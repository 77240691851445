import "./FacilityListComponent.scss";
import {ImageConfig, Misc} from "../../../constants";
import {ITableColumn} from "../../../shared/models/table.model";
import {CommonService} from "../../../shared/services";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import ButtonComponent from "../../../shared/components/button/ButtonComponent";
import LinkComponent from "../../../shared/components/link/LinkComponent";
import ToolTipComponent from "../../../shared/components/tool-tip/ToolTipComponent";
import {IAPIResponseType} from "../../../shared/models/api.model";
import TabsWrapperComponent, {
    TabComponent,
    TabContentComponent,
    TabsComponent
} from "../../../shared/components/tabs/TabsComponent";
import LoaderComponent from "../../../shared/components/loader/LoaderComponent";
import StatusCardComponent from "../../../shared/components/status-card/StatusCardComponent";
import CardComponent from "../../../shared/components/card/CardComponent";
import DataLabelValueComponent from "../../../shared/components/data-label-value/DataLabelValueComponent";
import DrawerComponent from "../../../shared/components/drawer/DrawerComponent";
import EditFacilityComponent from "../edit-facility/EditFacilityComponent";
import EditAdminDetailsComponent from "../edit-facility/EditAdminDetailsComponent";
import TableComponent from "../../../shared/components/table/TableComponent";
import AddWingComponent from "../wings/add-wings/AddWingsComponent";
import CustomToggleComponent
    from "../../../shared/components/custom-toogle-using-button-component/CustomToggleComponent";
import AddShiftTimings from "./AddShiftTimings";
import EditWingComponent from "../wings/edit-wing/EditWingsComponent";

interface PropertyListComponentProps {

}

const FacilityListComponent = (props: PropertyListComponentProps) => {
    const [activeTab, setActiveTab] = useState("basic-details");
    const [filterData, setFilterData] = useState<any>({
        search: '',
        selectedTab: 'Facility Details',
        // sort:{},
    });
    const [facilityDetails, setFacilityDetails] = useState<any>(null);
    const [isFacilityDetailsLoading, setIsFacilityDetailsLoading] = useState<boolean>(false);
    const [isFacilityDetailsLoadingFailed, setIsFacilityDetailsLoadingFailed] = useState<boolean>(false);
    const [isFacilityDetailsLoaded, setIsFacilityDetailsLoaded] = useState<boolean>(false);
    const [isEditDrawerOpen, setIsEditDrawerOpen] = useState<any>(false);
    const [isEditAdminDrawerOpen, setIsEditAdminDrawerOpen] = useState<any>(false);
    // const [isWingListLoading, setWingListLoading] = useState(false);
    const [isAddWingDrawerOpen, setIsAddWingDrawerOpen] = useState<boolean>(false);
    const [isAddShiftTimingsOpen, setIsAddShiftTimingsOpen] = useState<boolean>(false);
    const [isEditWingDrawerOpen, setIsEditWingDrawerOpen] = useState<boolean>(false);
    const [selectedWingsForEdit, setSelectedWingsForEdit] = useState<any>(null);

    const getFacilityDetails = useCallback(() => {
        setIsFacilityDetailsLoading(true);
        const payload = {};
        // console.log(isWingListLoading);
        CommonService._facility.FacilityDetailsAPICall(payload)
            .then((response: IAPIResponseType<any>) => {
                console.log(response.data);
                setFacilityDetails(response.data);
                setIsFacilityDetailsLoaded(true);
                setIsFacilityDetailsLoadingFailed(false);
            }).catch((error: any) => {
            setIsFacilityDetailsLoaded(false);
            setIsFacilityDetailsLoadingFailed(true);
        }).finally(() => {
            setIsFacilityDetailsLoading(false);
        });

    }, []);

    useEffect((): void => {
        getFacilityDetails();
    }, [getFacilityDetails]);

    const openEditDrawer = useCallback(() => {
        setIsEditDrawerOpen(true);
    }, []);

    const openAdminEditDrawer = useCallback(() => {
        setIsEditAdminDrawerOpen(true);
    }, []);

    const closeEditDrawer = useCallback(() => {
        setIsEditDrawerOpen(false);
    }, []);

    const closeAdminEditDrawer = useCallback(() => {
        setIsEditAdminDrawerOpen(false);
    }, []);


    const handleCloseAddWingDrawer = useCallback(() => {
        setIsAddWingDrawerOpen(false);
    }, []);

    const onWingsActivateDeactivate = useCallback((item: any) => {
        CommonService.onConfirm(
            {
                confirmationTitle: `${item?.is_active ? 'Deactivate' : 'Activate'}  Wing`,
                image: ImageConfig.ActivateDeactivateIcon,
                confirmationDescription: `Are you sure you want to ${item?.is_active ? 'deactivate' : 'activate'}  the
    wing?`,
                no: {
                    color: "default",
                    text: "No, Go Back",
                    variant: "outlined"
                },
                yes: {
                    color: "error",
                    text: `Yes, ${item?.is_active ? 'Deactivate' : 'Activate'}`,
                    variant: "contained"
                }
            }
        ).then((result: any) => {
            const payload: any = {};
            payload.is_active = !item?.is_active;
            CommonService._facility.ActivteDeactivateWings(item?._id, payload)
                .then((response: IAPIResponseType<any>) => {
                    CommonService._alert.showToast(response[Misc.API_RESPONSE_MESSAGE_KEY], "success");
                    getFacilityDetails();
                }).catch((error: any) => {
                CommonService._alert.showToast(error?.error || "No apis Found for this action", "error");
            });
        });
    }, [getFacilityDetails]);


    const deleteWingsApiCall = useCallback((wingId: any) => {
        const payload: any = {};
        CommonService._facility.deleteWings(wingId, payload)
            .then((response: IAPIResponseType<any>) => {
                CommonService._alert.showToast(response[Misc.API_RESPONSE_MESSAGE_KEY], "success");
                getFacilityDetails();
            }).catch((error: any) => {
            CommonService._alert.showToast(error?.error, "error");
        });
    }, [getFacilityDetails]);


    const onWingsDelete = useCallback((item: any) => {
        CommonService.onConfirm(
            {
                confirmationTitle: `Delete Wing`,
                image: ImageConfig.ActivateDeactivateIcon,
                confirmationDescription: `Are you sure you want to delete the
    wing?`,
                no: {
                    color: "default",
                    text: "No, Go Back",
                    variant: "outlined"
                },
                yes: {
                    color: "error",
                    text: `Yes, Delete`,
                    variant: "contained"
                }
            }
        ).then((result: any) => {
            deleteWingsApiCall(item?._id);
        });
    }, [deleteWingsApiCall]);

    const deleteShiftTimingsApiCall = useCallback((shiftTimingsId: any) => {
        const payload: any = {};
        CommonService._facility.deleteShiftTimings(shiftTimingsId, payload)
            .then((response: IAPIResponseType<any>) => {
                CommonService._alert.showToast(response[Misc.API_RESPONSE_MESSAGE_KEY], "success");
                getFacilityDetails();
            }).catch((error: any) => {
            CommonService._alert.showToast(error?.error, "error");
        });
    }, [getFacilityDetails]);


    const onShiftTimingsDelete = useCallback((item: any) => {
        CommonService.onConfirm(
            {
                confirmationTitle: `Delete this shift`,
                image: ImageConfig.ActivateDeactivateIcon,
                confirmationDescription: `Are you sure you want to delete this shift?`,
                no: {
                    color: "default",
                    text: "No, Go Back",
                    variant: "outlined"
                },
                yes: {
                    color: "error",
                    text: `Yes, Delete`,
                    variant: "contained"
                }
            }
        ).then((result: any) => {
            deleteShiftTimingsApiCall(item?._id);
        });
    }, [deleteShiftTimingsApiCall]);


    const shiftTimingsColumn: ITableColumn[] = useMemo<ITableColumn[]>(() => {
        return [
            {
                title: 'Shift Type',
                key: 'shift_type',
                dataIndex: 'shift_type',
                fixed: 'left',
                // sortable: true,
                width: 150,
                render: (item: any) => {
                    console.log(item);
                    return <div>
                        {/*<AvatarComponent url={item?.profilePic?.url} size={'xs'}/>*/}
                        {item?.shift_type}
                    </div>;
                }
            },
            {
                title: 'Shift Start Time',
                key: 'shift_start_time',
                dataIndex: 'shift_start_time',
                align: 'center',
                width: 150,
                render: (item: any) => {
                    return <>
                        <div>
                            {CommonService.convertMinutesToTime(item?.shift_start_time)}
                        </div>
                    </>;
                }
            },
            {
                title: "Shift End Time",
                dataIndex: "shift_end_time",
                align: 'center',
                key: "shift_end_time",
                width: 150,
                fixed: 'right',
                render: (item: any) => {
                    return <>
                        <div>
                            {CommonService.convertMinutesToTime(item?.shift_end_time)}
                        </div>
                    </>;
                }
            },
            {
                title: "",
                dataIndex: "delete",
                align: 'center',
                key: "delete",
                width: 100,
                fixed: 'right',
                render: (item: any) => {
                    return <LinkComponent onClick={() => {
                        // Filter the list to exclude the item with the specified ID
                        onShiftTimingsDelete(item);
                    }
                    }>
                        <ImageConfig.DeleteIcon/>
                    </LinkComponent>;
                }
            }
        ];
    }, [onShiftTimingsDelete]);

    const wingDetailsColumn: ITableColumn[] = useMemo<ITableColumn[]>(() => {
        return [
            {
                title: 'Wing Name',
                key: 'wing_name',
                dataIndex: 'wing_list_name',
                // width: 150,
                render: (item: any) => {
                    return <>
                        {
                            (item?.name?.name)?.length > 20 ?
                                <ToolTipComponent
                                    tooltip={item?.name?.name}
                                    showArrow={true}
                                    position={"top"}
                                >
                                    <div style={{cursor: "pointer"}}
                                         className={"ellipses-for-table-data property-name"}>
                                        {item?.name?.name || item?.name}
                                    </div>
                                </ToolTipComponent> :
                                <div className="facility-name">
                                    {item?.name?.name || item?.name || "-"}
                                </div>
                        }
                    </>;
                }
            },
            {
                title: "",
                dataIndex: "delete",
                align: 'right',
                key: "delete",
                fixed: 'right',
                render: (item: any) => {
                    return <LinkComponent onClick={() => {
                        // Filter the list to exclude the item with the specified ID
                        //   deleteWingsApiCall(item?._id);
                        onWingsDelete(item);
                    }
                    }>
                        <ImageConfig.DeleteIcon/>
                    </LinkComponent>;
                }
            },

            {
                title: '',
                key: 'activate-deactivate',
                dataIndex: 'activate',
                align: 'right',
                fixed: 'right',
                //    width: 150,
                render: (item: any) => {

                    return <>
                        <CustomToggleComponent toggleHandler={() => {
                            onWingsActivateDeactivate(item);
                        }} defaultOn={item?.is_active}/>
                    </>;
                }
            },

            {
                title: "",
                dataIndex: "actions",
                align: 'center',
                key: "actions",
                width: 100,
                fixed: 'right',
                render: (item: any) => {
                    if (item?._id) {
                        return <LinkComponent onClick={() => {
                            setSelectedWingsForEdit(item);
                            setIsEditWingDrawerOpen(true);
                        }}>
                            <ImageConfig.PencilEditIcon/>
                        </LinkComponent>;
                    }
                }
            }

        ];
    }, [onWingsActivateDeactivate, onWingsDelete]);

    const handleCloseAddShiftDrawer = useCallback(() => {
        setIsAddShiftTimingsOpen(false);
    }, []);

    const handleCloseEditWingDrawer = useCallback(() => {
        setIsEditWingDrawerOpen(false);
    }, []);

    return (
        <>
            <DrawerComponent isOpen={isAddShiftTimingsOpen} onClose={handleCloseAddShiftDrawer}>
                <AddShiftTimings closeEditDrawer={handleCloseAddShiftDrawer} getFacilityDetails={getFacilityDetails}/>
            </DrawerComponent>
            <DrawerComponent isOpen={isEditDrawerOpen} direction={"right"} onClose={closeEditDrawer}>
                <EditFacilityComponent getFacilityDetails={getFacilityDetails} closeEditDrawer={closeEditDrawer}
                                       facilityDetails={facilityDetails}/>
            </DrawerComponent>
            <DrawerComponent isOpen={isEditAdminDrawerOpen} direction={"right"} onClose={closeAdminEditDrawer}>
                <EditAdminDetailsComponent facilityDetails={facilityDetails} closeEditDrawer={closeAdminEditDrawer}
                                           getFacilityDetails={getFacilityDetails}/>
            </DrawerComponent>
            <DrawerComponent isOpen={isAddWingDrawerOpen} onClose={handleCloseAddWingDrawer}>
                <AddWingComponent getFacilityDetails={getFacilityDetails}
                                  closeEditDrawer={handleCloseAddWingDrawer}/>
            </DrawerComponent>
            <DrawerComponent isOpen={isEditWingDrawerOpen} onClose={handleCloseEditWingDrawer}>
                <EditWingComponent getFacilityDetails={getFacilityDetails}
                                   selectedFacilityForEdit={selectedWingsForEdit}
                                   closeEditDrawer={handleCloseEditWingDrawer}/>
            </DrawerComponent>
            <div className={'facility-list-component header-screen mrg-top-25 mrg-left-25 mrg-right-30'}>
                <div className="list-screen-header-wrapper">
                    <div className="list-screen-header">
                        {/*<div className={'mrg-bottom-5 home-text'}>Home</div>*/}
                        <div className="list-screen-header-title-and-count d-flex">
                            <div className={'mrg-right-10'}>
                                <ImageConfig.FacilityModuleHeaderIcon/>
                            </div>
                            <div className="list-screen-header-title mrg-bottom-25">Facility</div>
                        </div>
                    </div>
                </div>
                <TabsWrapperComponent>
                    <TabsComponent
                        value={activeTab}
                        allowScrollButtonsMobile={false}
                        onUpdate={(e: any, v: any) => {
                            setActiveTab(v);
                            setFilterData({...filterData, selectedTab: v, page: 1});
                        }}
                    >
                        <TabComponent label="Basic Details" value={"basic-details"}/>
                        <TabComponent label="Wing Details" value={"wing-details"}/>
                        <TabComponent label="Shift Timings" value={"shift-timings"}/>
                    </TabsComponent>
                    <div className={'pdd-top-10'}>
                        <TabContentComponent value={activeTab} selectedTab={"basic-details"}>
                            {
                                isFacilityDetailsLoading && <div className="h-v-center">
                                    <LoaderComponent type={'spinner'}/>
                                </div>
                            }
                            {
                                isFacilityDetailsLoadingFailed &&
                                <div className="h-v-center">
                                    <StatusCardComponent title={'Failed to Load'}/>
                                </div>
                            }
                            {
                                isFacilityDetailsLoaded && <>
                                    <CardComponent size={"sm"}>
                                        <div className={'details-screen'}>
                                            <div className="details-screen-title-with-image-wrapper pdd-10">
                                                <div
                                                    className={'details-screen-title-wrapper center-right-element mrg-left-30 mrg-right-30'}>
                                                    <div className={'details-screen-title'}>Facility
                                                        Details
                                                    </div>
                                                    <div onClick={openEditDrawer} className={'icon-button'}>
                                                <span
                                                    className={'center-element button-font-text'}><ImageConfig.EditIcon
                                                    className={'mrg-right-10'}/>Edit Facility Details</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={"pdd-left-25 pdd-right-25 pdd-top-25"}>
                                                <div className={'ts-row'}>
                                                    <div className={'ts-col-lg-3'}>
                                                        <DataLabelValueComponent label={'Facility Name'}>
                                                            {facilityDetails?.name || '-'}
                                                        </DataLabelValueComponent>
                                                    </div>
                                                    <div className={'ts-col-lg-3'}>
                                                        <DataLabelValueComponent label={'Phone Number'}>
                                                            {facilityDetails?.contact_number || '-'}
                                                        </DataLabelValueComponent>
                                                    </div>
                                                    <div className={'ts-col-lg-3'}>
                                                        <DataLabelValueComponent label={'Email ID'}>
                                                            {facilityDetails?.email || '-'}
                                                        </DataLabelValueComponent>
                                                    </div>
                                                    <div className={'ts-col-lg-3'}>
                                                        <DataLabelValueComponent label={'Address Line'}>
                                                            {`${facilityDetails?.address?.street}, ${facilityDetails?.address?.city}, ${facilityDetails?.address?.state}, ${facilityDetails?.address?.zip_code}, ${facilityDetails?.address?.region}, ${facilityDetails?.address?.country}` || '-'}
                                                        </DataLabelValueComponent>
                                                    </div>
                                                </div>
                                                <div className={'ts-row'}>
                                                    <div className={'ts-col-lg-3'}>
                                                        <DataLabelValueComponent label={'Timezone'}>
                                                            {CommonService.getTimezoneTitleByCode(facilityDetails?.timezone) || '-'}
                                                        </DataLabelValueComponent>
                                                    </div>
                                                    <div className={'ts-col-lg-3'}>
                                                        <DataLabelValueComponent label={'Region'}>
                                                            {
                                                                facilityDetails?.address?.region
                                                            }
                                                        </DataLabelValueComponent>
                                                    </div>
                                                    <div className={'ts-col-lg-6'}>
                                                        <DataLabelValueComponent label={'Domain'}>
                                                            {facilityDetails?.domain}
                                                        </DataLabelValueComponent>
                                                    </div>
                                                </div>
                                                <div className={"ts-row"}>
                                                    <div className={'ts-col-lg-6'}>
                                                        <DataLabelValueComponent label={'Latitude'}>
                                                            <div>
                                                                {facilityDetails?.location?.coordinates[1] || '-'}
                                                            </div>
                                                        </DataLabelValueComponent>
                                                    </div>
                                                    <div className={'ts-col-lg-6'}>
                                                        <DataLabelValueComponent label={'Longitude'}>
                                                            <div>
                                                                {facilityDetails?.location?.coordinates[0] || '-'}
                                                            </div>
                                                        </DataLabelValueComponent>
                                                    </div>
                                                </div>
                                                <div className={"ts-row"}>
                                                    <div className={'ts-col-lg-6'}>
                                                        <DataLabelValueComponent label={'About the Facility'}>
                                                            {facilityDetails?.about || '-'}
                                                        </DataLabelValueComponent>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </CardComponent>
                                    <CardComponent size={"sm"}>
                                        <div className={'details-screen'}>
                                            <div className="details-screen-title-with-image-wrapper pdd-10">
                                                <div
                                                    className={'details-screen-title-wrapper center-right-element pdd-left-30 pdd-right-30'}>
                                                    <div className={'details-screen-title'}>Admin Details
                                                    </div>
                                                    <div onClick={openAdminEditDrawer} className={'icon-button'}>
                                                <span
                                                    className={'center-element button-font-text'}><ImageConfig.EditIcon
                                                    className={'mrg-right-10'}/>Edit Admin Details</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={"pdd-left-25 pdd-right-25 pdd-top-25"}>
                                                <div className={'ts-row'}>
                                                    <div className={'ts-col-lg-3'}>
                                                        <DataLabelValueComponent label={'Admin Name'}>
                                                            {facilityDetails?.admin_details?.first_name + " " + facilityDetails?.admin_details?.last_name || '-'}
                                                        </DataLabelValueComponent>
                                                    </div>
                                                    <div className={'ts-col-lg-3'}>
                                                        <DataLabelValueComponent label={'Phone Number'}>
                                                            {facilityDetails?.admin_details?.contact_number || '-'}
                                                        </DataLabelValueComponent>
                                                    </div>
                                                    <div className={'ts-col-lg-3'}>
                                                        <DataLabelValueComponent label={'Email ID'}>
                                                            {facilityDetails?.admin_details?.email || '-'}
                                                        </DataLabelValueComponent>
                                                    </div>
                                                    <div className={'ts-col-lg-3'}>
                                                        <DataLabelValueComponent label={'Notifications Preference'}>
                                                            {(facilityDetails?.admin_details?.notification_preferences?.isSmsRequired ? "SMS, " : "") + (facilityDetails?.admin_details?.notification_preferences?.isEmailRequired ? "Email, " : "") + (facilityDetails?.admin_details?.notification_preferences?.isPush_notificationRequired ? "In App " : "") || '-'}
                                                        </DataLabelValueComponent>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </CardComponent>
                                    {/*<TabContentComponent selectedTab={activeTab} value={"wing-details"}>*/}
                                    {/*    <div className={"mrg-left-100  mrg-right-100"}>*/}
                                    {/*        <WingsListComponent facilityId={facilityId}/>*/}
                                    {/*    </div>*/}
                                    {/*</TabContentComponent>*/}
                                </>
                            }
                        </TabContentComponent>
                        <TabContentComponent selectedTab={activeTab} value={"wing-details"}>
                            <div>
                                <div className={'d-flex justify-content-right mrg-bottom-10'}>
                                    <ButtonComponent
                                        prefixIcon={<ImageConfig.AddOutlinedIcon/>}
                                        className={"center-element add-button-text "} onClick={() => {
                                        setIsAddWingDrawerOpen(true);
                                    }}>Add New
                                        Wing</ButtonComponent>
                                </div>
                                <div className={'shift-timings-wrapper '}>
                                    <TableComponent columns={wingDetailsColumn} noDataText={"no wings for this facility."}
                                                    data={facilityDetails?.wings} className={"shift-timings-wrapper"}/>
                                </div>
                                {/*<WingsListComponent facilityId={facilityId}/>*/}
                            </div>
                        </TabContentComponent>
                        <TabContentComponent selectedTab={activeTab} value={"shift-timings"}>
                            <div>
                                <div className={'d-flex justify-content-right mrg-bottom-10'}>
                                    <ButtonComponent
                                        prefixIcon={<ImageConfig.AddOutlinedIcon/>}
                                        className={"center-element add-button-text "} onClick={() => {
                                        setIsAddShiftTimingsOpen(true);
                                    }}>Add Shift Timings</ButtonComponent>
                                </div>
                                <div className={'shift-timings-wrapper'}>
                                    <TableComponent columns={shiftTimingsColumn} noDataText={"no shift timings for this facility."}
                                                    data={facilityDetails?.shift_timings} className={"shift-timings-wrapper"}/>
                                </div>
                            </div>
                        </TabContentComponent>
                    </div>
                </TabsWrapperComponent>
            </div>

        </>
    );

};

export default FacilityListComponent;
