import {Field, FieldProps, Form, Formik, FormikHelpers} from 'formik';
import React, {useCallback, useEffect, useState} from 'react';
import LabelComponent from '../../../../../shared/components/label/LabelComponent';
import {Autocomplete} from '@mui/lab';
import {
    Paper,
    TextField,
} from '@mui/material';
import ErrorComponent from '../../../../../shared/components/error/ErrorComponent';
import {
    genderList, home_shift_type,
} from '../../../../../data';
import FormikInputComponent from '../../../../../shared/components/form-controls/formik-input/FormikInputComponent';
import ButtonComponent from '../../../../../shared/components/button/ButtonComponent';
import {ImageConfig, Misc} from '../../../../../constants';
import * as Yup from 'yup';
import {useNavigate} from 'react-router-dom';
import FormikTimePickerComponent
    from '../../../../../shared/components/form-controls/formik-time-picker/FormikTimePickerComponent';
import './PreApprovedHomeHealthComponent.scss';
import FilePickerComponent from '../../../../../shared/components/file-picker/FilePickerComponent';
import {CommonService} from '../../../../../shared/services';
import {IAPIResponseType} from '../../../../../shared/models/api.model';
import {makeStyles} from '@mui/styles';
import FormikDatePickerComponent
    from '../../../../../shared/components/form-controls/formik-date-picker/FormikDatePickerComponent';
import FormikSelectDropdownComponent
    from '../../../../../shared/components/form-controls/formik-select-dropdown/FormikSelectDropdownComponent';
import PhoneInputComponent from '../../../../../shared/components/phoneInput/PhoneInputComponent';
import RadioButtonGroupComponent
    from "../../../../../shared/components/form-controls/radio-button-group/RadioButtonGroupComponent";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import DatePickers from "react-multi-date-picker";
import moment from "moment/moment";

interface PreApprovedHomeHealthComponentProps {
    setShiftTypeHeader?: any;
    getFacilityDetails?: any;
    handleInternalHCPListSubmit?: any;
    handleFloatHcpListSubmit?: any;
    setSelectedFacilityId?: any;
    selectedFacilityId?: any;
    selectedHcpType?: any;
    setSelectedHcpType?: any;
    isShiftCreationForSingleDate?: any;
    selectedDateFromCalender?: any;
    onCalender?: any;
    sixMonthsFromNow?: any;
    modifiedInternalHcpListRequirementBased?: any;
    modifiedFloatHcpListRequirementBased?: any;
    selectedStaff?: any;
    handleAgencyStaffListSubmit?: (value: any, setFieldValue: any) => void;
    setSelectedStaff?: any;
    schedulingListFilterState?:any;
    setOpenDialog?:any;
    setSchedulingListFilterState?:any;
}


const useStyles: any = makeStyles({
    autocomplete: {
        '& .MuiInputBase-input': {
            height: '14px', // Adjust the height value as needed
        },
        '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
            paddingTop: '4px', // Adjust the padding value as needed
            paddingBottom: '4px', // Adjust the padding value as needed
        },
    },
});

const shiftDetailsValidationSchema = Yup.object().shape({
    hospice_details: Yup.object().shape({
        first_name: Yup.string().required('First name is required'),
        last_name: Yup.string(),
        middle_name: Yup.string(),
        gender: Yup.string().required('Gender is required'),
        dob: Yup.string().required('Date of birth is required'),
        phone_number: Yup.string().required('Phone number is required').min(12,"Phone number must be 10 digits"),
        email_address: Yup.string().email('Invalid Email address'),
        street: Yup.string().required('Street is required'),
        apt: Yup.string(),
        zipcode: Yup.string().required('Zip Code is required').min(5, 'ZIP Code should be at least 5 characters').max(6, 'ZIP Code should be maximum 6 characters'),
        city: Yup.string().required('City is required').min(2,"Minimum 2 characters are required").max(30,"City cannot be more than 100 Characters"),
        state: Yup.string().required('State is required'),
    }),
    shift_type:Yup.string().required('Visit Type is required'),
    start_time: Yup.string().required('Start time is required'),
    end_time: Yup.string().required('End time is required'),
    lat:Yup.string().required('Latitude is required'),
    long:Yup.string().required('Longitude is required'),
});

interface formInitialValuesFormSchema {
    facility_id: string;
    shift_rate: string;
    title: string;
    shift_type: any;
    shift_dates: any;
    start_time: any;
    end_time: any;
    hcp_ids: any;
    lat:any;
    long:any;
    hospice_details: {
        age: any;
        city:string;
        zipcode:string;
    };
}

const PreApprovedHomeHealthComponent = (props: PreApprovedHomeHealthComponentProps) => {
    const navigate = useNavigate();
    const {
        selectedFacilityId,
        handleInternalHCPListSubmit,
        handleFloatHcpListSubmit,
        getFacilityDetails,
        modifiedInternalHcpListRequirementBased,
        onCalender,
        sixMonthsFromNow,
        modifiedFloatHcpListRequirementBased,
        selectedStaff,
        setSelectedStaff,
        selectedDateFromCalender,
        schedulingListFilterState,
        setOpenDialog,
        setSchedulingListFilterState,
    } = props;
    const classes = useStyles();
    const [timesheetFile, setTimesheetFile] = React.useState<any>([]);
    const [value, setValue] = useState<any>(
        onCalender ? [selectedDateFromCalender] : null,
    );
    const [selectedShiftType, setSelectedShiftType] = useState<any>(null);
    const [isZipcodeValidated,setIsZipcodeValidated]=useState<boolean>(false);
    const formInitialValues: formInitialValuesFormSchema = {
        facility_id: "",
        shift_rate: "",
        title: "",
        shift_type: "",
        shift_dates: selectedDateFromCalender ? selectedDateFromCalender : null,
        start_time: "",
        end_time: "",
        hcp_ids: "",
        lat:"",
        long:"",
        hospice_details: {
            age: "",
            city:"",
            zipcode:"",
        },
    };
    const [initialValues] =
        useState<formInitialValuesFormSchema>(formInitialValues);

    function handleDatePicker(value: any) {
        setValue(value);
    }

    const removeFile = (index: number) => {
        // Create a new array excluding the file at the given index
        const newTimesheetFile = [...timesheetFile];
        newTimesheetFile.splice(index, 1);
        // Update the timesheetFile state with the new array
        setTimesheetFile(newTimesheetFile);
    };

    const onSubmit = useCallback(
        (values: any, {setErrors, setSubmitting}: FormikHelpers<any>) => {
            const formData = new FormData();
            timesheetFile.forEach((file: any, index: number) => {
                // Append each file with the same key ('image')
                formData.append('image', file);
            });
            let shift_dates;
            shift_dates = value.map((item: any) => {
                let mm = item?.month?.number;
                let dd = item?.day;
                let yyyy = item?.year;
                return moment(`${yyyy}-${mm}-${dd}`).format('YYYY-MM-DD');
            });
            shift_dates?.forEach((item: any) => {
                formData.append('shift_dates[]', item);
            });
            formData.append('shift_type', values?.shift_type);
            // formData.append('facility_id', values?.facility_id);
            formData.append('shift_rate', values?.shift_rate || '');
            formData.append(
                'start_time',
                // @ts-ignore
                CommonService.convertToAdjustedMinutes(
                    values.start_time,
                ),
            );
            formData.append(
                'end_time',
                // @ts-ignore
                CommonService.convertToAdjustedMinutes(
                    values.end_time,
                ),
            );
            formData.append(
                'hospice_details.city',
                values.hospice_details.city || '',
            );
            formData.append(
                'hospice_details.state',
                values.hospice_details.state || '',
            );
            formData.append(
                'hospice_details.first_name',
                values.hospice_details.first_name || '',
            );
            formData.append(
                'hospice_details.middle_name',
                values.hospice_details.middle_name || '',
            );
            formData.append(
                'hospice_details.last_name',
                values.hospice_details.last_name || '',
            );
            formData.append(
                'hospice_details.gender',
                values.hospice_details.gender || '',
            );
            formData.append('hospice_details.dob', CommonService.formatDate(values.hospice_details.dob) || '');
            // formData.append('hospice_details.age', values.hospice_details.age || '');
            formData.append('hospice_details.mrn', values.hospice_details.mrn || '');
            formData.append(
                'hospice_details.phone_number',
                values.hospice_details.phone_number || '',
            );
            formData.append(
                'hospice_details.email_address',
                values.hospice_details.email_address || '',
            );
            formData.append(
                'hospice_details.street',
                values.hospice_details.street || '',
            );
            formData.append('hospice_details.apt', values.hospice_details.apt || '');
            formData.append(
                'hospice_details.zipcode',
                values.hospice_details.zipcode || '',
            );
            formData.append('requirementType', 'Home Health');
            formData.append(`hospice_details.coordinates[0]`,values.long);
            formData.append(`hospice_details.coordinates[1]`,values.lat);

            formData.append('hcp_ids[]', values.hcp_ids);
            // Append other values to formData
            // Append shift_timings to formData

            let apiCall: any = {};
            apiCall =
                CommonService._shift_management.preApprovedHomeShiftAPICall(
                    formData,
                );
            apiCall
                .then((response: IAPIResponseType<any>) => {
                    CommonService._alert.showToast(
                        response[Misc.API_RESPONSE_MESSAGE_KEY],
                        'success',
                    );
                    if (onCalender) {
                        setOpenDialog(false);
                        setSchedulingListFilterState({...schedulingListFilterState});
                    } else {
                        navigate(CommonService._routeConfig.ShiftManagementRoute());
                    }
                })
                .catch((error: any) => {
                    CommonService.handleErrors(setErrors, error);
                    CommonService._alert.showToast(error?.error, 'error');
                })
                .finally(() => {
                    setSubmitting(false);
                });
            // eslint-disable-next-line
        },
        [
            navigate,
            timesheetFile,
            onCalender,
            value,
            setOpenDialog,
            setSchedulingListFilterState,
            schedulingListFilterState,
        ],
    );

    useEffect(() => {
        if (selectedFacilityId) {
            handleInternalHCPListSubmit();
            handleFloatHcpListSubmit();
        }
    }, [
        handleFloatHcpListSubmit,
        handleInternalHCPListSubmit,
        selectedFacilityId,
    ]);

    useEffect(() => {
        getFacilityDetails();
    }, [getFacilityDetails]);

    const handleStaffSelection = useCallback((value: string, setFieldValue: any) => {
        setSelectedStaff(value)
        setFieldValue('hcp_ids', "")
    }, [setSelectedStaff])

    const handleValidateZipcode=useCallback((values:any,setFieldValue:any)=>{
        setIsZipcodeValidated(false);
        let zipcode=values?.hospice_details?.zipcode;
        CommonService.getLatLongFromZip(zipcode).then((response:any)=>{
            setFieldValue("lat",parseFloat(response.latitude));
            setFieldValue("long",parseFloat(response.longitude));
            setIsZipcodeValidated(true);
            CommonService._alert.showToast("Latitude and Longitude are Fetched","success");
        }).catch((error:any)=>{
            CommonService._alert.showToast("Not Able to fetch Latitude and Longitude due to geocode errors","error");
        }).finally(()=>{
        })
    },[])

    const openMap=useCallback((values:any)=>{
        let lat =values?.lat;
        let long=values?.long;
        const googleMapsUrl = `https://www.google.com/maps?q=${lat},${long}`;
        window.open(googleMapsUrl, '_blank');
    },[])

    return (
        <>
            <Formik
                validationSchema={shiftDetailsValidationSchema}
                initialValues={initialValues}
                validateOnChange={false}
                validateOnBlur={true}
                enableReinitialize={true}
                validateOnMount={true}
                onSubmit={onSubmit}
            >
                {({
                      touched,
                      values,
                      errors,
                      validateForm,
                      isValid,
                      setFieldValue,
                      isSubmitting,
                      handleBlur,
                  }) => {
                    // eslint-disable-next-line react-hooks/rules-of-hooks
                    useEffect(() => {
                        validateForm();
                    }, [validateForm, values]);
                    return (
                        <Form className='t-form scroll-content' noValidate={true}>
                            {/*<FormDebuggerComponent values={values} errors={errors} showDebugger={false}/>*/}
                            <div className='add-component-content'>
                                <>
                                    <div className={'body-min-height'}>
                                        <div className={'requirement-form-body-wrapper'}>
                                            <div>
                                                <LabelComponent title={'Patient Details'} isBold={true}/>
                                                <div className={'ts-row mrg-top-10'}>
                                                    <div className='ts-col-4'>
                                                        <Field name={'hospice_details.first_name'}>
                                                            {(field: FieldProps) => (
                                                                <FormikInputComponent
                                                                    label={'First Name*'}
                                                                    placeholder={'Enter first name'}
                                                                    formikField={field}
                                                                    fullWidth={true}
                                                                />
                                                            )}
                                                        </Field>
                                                    </div>
                                                    <div className='ts-col-4'>
                                                        <Field name={'hospice_details.middle_name'}>
                                                            {(field: FieldProps) => (
                                                                <FormikInputComponent
                                                                    label={'Middle Name'}
                                                                    placeholder={'Enter middle name'}
                                                                    formikField={field}
                                                                    fullWidth={true}
                                                                />
                                                            )}
                                                        </Field>
                                                    </div>
                                                    <div className='ts-col-4'>
                                                        <Field name={'hospice_details.last_name'}>
                                                            {(field: FieldProps) => (
                                                                <FormikInputComponent
                                                                    label={'Last name'}
                                                                    placeholder={'Enter last name'}
                                                                    formikField={field}
                                                                    fullWidth={true}
                                                                />
                                                            )}
                                                        </Field>
                                                    </div>
                                                </div>
                                                <div className="ts-row">
                                                    <div className='ts-col-4'>
                                                        <Field name={'hospice_details.gender'}>
                                                            {(field: FieldProps) => (
                                                                <FormikSelectDropdownComponent
                                                                    options={genderList}
                                                                    displayWith={(option: any) => option.title}
                                                                    valueExtractor={(option: any) => option.code}
                                                                    label={'Gender*'}
                                                                    placeholder={'Select'}
                                                                    formikField={field}
                                                                    fullWidth={true}
                                                                />
                                                            )}
                                                        </Field>
                                                    </div>
                                                    <div className='ts-col-4'>
                                                        <LabelComponent title={'DOB*'}/>
                                                        <Field name={'hospice_details.dob'}>
                                                            {(field: FieldProps) => (
                                                                <FormikDatePickerComponent
                                                                    placeholder={'Select'}
                                                                    formikField={field}
                                                                    fullWidth={true}
                                                                    required
                                                                    onUpdate={(dob: any) => {
                                                                        const dobDate = new Date(dob);
                                                                        const now = new Date();

                                                                        let years =
                                                                            now.getUTCFullYear() -
                                                                            dobDate.getUTCFullYear();

                                                                        setFieldValue(
                                                                            'hospice_details.age',
                                                                            `${years} years`,
                                                                        );
                                                                    }}
                                                                />
                                                            )}
                                                        </Field>
                                                    </div>
                                                    <div className='ts-col-4'>
                                                        <Field name={'hospice_details.mrn'}>
                                                            {(field: FieldProps) => (
                                                                <FormikInputComponent
                                                                    label={'MRN'}
                                                                    placeholder={'Enter MRN'}
                                                                    formikField={field}
                                                                    fullWidth={true}
                                                                    maxLength={7}
                                                                />
                                                            )}
                                                        </Field>
                                                    </div>
                                                </div>
                                            </div>

                                            <div
                                                className={
                                                    'shift-requirement-divider-line mrg-bottom-20'
                                                }
                                            />
                                            <div>
                                                <LabelComponent title={'Address Details'} isBold={true}/>
                                                <div className='ts-row mrg-top-10'>
                                                    <div className='ts-col-4'>
                                                        <Field name={'hospice_details.street'}>
                                                            {(field: FieldProps) => (
                                                                <FormikInputComponent
                                                                    label={'Street'}
                                                                    placeholder={'Enter Street'}
                                                                    type={'text'}
                                                                    required={true}
                                                                    formikField={field}
                                                                    fullWidth={true}
                                                                />
                                                            )}
                                                        </Field>
                                                    </div>
                                                    <div className='ts-col-4'>
                                                        <Field name={'hospice_details.apt'}>
                                                            {(field: FieldProps) => (
                                                                <FormikInputComponent
                                                                    label={'Apt/suite'}
                                                                    placeholder={'Enter apt/suite'}
                                                                    type={'text'}
                                                                    formikField={field}
                                                                    fullWidth={true}
                                                                />
                                                            )}
                                                        </Field>
                                                    </div>
                                                    <div className='ts-col-4'>
                                                        <Field name={'hospice_details.city'}>
                                                            {(field: FieldProps) => (
                                                                <FormikInputComponent
                                                                    label={'City*'}
                                                                    placeholder={'Enter City'}
                                                                    type={'text'}
                                                                    formikField={field}
                                                                    fullWidth={true}
                                                                />
                                                            )}
                                                        </Field>
                                                    </div>
                                                </div>

                                                <div className='ts-row'>
                                                    <div className='ts-col-6'>
                                                        <Field name={'hospice_details.state'}>
                                                            {(field: FieldProps) => (
                                                                <FormikInputComponent
                                                                    label={'State*'}
                                                                    placeholder={'Enter State'}
                                                                    type={'text'}
                                                                    formikField={field}
                                                                    fullWidth={true}
                                                                />
                                                            )}
                                                        </Field>
                                                    </div>
                                                    <div className='ts-col-3'>
                                                        <Field name={'hospice_details.zipcode'}>
                                                            {(field: FieldProps) => (
                                                                <FormikInputComponent
                                                                    label={'ZIP Code'}
                                                                    placeholder={'Enter ZIP Code'}
                                                                    type={'number'}
                                                                    maxLength={6}
                                                                    required={true}
                                                                    formikField={field}
                                                                    fullWidth={true}
                                                                    onChange={() => {
                                                                        setIsZipcodeValidated(false)
                                                                        setFieldValue('lat', '');
                                                                        setFieldValue('long', '')
                                                                    }}
                                                                />
                                                            )}
                                                        </Field>
                                                    </div>
                                                    <div className={'ts-col-3'}>
                                                        <ButtonComponent
                                                            fullWidth={true}
                                                            onClick={() => handleValidateZipcode(values, setFieldValue)}
                                                            className={"mrg-top-25"}
                                                            disabled={values?.hospice_details?.zipcode?.length < 5}
                                                        >
                                                            {isZipcodeValidated ? "Validated" : "Validate"}
                                                        </ButtonComponent>
                                                    </div>
                                                </div>
                                                <div
                                                    className={'ts-row'}>
                                                    <div className={'ts-col-6'}>
                                                        <Field name={'lat'}>
                                                            {(field: FieldProps) => (
                                                                <FormikInputComponent
                                                                    label={'Latitude'}
                                                                    placeholder={'Enter Latitude'}
                                                                    type={'number'}
                                                                    required={true}
                                                                    formikField={field}
                                                                    fullWidth={true}
                                                                    validationPattern={new RegExp(/^(-?([1-8]?[0-9](\.\d+)?|90(\.0+)?))$/)}
                                                                />
                                                            )}
                                                        </Field>
                                                    </div>
                                                    <div className={'ts-col-6'}>
                                                        <Field name={'long'}>
                                                            {(field: FieldProps) => (
                                                                <FormikInputComponent
                                                                    label={'Longitude'}
                                                                    placeholder={'Enter Longitude'}
                                                                    type={'number'}
                                                                    required={true}
                                                                    formikField={field}
                                                                    fullWidth={true}
                                                                    validationPattern={new RegExp(/^(-?((1[0-7][0-9]|[1-9]?[0-9])(\.\d+)?|180(\.0+)?))$/)}
                                                                />
                                                            )}
                                                        </Field>
                                                    </div>

                                                </div>
                                                {(values?.lat && values?.long) &&
                                                    <div className={'view-map'}  onClick={()=>openMap(values)} >
                                                        View location on Map.
                                                    </div>
                                                }
                                            </div>
                                            <div
                                                className={
                                                    'shift-requirement-divider-line mrg-bottom-20 mrg-top-10'
                                                }
                                            />
                                            <div>
                                                <div>
                                                    <LabelComponent title={'Patient Contact Details'}
                                                                    isBold={true}/>
                                                </div>
                                                <div className={'ts-row mrg-top-10'}>
                                                    <div className="ts-col-6">
                                                        <LabelComponent title={'Phone Number*'}/>
                                                        <Field name={'hospice_details.phone_number'}>
                                                            {(field: FieldProps) => {
                                                                return <PhoneInputComponent field={field}
                                                                                            placeholder={"Enter Phone number"}/>;
                                                            }}
                                                        </Field>
                                                    </div>
                                                    <div className="ts-col-6">
                                                        <Field name={'hospice_details.email_address'}>
                                                            {
                                                                (field: FieldProps) => (
                                                                    <FormikInputComponent
                                                                        label={'Email Address'}
                                                                        placeholder={'example@xyz.com'}
                                                                        type={"text"}
                                                                        //   required={true}
                                                                        formikField={field}
                                                                        fullWidth={true}
                                                                    />
                                                                )
                                                            }
                                                        </Field>
                                                    </div>

                                                </div>
                                            </div>
                                            <div
                                                className={
                                                    'shift-requirement-divider-line mrg-bottom-20 mrg-top-10'
                                                }
                                            />
                                            <div>
                                                <LabelComponent title={'Visit Patient By'} isBold={true}/>
                                                <div className={'ts-row mrg-top-10'}>
                                                    <div className='ts-col-6'>
                                                        <LabelComponent title={'Visit Type*'}/>
                                                        <Autocomplete
                                                            disableClearable
                                                            PaperComponent={({children}) => (
                                                                <Paper style={{color: '#1e1e1e'}}>
                                                                    {children}
                                                                </Paper>
                                                            )}
                                                            value={selectedShiftType}
                                                            options={home_shift_type}
                                                            getOptionLabel={(option: any) => option.title}
                                                            placeholder={'Select Visit Type'}
                                                            onBlur={handleBlur('shift_type')}
                                                            id='input_select_shift_type'
                                                            className={classes.autocomplete}
                                                            onChange={($event, code) => {
                                                                // console.log(code);
                                                                setSelectedShiftType(code);
                                                                setFieldValue('shift_type', code?.code);
                                                            }}
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    {...params}
                                                                    id='select_fac_payment_status'
                                                                    variant='outlined'
                                                                    placeholder={'Select Shift Type'}
                                                                />
                                                            )}
                                                        />
                                                        <div className={'d-flex justify-content-right'}>
                                                            {touched.shift_type &&
                                                            errors.shift_type &&
                                                            typeof errors.shift_type === 'string' ? (
                                                                // <div>{errors.hcp_type_id}</div>
                                                                <ErrorComponent errorText={errors.shift_type}/>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                    <div className='ts-col-6'>
                                                        <LabelComponent title={'Visit rate'}/>
                                                        <Field name={'shift_rate'}>
                                                            {(field: FieldProps) => (
                                                                <FormikInputComponent
                                                                    className={'mrg-bottom-set-zero'}
                                                                    placeholder={'Enter Rate'}
                                                                    type={'number'}
                                                                    formikField={field}
                                                                    fullWidth={true}
                                                                    validationPattern={new RegExp(/^(?:[0-9]{1,3}(?:\.[0-9]{1,3})?)?$/)}
                                                                />
                                                            )}
                                                        </Field>
                                                    </div>
                                                </div>
                                                <div className={'ts-row'}>
                                                    <div className='ts-col-4'>
                                                        <LabelComponent title={'Visit Date(s)*'}/>
                                                        <div className='shift-calender mrg-bottom-20'>
                                                            <Field
                                                                required={true}
                                                                onUpdate={(e: any) => {
                                                                    setFieldValue('shift_dates', []);
                                                                }}
                                                                inputClass='custom-input'
                                                                className='rmdp-mobile'
                                                                plugins={[
                                                                    <DatePanel eachDaysInRange/>,
                                                                ]}
                                                                format='MM/DD/YYYY'
                                                                //  range={mode === "range" ? true : false}
                                                                multiple={true}
                                                                onChange={handleDatePicker}
                                                                value={value}
                                                                variant='inline'
                                                                inputVariant='outlined'
                                                                placeholder={'Select'}
                                                                id='input_shift_requirement_shift_datepicker'
                                                                name='shift_dates'
                                                                InputLabelProps={{shrink: true}}
                                                                component={DatePickers}
                                                                minDate={new Date()}
                                                                maxDate={sixMonthsFromNow}
                                                            />
                                                            <div
                                                                className={
                                                                    'd-flex justify-content-right'
                                                                }
                                                            >
                                                                {touched.shift_dates &&
                                                                (!value ||
                                                                    (value && value?.length === 0)) ? (
                                                                    // <div>{errors.hcp_type_id}</div>
                                                                    <ErrorComponent
                                                                        errorText={
                                                                            'Shift Dates is required'
                                                                        }
                                                                    />
                                                                ) : null}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='ts-col-4'>
                                                        <LabelComponent title={'Start Time*'}/>
                                                        <Field name={'start_time'}>
                                                            {(field: FieldProps) => (
                                                                <FormikTimePickerComponent
                                                                    placeholder={'Select Time'}
                                                                    required={true}
                                                                    formikField={field}
                                                                    fullWidth={true}
                                                                />
                                                            )}
                                                        </Field>
                                                    </div>
                                                    <div className='ts-col-4'>
                                                        <LabelComponent title={'End Time*'}/>
                                                        <Field name={'end_time'}>
                                                            {(field: FieldProps) => (
                                                                <FormikTimePickerComponent
                                                                    placeholder={'Select Time'}
                                                                    required={true}
                                                                    formikField={field}
                                                                    fullWidth={true}
                                                                />
                                                            )}
                                                        </Field>
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                className={
                                                    'shift-requirement-divider-line mrg-bottom-20'
                                                }
                                            />
                                            <LabelComponent
                                                title={'Add Staff to the Shift from'}
                                                isBold={true}
                                            />
                                            <div className={'ts-row mrg-top-20 pdd-left-20'}>
                                                <RadioButtonGroupComponent
                                                    options={
                                                        [
                                                            {
                                                                title: "Internal Staff*",
                                                                code: "internal-staff"
                                                            },
                                                            {
                                                                title: 'Floating Staff*',
                                                                code: 'floating-staff'
                                                            }
                                                        ]
                                                    }
                                                    value={selectedStaff}
                                                    valueExtractor={(option: any) => option.code}
                                                    onChange={(value: any) => {
                                                        handleStaffSelection(value, setFieldValue)
                                                    }}
                                                />
                                            </div>
                                            {(selectedStaff === 'internal-staff') &&
                                                <div className={'ts-row pdd-10 mrg-top-10'}>
                                                    <>
                                                        <Field name={'hcp_ids'}>
                                                            {(field: FieldProps) => (
                                                                <FormikSelectDropdownComponent
                                                                    options={modifiedInternalHcpListRequirementBased}
                                                                    displayWith={(option: any) =>
                                                                        option.title
                                                                    }
                                                                    valueExtractor={(option: any) =>
                                                                        option.code
                                                                    }
                                                                    placeholder={'Select'}
                                                                    formikField={field}
                                                                    fullWidth={true}
                                                                    onUpdate={(value)=>{
                                                                        setFieldValue('hcp_ids',value ? value : "")
                                                                    }}
                                                                />
                                                            )}
                                                        </Field>
                                                    </>
                                                </div>
                                            }
                                            {
                                                selectedStaff === 'floating-staff' &&
                                                <div className={'ts-row pdd-10 mrg-top-10'}>
                                                    <>
                                                        <Field name={'hcp_ids'}>
                                                            {(field: FieldProps) => (
                                                                <FormikSelectDropdownComponent
                                                                    options={modifiedFloatHcpListRequirementBased}
                                                                    displayWith={(option: any) =>
                                                                        option.title
                                                                    }
                                                                    valueExtractor={(option: any) =>
                                                                        option.code
                                                                    }
                                                                    placeholder={'Select'}
                                                                    formikField={field}
                                                                    fullWidth={true}
                                                                    onUpdate={(value)=>{
                                                                        setFieldValue('hcp_ids',value ? value : "")
                                                                    }}
                                                                />
                                                            )}
                                                        </Field>
                                                    </>
                                                </div>

                                            }
                                            <div
                                                className={
                                                    'shift-requirement-divider-line mrg-bottom-20 mrg-top-20'
                                                }
                                            />
                                            <div
                                                className={
                                                    'document-upload-wrapper d-flex justify-content-space-between mrg-top-10'
                                                }
                                            >
                                                <div>
                                                    <LabelComponent title={'Upload Documents'} isBold={true}/>

                                                    <div className={'file-upload-supported-text mrg-top-10'}>
                                                        Upload the relevant documents
                                                    </div>
                                                    <div className={'file-upload-supported-text'}>
                                                        Supported formats : .pdf, .jpg, .jpeg, .png
                                                    </div>
                                                </div>
                                                <div>
                                                    <FilePickerComponent
                                                        maxFileCount={3}
                                                        height={'470px'}
                                                        id={'sc_upload_btn'}
                                                        btnText={'Select Files'}
                                                        disabled={timesheetFile?.length === 3}
                                                        multiple={true}
                                                        showDropZone={false}
                                                        onFilesDrop={(
                                                            acceptedFiles: any,
                                                            rejectedFiles: any,
                                                        ) => {
                                                            if (acceptedFiles && acceptedFiles.length > 0) {
                                                                const file = acceptedFiles[0];
                                                                //   setTimesheetFile(file);
                                                                setTimesheetFile([...timesheetFile, file]);
                                                            }
                                                        }}
                                                        acceptedFileTypes={['pdf', 'jpeg', 'jpg', 'png']}
                                                        uploadDescription={'(upload only one file)'}
                                                        acceptedFilesText={
                                                            'Only .pdf files are supported'
                                                        }
                                                    />
                                                    <div
                                                        className={
                                                            'd-flex justify-content-end mrg-top-5 file-upload-supported-text'
                                                        }
                                                    >
                                                        Max upload : 3
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={'d-flex-wrap mrg-top-10'}>
                                                {timesheetFile &&
                                                    timesheetFile?.map((file: any, index: number) => {
                                                        return (
                                                            <>
                                                                <div
                                                                    className={
                                                                        'd-flex justify-content-space-between file-holder-wrapper mrg-right-10 mrg-10'
                                                                    }
                                                                >
                                                                    <div> {file?.name}</div>
                                                                    <div
                                                                        className={'icon-button'}
                                                                        onClick={() => {
                                                                            removeFile(index);
                                                                        }}
                                                                    >
                                                                        <ImageConfig.DeleteIcon/>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        );
                                                    })}
                                            </div>
                                        </div>
                                        <div
                                            className={'d-flex ts-justify-content-center ts-align-content-center mrg-top-15'}
                                        >
                                            <ButtonComponent
                                                disabled={
                                                    isSubmitting || !isValid ||
                                                    values?.hcp_ids?.length === 0
                                                }
                                                isLoading={isSubmitting}
                                                type={'submit'}
                                                style={{backgroundColor: '#192938', color: 'white'}}
                                            >
                                                Create Pre Approved Shift
                                            </ButtonComponent>
                                        </div>
                                    </div>
                                </>

                            </div>
                        </Form>
                    );
                }}
            </Formik>
        </>
    );
};

export default PreApprovedHomeHealthComponent;
