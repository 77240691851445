import {Field, FieldProps, Form, Formik, FormikHelpers} from 'formik';
import React, {useCallback, useEffect, useState} from 'react';
import LabelComponent from '../../../../../shared/components/label/LabelComponent';
import {Autocomplete} from '@mui/lab';
import {
    Checkbox,
    ListItemText,
    MenuItem,
    Paper,
    Select,
    SelectChangeEvent,
    TextField,
} from '@mui/material';
import ErrorComponent from '../../../../../shared/components/error/ErrorComponent';
import {
    genderList,
    home_shift_type,
    languagesList,
} from '../../../../../data';
import FormikInputComponent from '../../../../../shared/components/form-controls/formik-input/FormikInputComponent';
import FormikCheckBoxComponent
    from '../../../../../shared/components/form-controls/formik-check-box/FormikCheckBoxComponent';
import FormControl from '@mui/material/FormControl';
import ButtonComponent from '../../../../../shared/components/button/ButtonComponent';
import {ImageConfig, Misc, Patterns} from '../../../../../constants';
import * as Yup from 'yup';
import {useDispatch, useSelector} from 'react-redux';
import {IRootReducerState} from '../../../../../store/reducers';
import {useNavigate} from 'react-router-dom';
import FormikTimePickerComponent
    from '../../../../../shared/components/form-controls/formik-time-picker/FormikTimePickerComponent';
import './HomeHealthHospiceTypeShiftRequirement.scss';
import FilePickerComponent from '../../../../../shared/components/file-picker/FilePickerComponent';
import {CommonService} from '../../../../../shared/services';
import {IAPIResponseType} from '../../../../../shared/models/api.model';
import {makeStyles} from '@mui/styles';
import FormikDatePickerComponent
    from '../../../../../shared/components/form-controls/formik-date-picker/FormikDatePickerComponent';
import FormikSelectDropdownComponent
    from '../../../../../shared/components/form-controls/formik-select-dropdown/FormikSelectDropdownComponent';
import PhoneInputComponent from '../../../../../shared/components/phoneInput/PhoneInputComponent';
import {getStaffSpecialityList} from '../../../../../store/actions/meta.action';

interface AddShiftDetailsProps {
    setShiftTypeHeader?: any;
    setIsFloatValueSelected?: any;
    setIsInternalValueSelected?: any;
    setCreatedRequirementList?: any;
    setIsInternalHcp?: any;
    getFacilityDetails?: any;
    handleInternalHCPListSubmit?: any;
    handleFloatHcpListSubmit?: any;
    setSelectedFacilityId?: any;
    selectedFacilityId?: any;
    selectedHcpType?: any;
    setSelectedHcpType?: any;
    sixMonthsFromNow?: any;
    isShiftCreationForSingleDate?: any;
    selectedDateFromCalender?: any;
    onCalender?: any;
    schedulingListFilterState?: any;
    setOpenDialog?: any;
    setSchedulingListFilterState?: any;
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const useStyles: any = makeStyles({
    autocomplete: {
        '& .MuiInputBase-input': {
            height: '14px', // Adjust the height value as needed
        },
        '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
            paddingTop: '4px', // Adjust the padding value as needed
            paddingBottom: '4px', // Adjust the padding value as needed
        },
    },
});

const shiftDetailsValidationSchema = Yup.object().shape({
    hcp_type_id: Yup.string().required('Staff Type Selection is required'),
    hcp_speciality_id: Yup.string(),
    shift_type: Yup.string().required('Job Type is required'),
    shift_dates: Yup.date().typeError('Invalid Date').required('Visit Date is required'),
    hospice_details: Yup.object().shape({
        first_name: Yup.string().required('First name is required'),
        visit_patient_time: Yup.string().required('Visit Time is required'),
        last_name: Yup.string(),
        middle_name: Yup.string(),
        gender: Yup.string().required('Gender is required'),
        dob: Yup.string(),
        phone_number: Yup.string().required('Phone number is required').min(12,"Phone number must be 10 digits"),
        email_address: Yup.string().email('Invalid Email address'),
        street: Yup.string().required('Street is required'),
        apt: Yup.string(),
        zipcode: Yup.string().required('Zip Code is required').min(5, 'ZIP Code should be at least 5 characters').max(6, 'ZIP Code should be maximum 6 characters'),
        city: Yup.string().required('City is required').min(2,"Minimum 2 characters are required").max(30,"City cannot be more than 100 Characters"),
        state: Yup.string().required('State is required'),
    }),
    shift_rate: Yup.string().required('Visit Rate is required'),
    lat:Yup.string().required('Latitude is required'),
    long:Yup.string().required('Longitude is required')
});

interface formInitialValuesFormSchema {
    shift_rate: string;
    title: string;
    hcp_type_id: any;
    required_hcp_count: any;
    // shift_rate: string;
    facility_wing_id: any;
    hazard_rate: any;
    hazard_zone: any;
    is_hazard: any;
    shift_type: any;
    language: any;
    is_rush: any;
    is_weekend: any;
    rush_rate: any;
    weekend_rate: any;
    shift_dates: any;
    lat:any;
    long:any;
    shift_timings: {
        shift_start_time: string;
        shift_end_time: string;
    };
    send_to: {
        is_float: any;
        is_internal: any;
        specific_agencies: any;
    };
    hospice_details: {
        age: any;
        city:string;
        zipcode:any;
    };
}

const HomeHealthHospiceTypeShiftRequirement = (props: AddShiftDetailsProps) => {
    const {
        setShiftTypeHeader,
        setIsInternalValueSelected,
        setIsFloatValueSelected,
        setIsInternalHcp,
        setCreatedRequirementList,
        selectedFacilityId,
        handleInternalHCPListSubmit,
        handleFloatHcpListSubmit,
        getFacilityDetails,
        setSelectedHcpType,
        selectedHcpType,
        onCalender,
        schedulingListFilterState,
        setOpenDialog,
        setSchedulingListFilterState,
    } = props;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const classes = useStyles();
    const {agencyList} = useSelector(
        (state: IRootReducerState) => state.staticData,
    );
    // const [value, setValue] = useState<any>(
    //     onCalender ? [selectedDateFromCalender] : null,
    // );
    const [selectedShiftType, setSelectedShiftType] = useState<any>(null);
    const {HcpTypesList} = useSelector(
        (state: IRootReducerState) => state.meta,
    );
    const [selectedAgency, setSelectedAgency] = React.useState<Array<string>>([]);
    const [isAgencySelected, setIsAgencySelected] = useState(false);
    const [requirementUploadFiles, setTimesheetFile] = React.useState<any>([]);
    const [isZipcodeValidated,setIsZipcodeValidated]=useState<boolean>(false);
    const {staffSpecialityList, stateList} = useSelector(
        (state: IRootReducerState) => state.meta,
    );

    const formInitialValues: formInitialValuesFormSchema = {
        title: '',
        shift_rate: '',
        facility_wing_id: '',
        hcp_type_id: '',
        shift_dates: null,
        hazard_rate: '',
        hazard_zone: '',
        shift_type: '',
        language: '',
        is_hazard: false,
        is_rush: false,
        is_weekend: false,
        required_hcp_count: '',
        rush_rate: '',
        weekend_rate: '',
        lat:'',
        long:'',
        shift_timings: {
            shift_start_time: '',
            shift_end_time: '',
        },
        send_to: {
            is_float: false,
            is_internal: false,
            specific_agencies: false,
        },
        hospice_details: {
            age: '0 years',
            city:'',
            zipcode:''
        },
    };
    const [initialValues] =
        useState<formInitialValuesFormSchema>(formInitialValues);

    const handleAgencyTypeSelection = useCallback((
        event: SelectChangeEvent<typeof selectedAgency>,
    ) => {
        // @ts-ignore
        setSelectedAgency(event.target.value);
    }, []);

    const removeFile = useCallback((index: number) => {
        // Create a new array excluding the file at the given index
        const newTimesheetFile = [...requirementUploadFiles];
        newTimesheetFile.splice(index, 1);

        // Update the timesheetFile state with the new array
        setTimesheetFile(newTimesheetFile);
    }, [requirementUploadFiles]);

    const onSubmit = useCallback(
        (values: any, {setErrors, setSubmitting}: FormikHelpers<any>) => {
            const formData = new FormData();
            requirementUploadFiles?.forEach((file: any, index: number) => {
                // Append each file with the same key ('image')
                formData.append('image', file);
            });
            let shiftdate = CommonService.formatDate(values?.shift_dates)
            formData.append("shift_dates[]",
                // @ts-ignore
                [shiftdate]
            );

            formData.append(
                'send_to.specific_agencies',
                // @ts-ignore
                Boolean(values.send_to.specific_agencies),
            );
            formData.append(
                'send_to.is_internal',
                // @ts-ignore
                Boolean(values.send_to.is_internal),
            );
            formData.append(
                'send_to.is_float',
                // @ts-ignore
                Boolean(values.send_to.is_float),
            );
            formData.append('hcp_type_id', values.hcp_type_id);
            formData.append('shift_type', values.shift_type);
            // formData.append('facility_id', values.facility_id);
            formData.append('shift_rate', values.shift_rate || '');
            formData.append(
                'hospice_details.visit_patient_time',
                // @ts-ignore
                CommonService.convertToAdjustedMinutes(
                    values.hospice_details.visit_patient_time,
                ),
            );
            formData.append(
                'hospice_details.city',
                values.hospice_details.city || '',
            );
            formData.append(
                'hospice_details.state',
                values.hospice_details.state || '',
            );
            // formData.append('note', values.note || '');
            formData.append('gender_preference', values.gender_preference || '');

            if (values?.language?.length > 0) {
                values.language?.forEach((item: any, index: any) => {
                    if (item !== "Other") {
                        formData.append(`languages[${index}]`, item);
                    } else if (item === "Other") {
                        formData.append(`languages[${values?.language?.length - 1}]`, values.other_language);
                    }
                });
            }

            formData.append('required_hcp_count', "1");
            formData.append(
                'hospice_details.first_name',
                values.hospice_details.first_name || '',
            );
            formData.append(
                'hospice_details.middle_name',
                values.hospice_details.middle_name || '',
            );
            formData.append(
                'hospice_details.last_name',
                values.hospice_details.last_name || '',
            );
            formData.append(
                'hospice_details.gender',
                values.hospice_details.gender || '',
            );
            formData.append('hospice_details.dob', CommonService.formatDate(values.hospice_details.dob) || '');
            // formData.append('hospice_details.age', values.hospice_details.age || '');
            formData.append('hospice_details.mrn', values.hospice_details.mrn || '');
            formData.append(
                'hospice_details.phone_number',
                values.hospice_details.phone_number || '',
            );
            formData.append(
                'hospice_details.email_address',
                values.hospice_details.email_address || '',
            );
            formData.append(
                'hospice_details.street',
                values.hospice_details.street || '',
            );
            formData.append('hospice_details.apt', values.hospice_details.apt || '');
            formData.append(
                'hospice_details.zipcode',
                values.hospice_details.zipcode || '',
            );

            // formData.append('visit_patient_by', values.visit_patient_by || '');
            formData.append('number_of_visit', values.number_of_visit || '');
            formData.append('hcp_speciality_id', values.hcp_speciality_id || '');
            formData.append('hospice_details.coordinates[0]',values?.long);
            formData.append('hospice_details.coordinates[1]',values?.lat);


            // Append allowed_agency_ids to formData
            selectedAgency?.forEach((item: any) => {
                formData.append('allowed_agency_ids[]', item.code);
            });

            // Append requirementType to formData
            formData.append('requirementType', 'Home Health');


            let apiCall: any = {};
            apiCall =
                CommonService._shift_management.addNewHomeHealthHospiceRequirementShiftAPICall(
                    formData,
                );
            apiCall
                .then((response: IAPIResponseType<any>) => {
                    CommonService._alert.showToast(
                        response[Misc.API_RESPONSE_MESSAGE_KEY],
                        'success',
                    );
                    if (values?.send_to?.is_internal || values?.send_to?.is_float) {
                        setIsInternalHcp(true);
                        setShiftTypeHeader('Create Approved Shift');
                        setCreatedRequirementList(response?.data);
                        if (values?.send_to?.is_internal && !values?.send_to?.is_float) {
                            setIsInternalValueSelected(true);
                        } else if (
                            values?.send_to?.is_float &&
                            !values?.send_to?.is_internal
                        ) {
                            setIsFloatValueSelected(true);
                        } else if (
                            values?.send_to?.is_float &&
                            values?.send_to?.is_internal
                        ) {
                            setIsInternalValueSelected(true);
                            setIsFloatValueSelected(true);
                        }
                    } else {
                        if (onCalender) {
                            setOpenDialog(false);
                            setSchedulingListFilterState({...schedulingListFilterState});
                            // navigate(CommonService._routeConfig.SchedulerRoute());
                        } else {
                            navigate(CommonService._routeConfig.ShiftManagementRoute());
                        }
                    }
                })
                .catch((error: any) => {
                    CommonService.handleErrors(setErrors, error);
                    CommonService._alert.showToast(error?.error, 'error');
                })
                .finally(() => {
                    setSubmitting(false);
                });
            // eslint-disable-next-line
        },

        [requirementUploadFiles,
            selectedAgency,
            navigate,
            onCalender,
            schedulingListFilterState,
            setCreatedRequirementList,
            setIsFloatValueSelected,
            setIsInternalHcp,
            setIsInternalValueSelected,
            setOpenDialog,
            setSchedulingListFilterState,
            setShiftTypeHeader,
        ],
    );

    useEffect(() => {
        if (selectedFacilityId) {
            handleInternalHCPListSubmit();
            handleFloatHcpListSubmit();
        }
    }, [
        handleFloatHcpListSubmit,
        handleInternalHCPListSubmit,
        selectedFacilityId,
    ]);

    useEffect(() => {
        getFacilityDetails();
    }, [getFacilityDetails]);

    const handleValidateZipcode=useCallback((values:any,setFieldValue:any)=>{
        setIsZipcodeValidated(false);
        let zipcode=values?.hospice_details?.zipcode;
        CommonService.getLatLongFromZip(zipcode).then((response:any)=>{
            setFieldValue("lat",parseFloat(response.latitude));
            setFieldValue("long",parseFloat(response.longitude));
            setIsZipcodeValidated(true);
            CommonService._alert.showToast("Latitude and Longitude are Fetched","success");
        }).catch((error:any)=>{
            CommonService._alert.showToast("Not Able to fetch Latitude and Longitude due to geocode errors","error");
        }).finally(()=>{
        })
    },[])

    const openMap=useCallback((values:any)=>{
        let lat =values?.lat;
        let long=values?.long;
        const googleMapsUrl = `https://www.google.com/maps?q=${lat},${long}`;
        window.open(googleMapsUrl, '_blank');
    },[])

    return (
        <>
            <Formik
                validationSchema={shiftDetailsValidationSchema}
                initialValues={initialValues}
                validateOnChange={false}
                validateOnBlur={true}
                enableReinitialize={true}
                validateOnMount={true}
                onSubmit={onSubmit}
            >
                {({
                      touched,
                      values,
                      errors,
                      validateForm,
                      isValid,
                      setFieldValue,
                      isSubmitting,
                      handleBlur,
                  }) => {
                    // eslint-disable-next-line react-hooks/rules-of-hooks
                    useEffect(() => {
                        validateForm();
                    }, [validateForm, values]);
                    return (
                        <Form className='t-form scroll-content' noValidate={true}>
                            {/*<FormDebuggerComponent values={values} errors={errors} showDebugger={false}/>*/}

                            <div className='add-component-content'>
                                <>
                                    <div className={'body-min-height'}>
                                        <div className={'requirement-form-body-wrapper'}>


                                            <div className={'ts-row'}>
                                                <div className='ts-col-4'>
                                                    <LabelComponent title={'Staff Type*'}/>
                                                    <div>
                                                        <Autocomplete
                                                            disableClearable
                                                            PaperComponent={({children}) => (
                                                                <Paper style={{color: '#1e1e1e'}}>
                                                                    {children}
                                                                </Paper>
                                                            )}
                                                            value={selectedHcpType}
                                                            options={HcpTypesList}
                                                            getOptionLabel={(option: any) => option.title}
                                                            placeholder={'Select Staff Type'}
                                                            onBlur={handleBlur('hcp_type_id')}
                                                            id='input_select_shift_type'
                                                            className={classes.autocomplete}
                                                            onChange={($event, code) => {
                                                                // console.log(code);
                                                                dispatch(getStaffSpecialityList(code?.code));
                                                                setSelectedHcpType(code);
                                                                setFieldValue('hcp_type_id', code?.code);
                                                            }}
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    {...params}
                                                                    id='select_fac_payment_status'
                                                                    variant='outlined'
                                                                    placeholder={'Select Staff Type'}
                                                                />
                                                            )}
                                                        />
                                                        <div className={'d-flex justify-content-right'}>
                                                            {touched.hcp_type_id &&
                                                            errors.hcp_type_id &&
                                                            typeof errors.hcp_type_id === 'string' ? (
                                                                // <div>{errors.hcp_type_id}</div>
                                                                <ErrorComponent
                                                                    errorText={errors.hcp_type_id}
                                                                />
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='ts-col-4'>
                                                    <Field name={'hcp_speciality_id'}>
                                                        {(field: FieldProps) => (
                                                            <FormikSelectDropdownComponent
                                                                options={staffSpecialityList}
                                                                displayWith={(option: any) => option.title}
                                                                valueExtractor={(option: any) => option.code}
                                                                label={'Staff Speciality'}
                                                                disabled={!values?.hcp_type_id}
                                                                placeholder={'Select'}
                                                                formikField={field}
                                                                searchable={true}
                                                                fullWidth={true}
                                                            />
                                                        )}
                                                    </Field>
                                                </div>
                                                <div className='ts-col-4'>
                                                    <LabelComponent title={'Visit Type*'}/>
                                                    <Autocomplete
                                                        disableClearable
                                                        PaperComponent={({children}) => (
                                                            <Paper style={{color: '#1e1e1e'}}>
                                                                {children}
                                                            </Paper>
                                                        )}
                                                        value={selectedShiftType}
                                                        options={home_shift_type}
                                                        getOptionLabel={(option: any) => option.title}
                                                        placeholder={'Select Visit Type'}
                                                        onBlur={handleBlur('shift_type')}
                                                        id='input_select_shift_type'
                                                        className={classes.autocomplete}
                                                        onChange={($event, code) => {
                                                            // console.log(code);
                                                            setSelectedShiftType(code);
                                                            setFieldValue('shift_type', code?.code);
                                                        }}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                id='select_fac_payment_status'
                                                                variant='outlined'
                                                                placeholder={'Select Shift Type'}
                                                            />
                                                        )}
                                                    />
                                                    <div className={'d-flex justify-content-right'}>
                                                        {touched.shift_type &&
                                                        errors.shift_type &&
                                                        typeof errors.shift_type === 'string' ? (
                                                            // <div>{errors.hcp_type_id}</div>
                                                            <ErrorComponent errorText={errors.shift_type}/>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                className={
                                                    'shift-requirement-divider-line mrg-bottom-10'
                                                }
                                            />
                                            <div>
                                                <div className={'form-header-text mrg-bottom-10'}>
                                                    Visit Patient By
                                                </div>
                                                <div className={'ts-row'}>
                                                    <div className='ts-col-4'>
                                                        <LabelComponent title={'Date*'}/>
                                                        <div className='shift-calender mrg-bottom-20'>
                                                            <Field name={'shift_dates'}>
                                                                {(field: FieldProps) => (
                                                                    <FormikDatePickerComponent
                                                                        // minDate={new Date()}
                                                                        placeholder={'Select Date'}
                                                                        required={true}
                                                                        formikField={field}
                                                                        fullWidth={true}
                                                                    />
                                                                )}
                                                            </Field>
                                                        </div>
                                                    </div>

                                                    <div className='ts-col-4'>
                                                        <LabelComponent title={'Visit Time*'}/>
                                                        <Field name={'hospice_details.visit_patient_time'}>
                                                            {(field: FieldProps) => (
                                                                <FormikTimePickerComponent
                                                                    placeholder={'Select Time'}
                                                                    required={true}
                                                                    formikField={field}
                                                                    fullWidth={true}
                                                                />
                                                            )}
                                                        </Field>
                                                    </div>
                                                    <div className='ts-col-4'>
                                                        <LabelComponent title={'Visit rate*'}/>
                                                        <Field name={'shift_rate'}>
                                                            {(field: FieldProps) => (
                                                                <FormikInputComponent
                                                                    className={'mrg-bottom-set-zero'}
                                                                    //  label={'Visit Rate*'}
                                                                    placeholder={'Enter Rate'}
                                                                    validationPattern={new RegExp(/^(?:[0-9]{1,3}(?:\.[0-9]{1,3})?)?$/)}
                                                                    type={'number'}
                                                                    formikField={field}
                                                                    fullWidth={true}
                                                                    suffix={<>$</>}
                                                                />
                                                            )}
                                                        </Field>
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                className={
                                                    'shift-requirement-divider-line mrg-bottom-10'
                                                }
                                            />

                                            <div>
                                                <div className={'form-header-text mrg-bottom-10'}>
                                                    Patient Details
                                                </div>

                                                <div className={'ts-row'}>
                                                    <div className='ts-col-4'>
                                                        <Field name={'hospice_details.first_name'}>
                                                            {(field: FieldProps) => (
                                                                <FormikInputComponent
                                                                    label={'First Name*'}
                                                                    placeholder={'Enter first name'}
                                                                    formikField={field}
                                                                    fullWidth={true}
                                                                />
                                                            )}
                                                        </Field>
                                                    </div>
                                                    <div className='ts-col-4'>
                                                        <Field name={'hospice_details.middle_name'}>
                                                            {(field: FieldProps) => (
                                                                <FormikInputComponent
                                                                    label={'Middle Name'}
                                                                    placeholder={'Enter middle name'}
                                                                    formikField={field}
                                                                    fullWidth={true}
                                                                />
                                                            )}
                                                        </Field>
                                                    </div>
                                                    <div className='ts-col-4'>
                                                        <Field name={'hospice_details.last_name'}>
                                                            {(field: FieldProps) => (
                                                                <FormikInputComponent
                                                                    label={'Last name'}
                                                                    placeholder={'Enter last name'}
                                                                    formikField={field}
                                                                    fullWidth={true}
                                                                />
                                                            )}
                                                        </Field>
                                                    </div>
                                                </div>
                                                <div className="ts-row">
                                                    <div className='ts-col-4'>
                                                        <Field name={'hospice_details.gender'}>
                                                            {(field: FieldProps) => (
                                                                <FormikSelectDropdownComponent
                                                                    options={genderList}
                                                                    displayWith={(option: any) => option.title}
                                                                    valueExtractor={(option: any) => option.code}
                                                                    label={'Gender*'}
                                                                    placeholder={'Select'}
                                                                    formikField={field}
                                                                    fullWidth={true}
                                                                />
                                                            )}
                                                        </Field>
                                                    </div>
                                                    <div className='ts-col-4'>
                                                        <LabelComponent title={'DOB'}/>
                                                        <Field name={'hospice_details.dob'}>
                                                            {(field: FieldProps) => (
                                                                <FormikDatePickerComponent
                                                                    placeholder={'Select'}
                                                                    formikField={field}
                                                                    fullWidth={true}
                                                                    onUpdate={(dob: any) => {
                                                                        const dobDate = new Date(dob);
                                                                        const now = new Date();

                                                                        let years =
                                                                            now.getUTCFullYear() -
                                                                            dobDate.getUTCFullYear();
                                                                        // let months = now.getUTCMonth() - dobDate.getUTCMonth();
                                                                        // let days = now.getUTCDate() - dobDate.getUTCDate();

                                                                        // if (days < 0) {
                                                                        //     months--;
                                                                        //     days += new Date(now.getUTCFullYear(), now.getUTCMonth(), 0).getDate();
                                                                        // }
                                                                        //
                                                                        // if (months < 0) {
                                                                        //     years--;
                                                                        //     months += 12;
                                                                        // }

                                                                        setFieldValue(
                                                                            'hospice_details.age',
                                                                            `${years} years`,
                                                                        );
                                                                    }}
                                                                />
                                                            )}
                                                        </Field>
                                                    </div>
                                                    <div className='ts-col-4'>
                                                        <Field name={'hospice_details.mrn'}>
                                                            {(field: FieldProps) => (
                                                                <FormikInputComponent
                                                                    label={'MRN'}
                                                                    placeholder={'Enter MRN'}
                                                                    formikField={field}
                                                                    fullWidth={true}
                                                                    maxLength={7}
                                                                />
                                                            )}
                                                        </Field>
                                                    </div>
                                                </div>
                                            </div>

                                            <div
                                                className={
                                                    'shift-requirement-divider-line mrg-bottom-10 mrg-top-10'
                                                }
                                            />

                                            <div>
                                                <div className={'form-header-text mrg-bottom-10'}>
                                                    Patient Address Details
                                                </div>
                                                <div className='ts-row'>
                                                    <div className='ts-col-4'>
                                                        <Field name={'hospice_details.street'}>
                                                            {(field: FieldProps) => (
                                                                <FormikInputComponent
                                                                    label={'Street'}
                                                                    placeholder={'Enter Street'}
                                                                    type={'text'}
                                                                    required={true}
                                                                    formikField={field}
                                                                    fullWidth={true}
                                                                />
                                                            )}
                                                        </Field>
                                                    </div>
                                                    <div className='ts-col-4'>
                                                        <Field name={'hospice_details.apt'}>
                                                            {(field: FieldProps) => (
                                                                <FormikInputComponent
                                                                    label={'Apt/suite'}
                                                                    placeholder={'Enter apt/suite'}
                                                                    type={'text'}
                                                                    formikField={field}
                                                                    fullWidth={true}
                                                                />
                                                            )}
                                                        </Field>
                                                    </div>
                                                    <div className='ts-col-4'>
                                                        <Field name={'hospice_details.city'}>
                                                            {(field: FieldProps) => (
                                                                <FormikInputComponent
                                                                    label={'City*'}
                                                                    placeholder={'Enter City'}
                                                                    type={'text'}
                                                                    formikField={field}
                                                                    fullWidth={true}
                                                                />
                                                            )}
                                                        </Field>
                                                    </div>
                                                </div>

                                                <div className='ts-row'>
                                                    <div className='ts-col-6'>
                                                        <Field name={'hospice_details.state'}>
                                                            {(field: FieldProps) => (
                                                                <FormikSelectDropdownComponent
                                                                    options={stateList}
                                                                    displayWith={(option: any) => option.title}
                                                                    valueExtractor={(option: any) => option.code}
                                                                    label={'State*'}
                                                                    //  disabled={!values?.hcp_type_id}
                                                                    placeholder={'Select State'}
                                                                    formikField={field}
                                                                    searchable={true}
                                                                    fullWidth={true}
                                                                />
                                                            )}
                                                        </Field>
                                                    </div>
                                                    <div className='ts-col-3'>
                                                        <Field name={'hospice_details.zipcode'}>
                                                            {(field: FieldProps) => (
                                                                <FormikInputComponent
                                                                    label={'ZIP Code'}
                                                                    placeholder={'Enter ZIP Code'}
                                                                    type={'number'}
                                                                    maxLength={6}
                                                                    required={true}
                                                                    formikField={field}
                                                                    fullWidth={true}
                                                                    onChange={() => {
                                                                        setIsZipcodeValidated(false)
                                                                        setFieldValue('lat', '');
                                                                        setFieldValue('long', '')
                                                                    }}
                                                                />
                                                            )}
                                                        </Field>
                                                    </div>
                                                    <div className={'ts-col-3'}>
                                                        <ButtonComponent
                                                            fullWidth={true}
                                                            onClick={() => handleValidateZipcode(values, setFieldValue)}
                                                            className={"mrg-top-25"}
                                                            disabled={values?.hospice_details?.zipcode?.length < 5}
                                                        >
                                                            {isZipcodeValidated ? "Validated" : "Validate"}
                                                        </ButtonComponent>
                                                    </div>
                                                </div>
                                                <div
                                                    className={'ts-row'}>
                                                    <div className={'ts-col-6'}>
                                                        <Field name={'lat'}>
                                                            {(field: FieldProps) => (
                                                                <FormikInputComponent
                                                                    label={'Latitude'}
                                                                    placeholder={'Enter Latitude'}
                                                                    type={'number'}
                                                                    required={true}
                                                                    formikField={field}
                                                                    fullWidth={true}
                                                                    validationPattern={new RegExp(/^(-?([1-8]?[0-9](\.\d+)?|90(\.0+)?))$/)}
                                                                />
                                                            )}
                                                        </Field>
                                                    </div>
                                                    <div className={'ts-col-6'}>
                                                        <Field name={'long'}>
                                                            {(field: FieldProps) => (
                                                                <FormikInputComponent
                                                                    label={'Longitude'}
                                                                    placeholder={'Enter Longitude'}
                                                                    type={'number'}
                                                                    required={true}
                                                                    formikField={field}
                                                                    fullWidth={true}
                                                                    validationPattern={new RegExp(/^(-?((1[0-7][0-9]|[1-9]?[0-9])(\.\d+)?|180(\.0+)?))$/)}
                                                                />
                                                            )}
                                                        </Field>
                                                    </div>
                                                </div>
                                            </div>
                                            {(values?.lat && values?.long) &&
                                                <div className={'view-map'}  onClick={()=>openMap(values)} >
                                                    View location on Map.
                                                </div>
                                            }
                                            <div
                                                className={
                                                    'shift-requirement-divider-line mrg-bottom-10 mrg-top-10'
                                                }
                                            />

                                            <div>
                                                <div>
                                                    <div className={'form-header-text mrg-bottom-10'}>
                                                        Patient Contact Details
                                                    </div>
                                                </div>
                                                <div className={'ts-row'}>
                                                    <div className="ts-col-4">
                                                        <LabelComponent title={'Phone Number*'}/>
                                                        <Field name={'hospice_details.phone_number'}>
                                                            {(field: FieldProps) => {
                                                                return <PhoneInputComponent field={field}
                                                                                            placeholder={"Enter Phone number"}/>;
                                                            }}
                                                        </Field>
                                                    </div>
                                                    <div className="ts-col-4">
                                                        <Field name={'hospice_details.email_address'}>
                                                            {
                                                                (field: FieldProps) => (
                                                                    <FormikInputComponent
                                                                        label={'Email Address'}
                                                                        placeholder={'example@xyz.com'}
                                                                        type={"text"}
                                                                        //   required={true}
                                                                        formikField={field}
                                                                        fullWidth={true}
                                                                    />
                                                                )
                                                            }
                                                        </Field>
                                                    </div>

                                                </div>
                                            </div>

                                            <div
                                                className={
                                                    'shift-requirement-divider-line mrg-bottom-10 mrg-top-10'
                                                }
                                            />

                                            <div>
                                                <div>
                                                    <div className={'form-header-text mrg-bottom-10'}>
                                                        More Details
                                                    </div>
                                                    <div>
                                                        <div className={'ts-row'}>
                                                            <div className='ts-col-4'>
                                                                <Field name={'number_of_visit'}>
                                                                    {(field: FieldProps) => (
                                                                        <FormikInputComponent
                                                                            className={'mrg-bottom-set-zero'}
                                                                            label={'Total Number of Visits'}
                                                                            placeholder={'Enter Number of Visits'}
                                                                            validationPattern={
                                                                                Patterns.POSITIVE_WHOLE_NUMBERS
                                                                            }
                                                                            type={'number'}
                                                                            formikField={field}
                                                                            fullWidth={true}
                                                                        />
                                                                    )}
                                                                </Field>
                                                            </div>
                                                            <div className='ts-col-4'>
                                                                <Field name={'gender_preference'}>
                                                                    {(field: FieldProps) => (
                                                                        <FormikSelectDropdownComponent
                                                                            options={genderList}
                                                                            displayWith={(option: any) => option.title}
                                                                            valueExtractor={(option: any) => option.code}
                                                                            label={'Gender Preference'}
                                                                            placeholder={'Select'}
                                                                            formikField={field}
                                                                            fullWidth={true}
                                                                        />
                                                                    )}
                                                                </Field>
                                                            </div>
                                                        </div>
                                                        <div className={'ts-row'}>
                                                            <div className='ts-col-12'>
                                                                <Field name={'language'}>
                                                                    {(field: FieldProps) => (
                                                                        <FormikSelectDropdownComponent
                                                                            options={languagesList}
                                                                            multiple={true}
                                                                            displayWith={(option: any) => option.title}
                                                                            valueExtractor={(option: any) => option.code}
                                                                            label={'Languages'}
                                                                            placeholder={'Select'}
                                                                            formikField={field}
                                                                            fullWidth={true}

                                                                        />
                                                                    )}
                                                                </Field>
                                                            </div>
                                                            {values?.language?.includes("Other") && (
                                                                <>
                                                                    <div className='ts-col-6'>
                                                                        <Field name={'other_language'}>
                                                                            {(field: FieldProps) => (
                                                                                <FormikInputComponent
                                                                                    className={'mrg-bottom-set-zero'}
                                                                                    label={'Language'}
                                                                                    placeholder={'Enter'}
                                                                                    type={'text'}
                                                                                    formikField={field}
                                                                                    fullWidth={true}
                                                                                />
                                                                            )}
                                                                        </Field>
                                                                    </div>
                                                                </>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>


                                            <LabelComponent
                                                title={'Send Shift Req to'}
                                                isBold={true}
                                            />
                                            <div className='ts-row mrg-top-10'>
                                                <div className='ts-col-4'>
                                                    <Field name={'send_to.is_internal'}>
                                                        {(field: FieldProps) => (
                                                            <FormikCheckBoxComponent
                                                                formikField={field}
                                                                label={'Internal HCP'}
                                                            />
                                                        )}
                                                    </Field>
                                                </div>
                                                <div className='ts-col-4'>
                                                    <Field name={'send_to.is_float'}>
                                                        {(field: FieldProps) => (
                                                            <FormikCheckBoxComponent
                                                                formikField={field}
                                                                label={'Floating HCP'}
                                                            />
                                                        )}
                                                    </Field>
                                                </div>
                                                <div className='ts-col-4'>
                                                    <Field name={'send_to.specific_agencies'}>
                                                        {(field: FieldProps) => (
                                                            <FormikCheckBoxComponent
                                                                formikField={field}
                                                                label={'Agency'}
                                                                onChange={(e) => {
                                                                    setIsAgencySelected(e);
                                                                    if (e) {
                                                                        const allAgencyCodes = agencyList.map(
                                                                            (item: any) => item,
                                                                        );
                                                                        setSelectedAgency(allAgencyCodes);
                                                                    } else {
                                                                        setSelectedAgency([]);
                                                                    }
                                                                }}
                                                            />
                                                        )}
                                                    </Field>
                                                </div>
                                            </div>
                                            {isAgencySelected && (
                                                <FormControl
                                                    sx={{m: 1, minWidth: '818px', maxWidth: '818px'}}
                                                >
                                                    {/*<InputLabel id="demo-multiple-checkbox-label">*/}
                                                    {/*    {" "}*/}
                                                    {/*    Select Agency*/}
                                                    {/*</InputLabel>*/}
                                                    {selectedAgency?.length === 0 && (
                                                        <div
                                                            className={
                                                                'material-select-box-placeholder-custom'
                                                            }
                                                        >
                                                            Select Agency
                                                        </div>
                                                    )}
                                                    <Select
                                                        labelId='demo-multiple-checkbox-label'
                                                        id='demo-multiple-checkbox'
                                                        multiple
                                                        value={selectedAgency}
                                                        onChange={handleAgencyTypeSelection}
                                                        // input={<OutlinedInput label="Select Agency"/>}
                                                        renderValue={(selected) =>
                                                            selected
                                                                .map((item: any) => item?.title)
                                                                .join(', ')
                                                        }
                                                        MenuProps={MenuProps}
                                                    >
                                                        {agencyList.map((item: any) => (
                                                            <MenuItem key={item.code} value={item}>
                                                                <Checkbox
                                                                    style={{
                                                                        color: '#202937',
                                                                    }}
                                                                    checked={selectedAgency.some(
                                                                        (selectedItem: any) =>
                                                                            selectedItem.code === item.code,
                                                                    )}
                                                                />
                                                                <ListItemText primary={item.title}/>
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            )}

                                            <div
                                                className={
                                                    'document-upload-wrapper d-flex justify-content-space-between mrg-top-10'
                                                }
                                            >
                                                <div>
                                                    <div className={'form-header-text'}>
                                                        Upload Documents
                                                    </div>
                                                    <div className={'file-upload-supported-text'}>
                                                        Upload the relevant documents
                                                    </div>
                                                    <div className={'file-upload-supported-text'}>
                                                        Supported formats : .pdf, .jpg, .jpeg, .png
                                                    </div>
                                                </div>
                                                <div>
                                                    <FilePickerComponent
                                                        maxFileCount={3}
                                                        height={'470px'}
                                                        id={'sc_upload_btn'}
                                                        btnText={'Select Files'}
                                                        disabled={requirementUploadFiles?.length === 3}
                                                        multiple={true}
                                                        showDropZone={false}
                                                        onFilesDrop={(
                                                            acceptedFiles: any,
                                                            rejectedFiles: any,
                                                        ) => {
                                                            if (acceptedFiles && acceptedFiles.length > 0) {
                                                                const file = acceptedFiles[0];
                                                                //   setTimesheetFile(file);
                                                                setTimesheetFile([...requirementUploadFiles, file]);
                                                            }
                                                        }}
                                                        acceptedFileTypes={['pdf', 'jpeg', 'jpg', 'png']}
                                                        uploadDescription={'(upload only one file)'}
                                                        acceptedFilesText={'Only .pdf files are supported'}
                                                    />
                                                    <div
                                                        className={
                                                            'd-flex justify-content-end mrg-top-5 file-upload-supported-text'
                                                        }
                                                    >
                                                        Max upload : 3
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={'d-flex-wrap mrg-top-10'}>
                                                {requirementUploadFiles &&
                                                    requirementUploadFiles?.map((file: any, index: number) => {
                                                        return (
                                                            <>
                                                                <div
                                                                    className={
                                                                        'd-flex justify-content-space-between file-holder-wrapper mrg-right-10 mrg-10'
                                                                    }
                                                                >
                                                                    <div> {file?.name}</div>
                                                                    <div
                                                                        className={'icon-button'}
                                                                        onClick={() => {
                                                                            removeFile(index);
                                                                        }}
                                                                    >
                                                                        <ImageConfig.DeleteIcon/>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        );
                                                    })}
                                            </div>
                                        </div>
                                    </div>
                                </>
                                <div
                                    className={'ts-row mrg-top-15'}
                                    style={{alignSelf: 'center'}}
                                >
                                    <ButtonComponent
                                        disabled={isSubmitting || !isValid}
                                        isLoading={isSubmitting}
                                        type={'submit'}
                                        style={{backgroundColor: '#192938', color: 'white'}}
                                        suffixIcon={<ImageConfig.ArrowRightCircleIcon/>}
                                    >
                                        Create Shift
                                    </ButtonComponent>
                                </div>
                            </div>
                        </Form>
                    )
                        ;
                }}
            </Formik>
        </>
    );
};

export default HomeHealthHospiceTypeShiftRequirement;
