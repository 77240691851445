import "./EditWingsComponent.scss";
import * as Yup from "yup";
import React, {useCallback, useEffect, useState} from "react";
import {Field, FieldProps, Form, Formik, FormikHelpers} from "formik";
import {ImageConfig, Misc} from "../../../../constants";
import FormikInputComponent from "../../../../shared/components/form-controls/formik-input/FormikInputComponent";
import ButtonComponent from "../../../../shared/components/button/ButtonComponent";
import {CommonService} from "../../../../shared/services";
import {IAPIResponseType} from "../../../../shared/models/api.model";
import {useSelector} from "react-redux";
import {IRootReducerState} from "../../../../store/reducers";
import HorizontalLineComponent
    from "../../../../shared/components/horizontal-line/horizontal-line/HorizontalLineComponent";

interface EditWingsComponentProps {
    closeEditDrawer: any;
    getFacilityDetails?: any;
    selectedFacilityForEdit?: any;
}

const EditWingValidationSchema = Yup.object().shape({
    name: Yup.string().required('Wing name is required'),
});


interface EditWingFormInitialValuesFormSchema {
    name: string;

}

const AddWingFormInitialValues: EditWingFormInitialValuesFormSchema = {
    name: "",
};


const EditWingComponent = (props: EditWingsComponentProps) => {
    const {closeEditDrawer, getFacilityDetails, selectedFacilityForEdit} = props;
    const [initialValues, setInitialValues] = useState<EditWingFormInitialValuesFormSchema>(AddWingFormInitialValues);
    const {wingsList} = useSelector((state: IRootReducerState) => state.staticData);

    useEffect(() => {
        console.log(selectedFacilityForEdit);
        const basicDetails = {
            name: selectedFacilityForEdit?.name?.name?.length > 0 ? selectedFacilityForEdit?.name?.name : selectedFacilityForEdit?.name,
        };
        setInitialValues(basicDetails);
    }, [selectedFacilityForEdit]);

    const onSubmit = useCallback((values: any, {setErrors, setSubmitting}: FormikHelpers<any>) => {
        setSubmitting(true);
        const payload = {
            ...values,
        };
        let apiCall: any = {};
        apiCall = CommonService._facility.EditWingAPICall(selectedFacilityForEdit?._id, payload);
        apiCall.then((response: IAPIResponseType<any>) => {
            CommonService._alert.showToast(response[Misc.API_RESPONSE_MESSAGE_KEY], "success");
            closeEditDrawer();
            getFacilityDetails();
        })
            .catch((error: any) => {
                CommonService.handleErrors(setErrors, error);
            }).finally(() => {
            setSubmitting(false);
        });
    }, [closeEditDrawer, getFacilityDetails, selectedFacilityForEdit?._id]);

    // const onShiftTimingSubmit = useCallback((values: any, {setErrors, setSubmitting}: FormikHelpers<any>) => {
    //     setIsAddShiftTimingsButton(false);
    //     const uniqueId: string = uuidv4();
    //     const newShiftTime = {
    //         ...values.shift_timings,
    //         shift_start_time: CommonService.convertToAdjustedMinutes(values.shift_timings?.shift_start_time),
    //         shift_end_time: CommonService.convertToAdjustedMinutes(values.shift_timings?.shift_end_time),
    //         // Spread existing properties from values.shift_timings
    //         id: uniqueId,           // Add the new key-value pair
    //     };
    //     setShiftTimeList([...shiftTimeList, newShiftTime]);
    //     setIsAddShiftTimingsButton(true);
    // }, [shiftTimeList]);


    return (
        <>
            <div className={'wing-add-facility-component'} style={{minWidth: "200px"}}>
                <div className={'edit-wing-header'}>
                    <div className={'form-header-text'}>Edit Wings Details</div>
                    <div className={'drawer-close-button'} onClick={closeEditDrawer}><ImageConfig.CrossIcon height={20}
                                                                                                            width={20}/>
                    </div>
                </div>
                <div className={'mrg-bottom-10'}>
                    <HorizontalLineComponent/>
                </div>
                <div className={'edit-wing-form-wrapper'}>
                    <Formik
                        validationSchema={EditWingValidationSchema}
                        initialValues={initialValues}
                        validateOnChange={false}
                        validateOnBlur={true}
                        enableReinitialize={true}
                        validateOnMount={true}
                        onSubmit={onSubmit}
                    >
                        {({values, errors, setErrors, validateForm, isValid, setFieldValue, isSubmitting}) => {
                            // eslint-disable-next-line react-hooks/rules-of-hooks
                            useEffect(() => {
                                validateForm();
                            }, [validateForm, values]);
                            return (
                                <Form className="t-form scroll-content form-holder pdd-10" noValidate={true}>
                                    {/*<FormDebuggerComponent values={values} errors={errors} showDebugger={true}/>*/}
                                    <div className={'add-form-holder'}>
                                        <div className={'add-form-input-container mrg-bottom-10'}>
                                            <div className="edit-component-content">
                                                <Field validate={() => {
                                                    if (wingsList?.some((wing: any) => wing.name === values?.name)) {
                                                        return `${values?.name} already exists in the list`;
                                                    }
                                                }} name={'name'}>
                                                    {
                                                        (field: FieldProps) => (
                                                            <FormikInputComponent
                                                                label={'Wing Name'}
                                                                placeholder={'Enter Wing Name'}
                                                                type={"text"}
                                                                required={true}
                                                                formikField={field}
                                                                fullWidth={true}
                                                            />
                                                        )
                                                    }
                                                </Field>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="add-wing-form-action-button">
                                        <ButtonComponent
                                            disabled={isSubmitting || !isValid || !values?.name}
                                            isLoading={isSubmitting}
                                            color={'secondary'}
                                            type={'submit'}
                                        >
                                            Save Details
                                        </ButtonComponent>
                                    </div>
                                </Form>
                            );
                        }}
                    </Formik>
                </div>
            </div>
        </>
    );
};
export default EditWingComponent;




