import React, {useMemo} from 'react';
import {ITableColumn} from "../models/table.model";
import ToolTipComponent from "../components/tool-tip/ToolTipComponent";
import TextWithIconComponent from "../components/text-with-icon/TextWithIconComponent";
import {ImageConfig} from "../../constants";
import {CommonService} from "../services";
import {shiftTypeLayoutRepresentation} from "../../data";

export const useUserListColumn = (): ITableColumn[] => {
    return useMemo(() => {
        return [
            {
                title: () => <TextWithIconComponent prefix={<ImageConfig.ShiftDate/>}>Shift
                    Date</TextWithIconComponent>,
                key: 'shift_date',
                dataIndex: 'shift_date',
                width: 150,
                render: (item: any) => {
                    if (item?.shift_date) {
                        return <>{CommonService.convertDateFormat2(item?.shift_date, "MM-DD-YYYY")}</>;
                    } else {
                        return <>N/A</>;
                    }
                }
            },
            {
                title: () => <TextWithIconComponent prefix={<ImageConfig.ShiftTime/>}>Shift
                    Time</TextWithIconComponent>,
                key: 'shift-time',
                dataIndex: 'shift-time',
                width: 150,
                render: (item: any) => {
                    if (item?.requirement_details?.shift_timings) {
                        return <>{CommonService.convertMinutesToTime(item?.requirement_details?.shift_timings?.start_time) + "-" + CommonService.convertMinutesToTime(item?.requirement_details?.shift_timings?.end_time)}</>;
                    } else {
                        return <>{CommonService.convertMinutesToTime(item?.expected?.shift_start_time) + "-" + CommonService.convertMinutesToTime(item?.expected?.shift_end_time)}</>;
                    }
                }
            },
            {
                title: () => <TextWithIconComponent prefix={<ImageConfig.Wing/>}>Wing</TextWithIconComponent>,
                key: 'wing',
                dataIndex: 'wing',
                width: 100,
                align: 'left',
                render: (item: any) => {
                    return <div className={'hcp-details-wrapper'}>
                        <div className={'hcp-name-tower-wrapper'}>
                            {
                                (item?.facility_wing_details?.name)?.length > 10 ?
                                    <ToolTipComponent
                                        tooltip={item?.facility_wing_details?.name}
                                        showArrow={true}
                                        position={"top"}
                                    >
                                        <div className={"ellipses-for-calender-card-data property-name"}>
                                            {item?.facility_wing_details?.name}
                                        </div>
                                    </ToolTipComponent> :
                                    <div className="hcp-name">
                                        {item?.facility_wing_details?.name || '-'}
                                    </div>
                            }
                        </div>
                    </div>;
                }
            },
            {
                title: () => <TextWithIconComponent prefix={<ImageConfig.ShiftType/>}>Shift Type</TextWithIconComponent>,
                key: 'shift_type',
                dataIndex: 'shift_type',
                width: 200,
                render: (item: any) => {
                    return <div className={'d-flex justify-content-center align-items-center'}>
                        {
                            (() => {
                                const foundShiftType = shiftTypeLayoutRepresentation.find(shiftTypeObj => shiftTypeObj.code === item?.shift_type);
                                if (foundShiftType) {
                                    return <>
                                        <div
                                            className={'mrg-right-5 mrg-top-5'}>
                                            {foundShiftType.icon}
                                        </div>
                                        <div className={foundShiftType.color}>
                                            {item?.shift_type}
                                        </div>
                                    </>;
                                } else {
                                    return null;
                                }
                            })()
                        }
                    </div>;
                }
            },

        ];
    }, []);
};