import React, {useMemo} from 'react';
import {ITableColumn} from "../../../shared/models/table.model";
import {CommonService} from "../../../shared/services";
import ToolTipComponent from "../../../shared/components/tool-tip/ToolTipComponent";
import {ImageConfig} from '../../../constants';
import LinkComponent from '../../../shared/components/link/LinkComponent';
import CustomToggleComponent
    from "../../../shared/components/custom-toogle-using-button-component/CustomToggleComponent";
import TagComponent from "../../../shared/components/tag/TagComponent";

export const useUserListColumn = (onUserActivateDeactivate: any, setIsEdituserDetailsDrawerOpen: any, setUserDetails: any): ITableColumn[] => {
    return useMemo(() => {
        return [
            {
                title: "Username",
                key: 'user_name',
                dataIndex: 'user_management_name',
                fixed: 'left',
                // sortable: true,
                width: 200,
                render: (item: any) => {
                    return <div className={'user-management-details-wrapper'}>
                        {/*<AvatarComponent url={item?.profilePic?.url} size={'xs'}/>*/}
                        <div className={'user-management-name-wrapper'}>
                            {
                                (item?.first_name + " " + item?.last_name)?.length > 20 ?
                                    <ToolTipComponent
                                        tooltip={item?.first_name + " " + item?.last_name}
                                        showArrow={true}
                                        position={"top"}
                                    >
                                        <div className={"ellipses-for-table-data property-name"}>
                                            {item?.first_name + " " + item?.last_name}
                                        </div>
                                    </ToolTipComponent> :
                                    <div className="facility-name">
                                        {item?.first_name + " " + item?.last_name || '-'}
                                    </div>
                            }
                        </div>
                    </div>;
                }
            },
            {
                title: "Phone Number",
                key: 'phone_number',
                dataIndex: 'phone_number',
                align: 'center',
                width: 200,
                render: (item: any) => {
                    return <>
                        <div>
                            {item?.contact_number || "-"}
                        </div>
                    </>;
                }
            },
            {
                title: "Email ID",
                key: 'email_address',
                dataIndex: 'email_address',
                align: 'left',
                width: 200,
                render: (item: any) => {
                    return <>
                        <div className={'facility-details-wrapper'}>
                            {/*<AvatarComponent url={item?.profilePic?.url} size={'xs'}/>*/}
                            <div className={'facility-name-tower-wrapper'}>
                                {
                                    (item?.email)?.length > 20 ?
                                        <ToolTipComponent
                                            tooltip={item?.email}
                                            showArrow={true}
                                            position={"top"}
                                        >
                                            <div className={"ellipses-for-table-data property-name"}>
                                                {item?.email}
                                            </div>
                                        </ToolTipComponent> :
                                        <div className="facility-name">
                                            {item?.email || '-'}
                                        </div>
                                }
                            </div>
                        </div>
                    </>;
                }
            },
            {
                title: "Status",
                key: 'status',
                dataIndex: 'status',
                align: 'left',
                width: 150,
                render: (item: any) => {
                    return <>
                        <TagComponent
                            color={item?.is_active ? CommonService.getStatusTagColor('Checked In') : CommonService.getStatusTagColor('Checked Out')}
                            // showIndicator={true}
                            label={item?.is_active ? 'Active' : 'Inactive'}/>
                    </>;
                }
            },
            {
                title: 'Activate/Deactivate',
                key: 'activate-deactivate',
                dataIndex: 'activate',
                align: 'center',
                width: 50,
                render: (item: any) => {
                    return <>
                        <CustomToggleComponent defaultOn={item?.is_active} toggleHandler={() => {
                            onUserActivateDeactivate(item);
                        }}/>
                    </>;
                }
            },
            {
                title: "",
                dataIndex: "actions",
                align: 'center',
                key: "actions",
                width: 100,
                fixed: 'right',
                render: (item: any) => {
                    if (item?._id) {
                        return <LinkComponent onClick={() => {
                            setIsEdituserDetailsDrawerOpen(true);
                            setUserDetails(item);
                        }}>
                            <ImageConfig.PencilEditIcon/>
                        </LinkComponent>;
                    }
                }
            }
        ];
    }, [onUserActivateDeactivate, setIsEdituserDetailsDrawerOpen, setUserDetails]);
};