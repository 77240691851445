import { useMemo } from 'react';
import { Checkbox, Typography } from '@mui/material';
import { ITableColumn } from '../../../shared/models/table.model';
import { CommonService } from '../../../shared/services';
import ToolTipComponent from '../../../shared/components/tool-tip/ToolTipComponent';
import { ImageConfig } from '../../../constants';
import LinkComponent from '../../../shared/components/link/LinkComponent';
import { shiftTypeLayoutRepresentation } from '../../../data';
import TextWithIconComponent from "../../../shared/components/text-with-icon/TextWithIconComponent";

export const useOpenShiftsListColumn = (filterData: any): ITableColumn[] => {
  return useMemo(() => {
    return [
      {
        title: 'Title',
        key: 'title',
        dataIndex: 'title',
        fixed: 'left',
        width: 320,
        render: (item: any) => {
          return (
            <div className={'hcp-details-wrapper'}>
              <div className={'hcp-name-tower-wrapper'}>
                {/*{*/}
                {/*    (CommonService.convertDateFormat2(item?.shift_date, "MM-DD-YYYY") + " | " + (item?.hcp_type_details?.title || "") + " | " + item?.shift_type)?.length > 30 ?*/}
                {/*        <ToolTipComponent*/}
                {/*            tooltip={CommonService.convertDateFormat2(item?.shift_date, "MM-DD-YYYY") + " | " + (item?.hcp_type_details?.title || "") + " | " + item?.shift_type}*/}
                {/*            showArrow={true}*/}
                {/*            position={"top"}*/}
                {/*        >*/}
                {/*            <div className={*/}
                {/*                // "ellipses-for-table-data" +*/}
                {/*                "property-name"}>*/}
                {/*                {CommonService.convertDateFormat2(item?.shift_date, "MM-DD-YYYY") + " | " + (item?.hcp_type_details?.title || "") + " | " + item?.shift_type}*/}
                {/*            </div>*/}
                {/*        </ToolTipComponent> :*/}
                {/*}*/}
                <div className='hcp-name shift-title-text'>
                  <div>
                    <LinkComponent
                      route={CommonService._routeConfig.ShiftRequirementDetailsRoute(
                        filterData?.status,
                        item?._id,
                      )}
                    >
                      <div className={'d-flex'}>
                        <div className={'mrg-right-10'}>
                          {CommonService.convertDateFormat2(
                            item?.shift_date,
                            'MM-DD-YYYY',
                          ) +
                            ' | ' +
                            (item?.hcp_type_details?.title || '') +
                            ' | ' +
                            item?.shift_type}
                        </div>
                        <ImageConfig.TitleViewIcon />
                      </div>
                    </LinkComponent>
                  </div>
                </div>
              </div>
            </div>
          );
        },
      },
      {
        title: 'Shift Date',
        key: 'shift_date',
        dataIndex: 'shift_date',
        width: 120,
        render: (item: any) => {
          if (item?.shift_date) {
            return (
              <>
                {CommonService.convertDateFormat2(
                  item?.shift_date,
                  'MM-DD-YYYY',
                )}
              </>
            );
          } else {
            return <>N/A</>;
          }
        },
      },
      {
        title:
            <>
              {filterData?.requirementType === "Home Health" ? "Visit Time" : "Shift Time"}
            </>,
        key: 'shift-time',
        dataIndex: 'shift-time',
        width: 200,
        render: (item: any) => {
          // if (item?.shift_timings) {
          //   return (
          //     <>
          //       {CommonService.convertMinutesToTime(
          //         item?.shift_timings?.start_time,
          //       ) +
          //         '-' +
          //         CommonService.convertMinutesToTime(
          //           item?.shift_timings?.end_time,
          //         )}
          //     </>
          //   );
          // } else {
          //   return <>N/A</>;
          // }
          if (item?.shift_timings) {
            return (
                <>
                  {filterData.requirementType === "Home Health" &&
                      <div>
                        {CommonService.convertMinutesToTime(
                            item?.hospice_details?.visit_patient_time,
                        )}
                      </div>
                  }
                  {filterData.requirementType !== "Home Health" &&
                      <div>
                        {CommonService.convertMinutesToTime(
                                item?.shift_timings?.start_time,
                            ) +
                            ' - ' +
                            CommonService.convertMinutesToTime(
                                item?.shift_timings?.end_time,
                            )}
                      </div>
                  }
                </>
            );
          } else {
            return <>N/A</>;
          }
        },
      },
      {
        title: 'Staff Type',
        key: 'hcp-type',
        dataIndex: 'hcp_type',
        width: 160,
        render: (item: any) => {
          return (
            <>
              {item?.hcp_type_details?.title
                ? item?.hcp_type_details?.title
                : '_'}
            </>
          );
        },
      },
      {
        title: 'Shift Type',
        key: 'shift-type',
        dataIndex: 'shift-type',
        width: 150,
        render: (item: any) => {
          return (
            <div className={'d-flex justify-content-center align-items-center'}>
              {(() => {
                const foundShiftType = shiftTypeLayoutRepresentation.find(
                  (shiftTypeObj) => shiftTypeObj.code === item?.shift_type,
                );
                if (foundShiftType) {
                  return (
                    <>
                      <div className={'mrg-right-5 mrg-top-5'}>
                        {foundShiftType.icon}
                      </div>
                      <div className={foundShiftType.color}>
                        {item?.shift_type}
                      </div>
                    </>
                  );
                } else {
                  return null;
                }
              })()}
            </div>
          );
        },
      },
      {
        title: 'Wing',
        key: 'wing',
        dataIndex: 'wing',
        width: 137,
        align: 'left',
        render: (item: any) => {
          return (
            <div className={'hcp-details-wrapper'}>
              <div className={'hcp-name-tower-wrapper'}>
                {item?.facility_wing_details?.name?.length > 20 ? (
                  <ToolTipComponent
                    tooltip={item?.facility_wing_details?.name}
                    showArrow={true}
                    position={'top'}
                  >
                    <div className={'ellipses-for-table-data property-name'}>
                      {item?.facility_wing_details?.name}
                    </div>
                  </ToolTipComponent>
                ) : (
                  <div className='hcp-name'>
                    {item?.facility_wing_details?.name || '-'}
                  </div>
                )}
              </div>
            </div>
          );
        },
      },
      {
        title: () => (
          <TextWithIconComponent prefix={<ImageConfig.CheckmarkRound />}>
            Status
          </TextWithIconComponent>
        ),
        key: 'timesheet',
        dataIndex: 'timesheet',
        width: 150,
        fixed: 'right',
        // sortable: true,
        // render: (item: any) => {
        //     return <>{item?.fms?.map((item: any) => {
        //         return <>{item?.name}</>
        //     }) || "-"}</>
        // }
        render: (item: any) => {
          return (
            <div className={'requirement-status-tag'}>
              <div
                onClick={() => {
                  console.log(item?.status);
                }}
                className={`requirement-status requirement-${item?.status}-status`}
              >
                {item?.status === 'partially_open' && <div>Partially Open</div>}
                {item?.status !== 'partially_open' &&
                  CommonService.capitalizeFirstLetter(item?.status)}
              </div>
            </div>
          );
        },
      },
      {
        title: 'Staff Filled',
        key: 'hcp-filled',
        dataIndex: 'hcp-filled',
        align: 'left',
        width: 100,
        fixed: 'right',
        render: (item: any) => {
          let hcpCount = item?.approved_hcp_count;
          let exceededHcpCount = 0;
          if (hcpCount > item?.required_hcp_count) {
            exceededHcpCount = hcpCount - item?.required_hcp_count;
            hcpCount = item?.required_hcp_count;
          }
          return (
            <>
              <div
                style={{ width: '100%', display: 'flex', alignItems: 'center' }}
              >
                {/*<Box sx={{width: '80%'}}>*/}
                {/*    <LinearProgress*/}
                {/*        sx={{borderRadius: "5px", minHeight: "8px", backgroundColor: "#F2F4F7"}}*/}
                {/*        variant="determinate" value={progress}/>*/}
                {/*</Box>*/}
                <div className={'mrg-left-20'}>
                  <Typography>
                    {item?.approved_hcp_count}/
                    {Number(item?.required_hcp_count) +
                      Number(exceededHcpCount)}
                  </Typography>
                </div>
              </div>
            </>
          );
        },
      },
      // {
      //     title: "View Details",
      //     dataIndex: "actions",
      //     align: 'center',
      //     key: "actions",
      //     width: 100,
      //     fixed: 'right',
      //     render: (item: any) => {
      //         if (item?._id) {
      //             return <div>
      //                 <LinkComponent
      //                     route={CommonService._routeConfig.ShiftRequirementDetailsRoute(filterData?.status, item?._id)}>
      //                     <ImageConfig.ArrowRightOutlinedIcon/>
      //                 </LinkComponent>
      //             </div>;
      //         }
      //     }
      // }
    ];
  }, [filterData]);
};

export const useOtherShiftsListColumn = (
  activeTab: string,
  handleSelectShifts: any,
  isSelected: any,
  setOpenDrawer: any,
  setShiftDetails: any,
): ITableColumn[] => {
  return useMemo(() => {
    return [
      {
        title: '',
        key: 'selection_checkbox',
        dataIndex: 'selection_checkbox',
        fixed: 'left',
        width: activeTab !== 'cancelled' ? 70 : 1,
        render: (item: any) => {
          const isItemSelected = isSelected(item?._id);
          return (
            <>
              {activeTab !== 'cancelled' ? (
                <Checkbox
                  style={{ color: '#202937' }}
                  id={'cb_' + item?._id}
                  checked={isItemSelected}
                  onChange={(event) => handleSelectShifts(event, item?._id)}
                />
              ) : null}
            </>
          );
        },
      },
      {
        title: 'Shift Title',
        key: 'title',
        dataIndex: 'title',
        fixed: 'left',
        width: 320,
        render: (item: any) => {
          return (
            <div className={'hcp-details-wrapper'}>
              <div className={'hcp-name-tower-wrapper'}>
                {/*{*/}
                {/*    (CommonService.convertDateFormat2(item?.shift_date, "MM-DD-YYYY") + " | " + (item?.hcp_type_details?.title || "") + " | " + item?.shift_type)?.length > 20 ?*/}
                {/*        <ToolTipComponent*/}
                {/*            tooltip={CommonService.convertDateFormat2(item?.shift_date, "MM-DD-YYYY") + " | " + (item?.hcp_type_details?.title || "") + " | " + item?.shift_type}*/}
                {/*            showArrow={true}*/}
                {/*            position={"top"}*/}
                {/*        >*/}
                {/*            <div className={"ellipses-for-table-data property-name"}>*/}
                {/*                {CommonService.convertDateFormat2(item?.shift_date, "MM-DD-YYYY") + " | " + (item?.hcp_type_details?.title || "") + " | " + item?.shift_type}*/}
                {/*            </div>*/}
                {/*        </ToolTipComponent> :*/}
                {/*        <div className="hcp-name">*/}
                {/*            {CommonService.convertDateFormat2(item?.shift_date, "MM-DD-YYYY") + " | " + (item?.hcp_type_details?.title || "") + " | " + item?.shift_type}*/}
                {/*        </div>*/}
                {/*}*/}
                <div className='hcp-name shift-title-text'>
                  <div>
                    <LinkComponent
                      onClick={() => {
                        setOpenDrawer(true);
                        setShiftDetails(item);
                      }}
                    >
                      <div className={'d-flex'}>
                        <div className={'mrg-right-10'}>
                          {CommonService.convertDateFormat2(
                            item?.shift_date,
                            'MM-DD-YYYY',
                          ) +
                            ' | ' +
                            (item?.hcp_type_details?.title || '') +
                            ' | ' +
                            item?.shift_type}
                        </div>
                        <ImageConfig.TitleViewIcon />
                      </div>
                    </LinkComponent>
                  </div>
                </div>
              </div>
            </div>
          );
        },
      },
      {
        title: 'Agency Name',
        key: 'agency_name',
        dataIndex: 'agencies_name',
        width: 200,
        render: (item: any) => {
          return (
            <div className={'hcp-details-wrapper'}>
              <div className={'hcp-name-tower-wrapper'}>
                {item?.agency_details?.name?.length > 20 ? (
                  <ToolTipComponent
                    tooltip={item?.agency_details?.name}
                    showArrow={true}
                    position={'top'}
                  >
                    <div className={'ellipses-for-table-data property-name'}>
                      {item?.agency_details?.name}
                    </div>
                  </ToolTipComponent>
                ) : (
                  <div className='hcp-name'>{item?.agency_details?.name}</div>
                )}
              </div>
            </div>
          );
        },
      },
      {
        title: 'Staff Name',
        key: 'hcp_name',
        dataIndex: 'hcp_name',
        width: 200,
        align: 'left',
        render: (item: any) => {
          return (
            <div className={'hcp-details-wrapper'}>
              <div className={'hcp-name-tower-wrapper'}>
                {(item?.hcp_details?.first_name + item?.hcp_details?.last_name)
                  ?.length > 20 ? (
                  <ToolTipComponent
                    tooltip={
                      item?.hcp_details?.first_name +
                      ' ' +
                      item?.hcp_details?.last_name
                    }
                    showArrow={true}
                    position={'top'}
                  >
                    <div className={'ellipses-for-table-data property-name'}>
                      {item?.hcp_details?.first_name +
                        ' ' +
                        item?.hcp_details?.last_name}
                    </div>
                  </ToolTipComponent>
                ) : (
                  <div className='hcp-name'>
                    {item?.hcp_details?.first_name +
                      ' ' +
                      item?.hcp_details?.last_name || '-'}
                  </div>
                )}
              </div>
            </div>
          );
        },
      },
      {
        title: 'Shift Date',
        key: 'shift_date',
        dataIndex: 'shift_date',
        width: 120,
        render: (item: any) => {
          if (item?.shift_date) {
            return (
              <>
                {CommonService.convertDateFormat2(
                  item?.shift_date,
                  'MM-DD-YYYY',
                )}
              </>
            );
          } else {
            return <>N/A</>;
          }
        },
      },
      {
        title: 'Shift Time',
        key: 'shift-time',
        dataIndex: 'shift-time',
        width: 200,
        render: (item: any) => {
          if (item?.expected) {
            return (
              <>
                {CommonService.convertMinutesToTime(
                  item?.expected?.shift_start_time,
                ) +
                  '-' +
                  CommonService.convertMinutesToTime(
                    item?.expected?.shift_end_time,
                  )}
              </>
            );
          } else {
            return <>N/A</>;
          }
        },
      },
      {
        title: 'Staff Type',
        key: 'hcp-type',
        dataIndex: 'hcp_type',
        width: 180,
        render: (item: any) => {
          return (
            <>
              {item?.hcp_type_details?.title
                ? item?.hcp_type_details?.title
                : '_'}
            </>
          );
        },
      },
      {
        title: 'Shift Type',
        key: 'shift-type',
        dataIndex: 'shift-type',
        render: (item: any) => {
          return (
            <div className={'d-flex justify-content-center align-items-center'}>
              {(() => {
                const foundShiftType = shiftTypeLayoutRepresentation.find(
                  (shiftTypeObj) => shiftTypeObj.code === item?.shift_type,
                );
                if (foundShiftType) {
                  return (
                    <>
                      <div className={'mrg-right-5 mrg-top-5'}>
                        {foundShiftType.icon}
                      </div>
                      <div className={foundShiftType.color}>
                        {item?.shift_type}
                      </div>
                    </>
                  );
                } else {
                  return null;
                }
              })()}
            </div>
          );
        },
      },
      {
        title: 'Wing',
        key: 'wing',
        dataIndex: 'wing',
        width: 200,
        align: 'left',
        render: (item: any) => {
          return (
            <div className={'hcp-details-wrapper'}>
              <div className={'hcp-name-tower-wrapper'}>
                {item?.facility_wing_details?.name?.length > 20 ? (
                  <ToolTipComponent
                    tooltip={item?.facility_wing_details?.name}
                    showArrow={true}
                    position={'top'}
                  >
                    <div className={'ellipses-for-table-data property-name'}>
                      {item?.facility_wing_details?.name}
                    </div>
                  </ToolTipComponent>
                ) : (
                  <div className='hcp-name'>
                    {item?.facility_wing_details?.name || '-'}
                  </div>
                )}
              </div>
            </div>
          );
        },
      },
      {
        title: 'Timesheet',
        key: 'timesheet',
        dataIndex: 'timesheet',
        width: 137,
        align: 'left',
        render: (item: any) => {
          return (
            <>
              {item?.is_timesheet_uploaded && (
                <div className={'uploaded'}>Uploaded</div>
              )}
              {!item?.is_timesheet_uploaded && (
                <>
                  <div className={'pending'}>Pending</div>
                </>
              )}
            </>
          );
        },
      },
      // {
      //     title: "View Details",
      //     dataIndex: "actions",
      //     align: 'center',
      //     key: "actions",
      //     width: 100,
      //     fixed: 'right',
      //     render: (item: any) => {
      //         if (item?._id) {
      //             return <div>
      //                 <LinkComponent onClick={() => {
      //                     setOpenDrawer(true);
      //                     setShiftDetails(item);
      //                 }}>
      //                     <ImageConfig.ArrowRightOutlinedIcon/>
      //                 </LinkComponent>
      //             </div>;
      //         }
      //     }
      // }
    ];
  }, [
    activeTab,
    handleSelectShifts,
    isSelected,
    setOpenDrawer,
    setShiftDetails,
  ]);
};

export const usePendingShiftListColumn = (
  setOpenDrawer: any,
  setShiftDetails: any,
): ITableColumn[] => {
  return useMemo(() => {
    return [
      {
        title: 'Title',
        key: 'title',
        dataIndex: 'title',
        fixed: 'left',
        // sortable: true,
        width: 320,
        render: (item: any) => {
          return (
            <div className={'hcp-details-wrapper'}>
              <div className={'hcp-name-tower-wrapper'}>
                {/*{*/}
                {/*    (CommonService.convertDateFormat2(item?.shift_date, "MM-DD-YYYY") + " | " + (item?.hcp_type_details?.title || "") + " | " + item?.shift_type)?.length > 20 ?*/}
                {/*        <ToolTipComponent*/}
                {/*            tooltip={CommonService.convertDateFormat2(item?.shift_date, "MM-DD-YYYY") + " | " + (item?.hcp_type_details?.title || "") + " | " + item?.shift_type}*/}
                {/*            showArrow={true}*/}
                {/*            position={"top"}*/}
                {/*        >*/}
                {/*            <div className={"ellipses-for-table-data property-name"}>*/}
                {/*                {CommonService.convertDateFormat2(item?.shift_date, "MM-DD-YYYY") + " | " + (item?.hcp_type_details?.title || "") + " | " + item?.shift_type}*/}
                {/*            </div>*/}
                {/*        </ToolTipComponent> :*/}
                {/*        <div className="hcp-name">*/}
                {/*            {CommonService.convertDateFormat2(item?.shift_date, "MM-DD-YYYY") + " | " + (item?.hcp_type_details?.title || "") + " | " + item?.shift_type}*/}
                {/*        </div>*/}
                {/*}*/}
                <div className='hcp-name shift-title-text'>
                  <div>
                    <LinkComponent
                      onClick={() => {
                        setOpenDrawer(true);
                        setShiftDetails(item);
                      }}
                    >
                      <div className={'d-flex'}>
                        <div className={'mrg-right-10'}>
                          {CommonService.convertDateFormat2(
                            item?.shift_date,
                            'MM-DD-YYYY',
                          ) +
                            ' | ' +
                            (item?.hcp_type_details?.title || '') +
                            ' | ' +
                            item?.shift_type}
                        </div>
                        <ImageConfig.TitleViewIcon />
                      </div>
                    </LinkComponent>
                  </div>
                </div>
              </div>
            </div>
          );
        },
      },
      {
        title: 'Agency Name',
        key: 'agency_name',
        dataIndex: 'name',
        width: 250,
        render: (item: any) => {
          return (
            <div className={'hcp-details-wrapper'}>
              {/*<AvatarComponent url={item?.profilePic?.url} size={'xs'}/>*/}
              <div className={'hcp-name-tower-wrapper'}>
                {item?.agency_details?.name?.length > 20 ? (
                  <ToolTipComponent
                    tooltip={item?.agency_details?.name}
                    showArrow={true}
                    position={'top'}
                  >
                    <div className={'ellipses-for-table-data property-name'}>
                      {item?.agency_details?.name}
                    </div>
                  </ToolTipComponent>
                ) : (
                  <div className='hcp-name'>
                    {item?.agency_details?.name || '-'}
                  </div>
                )}
              </div>
            </div>
          );
        },
      },
      {
        title: 'Staff  Name',
        key: 'hcp_name',
        dataIndex: 'hcp_name',
        width: 200,
        align: 'left',

        render: (item: any) => {
          return (
            <div className={'hcp-details-wrapper'}>
              <div className={'hcp-name-tower-wrapper'}>
                {(item?.hcp_details?.first_name + item?.hcp_details?.last_name)
                  ?.length > 20 ? (
                  <ToolTipComponent
                    tooltip={
                      item?.hcp_details?.first_name +
                      ' ' +
                      item?.hcp_details?.last_name
                    }
                    showArrow={true}
                    position={'top'}
                  >
                    <div className={'ellipses-for-table-data property-name'}>
                      {item?.hcp_details?.first_name +
                        ' ' +
                        item?.hcp_details?.last_name}
                    </div>
                  </ToolTipComponent>
                ) : (
                  <div className='hcp-name'>
                    {item?.hcp_details?.first_name +
                      ' ' +
                      item?.hcp_details?.last_name || '-'}
                  </div>
                )}
              </div>
            </div>
          );
        },
      },
      {
        title: 'Shift Date',
        key: 'shift_date',
        dataIndex: 'shift_date',
        //fixed: 'left',
        // sortable: true,
        width: 200,
        render: (item: any) => {
          if (item?.requirement_details?.shift_date) {
            return (
              <>
                {CommonService.convertDateFormat2(
                  item?.requirement_details?.shift_date,
                  'MM-DD-YYYY',
                )}
              </>
            );
          } else {
            return <>N/A</>;
          }
        },
      },
      {
        title: 'Shift Time',
        key: 'shift-time',
        dataIndex: 'shift-time',
        width: 200,
        render: (item: any) => {
          if (item?.requirement_details?.shift_timings) {
            return (
              <>
                {CommonService.convertMinutesToTime(
                  item?.requirement_details?.shift_timings?.start_time,
                ) +
                  '-' +
                  CommonService.convertMinutesToTime(
                    item?.requirement_details?.shift_timings?.end_time,
                  )}
              </>
            );
          } else {
            return (
              <>
                {CommonService.convertMinutesToTime(
                  item?.expected?.shift_start_time,
                ) +
                  '-' +
                  CommonService.convertMinutesToTime(
                    item?.expected?.shift_end_time,
                  )}
              </>
            );
          }
        },
      },
      {
        title: 'Staff Type',
        key: 'hcp-type',
        dataIndex: 'hcp_type',
        width: 180,
        render: (item: any) => {
          return (
            <>
              {item?.hcp_type_details?.title
                ? item?.hcp_type_details?.title
                : '_'}
            </>
          );
        },
      },
      {
        title: 'Shift Type',
        key: 'shift-type',
        dataIndex: 'shift-type',
        render: (item: any) => {
          return (
            <div className={'d-flex justify-content-center align-items-center'}>
              {(() => {
                const foundShiftType = shiftTypeLayoutRepresentation.find(
                  (shiftTypeObj) => shiftTypeObj.code === item?.shift_type,
                );
                if (foundShiftType) {
                  return (
                    <>
                      <div className={'mrg-right-5 mrg-top-5'}>
                        {foundShiftType.icon}
                      </div>
                      <div className={foundShiftType.color}>
                        {item?.shift_type}
                      </div>
                    </>
                  );
                } else {
                  return null;
                }
              })()}
            </div>
          );
        },
      },
      {
        title: 'Wing',
        key: 'wing',
        dataIndex: 'wing',
        width: 200,
        align: 'left',
        render: (item: any) => {
          return (
            <div className={'hcp-details-wrapper'}>
              <div className={'hcp-name-tower-wrapper'}>
                {item?.wing_details?.name?.length > 20 ? (
                  <ToolTipComponent
                    tooltip={item?.wing_details?.name}
                    showArrow={true}
                    position={'top'}
                  >
                    <div className={'ellipses-for-table-data property-name'}>
                      {item?.wing_details?.name}
                    </div>
                  </ToolTipComponent>
                ) : (
                  <div className='hcp-name'>
                    {item?.wing_details?.name || '-'}
                  </div>
                )}
              </div>
            </div>
          );
        },
      },
      // {
      //     title: "View Details",
      //     dataIndex: "actions",
      //     align: 'center',
      //     key: "actions",
      //     width: 100,
      //     fixed: 'right',
      //     render: (item: any) => {
      //         if (item?._id) {
      //             return <div>
      //                 <LinkComponent onClick={() => {
      //                     setOpenDrawer(true);
      //                     setShiftDetails(item);
      //                 }}>
      //                     <ImageConfig.ArrowRightOutlinedIcon color={"#4E535F"}/>
      //                 </LinkComponent>
      //             </div>;
      //         }
      //     }
      // }
    ];
  }, [setOpenDrawer, setShiftDetails]);
};
