import './ShiftRequirementList.scss';
import React, {useCallback, useEffect, useState} from 'react';
import {CommonService} from '../../../shared/services';
import {APIConfig, ImageConfig, Misc} from '../../../constants';
import TableWrapperComponent from '../../../shared/components/table-wrapper/TableWrapperComponent';
import TabsWrapperComponent, {
    TabComponent,
    TabContentComponent,
    TabsComponent,
} from '../../../shared/components/tabs/TabsComponent';
import ButtonComponent from '../../../shared/components/button/ButtonComponent';
// import LinkComponent from "../../../shared/components/link/LinkComponent";
import DrawerComponent from '../../../shared/components/drawer/DrawerComponent';
import ApprovedShiftDetailsComponent from '../shift-details/approved-shift-details/ApprovedShiftDetailsComponent';
import CancelledShiftDetailsComponent from '../shift-details/cancelled-shift-details/CancelledShiftDetailsComponent';
import ConfirmedShiftDetailsComponent from '../shift-details/confirmed-shift-details/ConfirmedShiftDetailsComponent';
import CompletedShiftDetailsComponent from '../shift-details/completed-shift-details/CompletedShiftDetailsComponent';
import {IAPIResponseType} from '../../../shared/models/api.model';
import DialogComponent from '../../../shared/components/dialog-component/DialogComponent';
import HorizontalLineComponent
    from '../../../shared/components/horizontal-line/horizontal-line/HorizontalLineComponent';
import ShiftFilterComponent from '../filter-popup/ShiftFilterComponent';
import AddShiftDetailsComponent from '../add-new-shift/add-shift-details/AddShiftRequirementDetails';
import PendingShiftDetailsComponent from '../shift-details/pending-shift-details/PendingShiftDetails';
import {useDispatch, useSelector} from 'react-redux';
import {getAgencyListLite,getWingsListLite} from "../../../store/actions/static-data.action";
import {
    approvedTabButtonOptions,
    completedTabButtonOptions,
    confirmedTabButtonOptions,
    pendingTabButtonOptions, shiftHomeType,
} from '../../../data';
import ReusableDropdown from '../../../shared/components/action-select-component/ActionSelectComponent';
import {IRootReducerState} from '../../../store/reducers';
import RejectCancelledCommonComponent
    from '../../../shared/components/reject-cancellation/RejectCancelledCommonComponent';
import {
    useOpenShiftsListColumn,
    useOtherShiftsListColumn,
    usePendingShiftListColumn,
} from './TableColumnns';
import SearchComponent from '../../../shared/components/search/SearchComponent';
import SelectDropdownComponent from "../../../shared/components/form-controls/select-dropdown/SelectDropdownComponent";
import ShiftCreationPopupComponent from "../shift-creation-popup/ShiftCreationPopupComponent";
import AddPreApprovedShiftDetailsComponent
    from "../add-pre-approved-shift/add-pre-approved-shit-details/AddPreApprovedShiftDetailsComponent";


export interface ShiftRequirementListProps {
}

const ShiftRequirementList: React.FC<ShiftRequirementListProps> = () => {
    const dispatch=useDispatch();
    const [filterData, setFilterData] = useState<any>({
        search: '',
        status: 'open',
        open_status_type: 'open',
        requirementType: 'Open Shift'
    });
    const [activeTab, setActiveTab] = useState<
        'open' | 'pending' | 'approved' | 'completed' | 'confirmed' | 'cancelled'
    >('open');
    const [selectedShifts, setSelectedShifts] = useState<any>([]);
    const [openDrawer, setOpenDrawer] = useState<boolean>(false);
    const [shiftDetails, setShiftDetails] = useState<any>(null);
    const [openDialog, setOpenDialog] = useState<boolean>(false);
    const [shiftCancellationReason, setShiftCancellationReason] =
        useState<any>(null);
    const [isDownloading, setIsDownloading] = useState<boolean>(false);
    const [openDialogForShiftCreation, setOpenDialogForShiftCreation] =
        useState<boolean>(false);
    const [shiftTypeHeader, setShiftTypeHeader] = useState('Create Shift');
    const [tabActionSelection, setTabActionSelection] = useState<any>(
        pendingTabButtonOptions,
    );
    const [loadingOption, setLoadingOption] = useState<string | number | null>(
        null,
    );
    const [preApprovedShiftHeader,setPreApprovedShiftHeader]=useState<string>('Add Pre Approved Shift')
    const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false);
    const [openDialogForPreApprovedShiftCreation,setOpenDialogForPreApprovedShiftCreation]=useState<boolean>(false)
    const [
        shiftCancellationRejectionLoading,
        setIsShiftCancellationRejectionLoading,
    ] = useState<any>(false);
    const [open, setOpen] = useState(false);
    const {currentUser} = useSelector(
        (state: IRootReducerState) => state.account,
    );

    useEffect(() => {
        if (activeTab === 'pending') {
            setTabActionSelection(pendingTabButtonOptions);
        } else if (activeTab === 'approved') {
            setTabActionSelection(approvedTabButtonOptions);
        } else if (activeTab === 'completed') {
            setTabActionSelection(completedTabButtonOptions);
        } else if (activeTab === 'confirmed') {
            setTabActionSelection(confirmedTabButtonOptions);
        }
    }, [activeTab]);

    const handleSelectShifts = useCallback(
        (event: any, _id: any) => {
            if (event.target.checked === true) {
                setSelectedShifts([...selectedShifts, _id]);
            } else {
                let tempSelectedShifts = selectedShifts?.filter(
                    (item: any) => item !== _id,
                );
                setSelectedShifts([...tempSelectedShifts]);
            }
        },
        [selectedShifts],
    );

    const isSelected = useCallback(
        (_id: any) => {
            if (selectedShifts?.indexOf(_id) !== -1) {
                return true;
            } else {
                return false;
            }
        },
        [selectedShifts],
    );

    const onMarkingShiftCompleted = useCallback(() => {
        const payload: any = {};
        let apiCall: any = {};
        payload.shift_ids = selectedShifts;
        apiCall =
            CommonService._shift_management.markApprovedShiftToCompletedAPICall(
                payload,
            );
        apiCall
            .then((response: IAPIResponseType<any>) => {
                CommonService._alert.showToast(
                    response[Misc.API_RESPONSE_MESSAGE_KEY],
                    'success',
                );
                setActiveTab('completed');
                setFilterData({...filterData, status: 'completed'});
                setOpenDrawer(false);
            })
            .catch((error: any) => {
            })
            .finally(() => {
            });
    }, [filterData, selectedShifts, setFilterData]);

    const handleConfirmingShiftMarkingAsCompleted = useCallback(() => {
        setOpen(false);
        if (selectedShifts.length === 0) {
            CommonService._alert.showToast(
                'Please select atleast one shift to mark as completed',
                'info',
            );
            setLoadingOption(null);
        } else {
            CommonService.onConfirm({
                confirmationTitle: `Mark As Completed`,
                image: ImageConfig.MarkAsCompleted,
                confirmationDescription:
                    'Are you sure you want to mark this shift as completed ?',
                no: {
                    color: 'default',
                    text: 'No, Go Back',
                    variant: 'outlined',
                },
                yes: {
                    color: 'error',
                    text: 'Yes, Mark as Completed',
                    variant: 'contained',
                },
            })
                .then((result: any) => {
                    onMarkingShiftCompleted();
                })
                .catch(() => {
                    setLoadingOption(null);
                });
        }
    }, [onMarkingShiftCompleted, selectedShifts]);

    const handleConfirmingShiftMarkingAsConfirmed = useCallback(() => {
        setOpen(false);
        if (selectedShifts.length === 0) {
            CommonService._alert.showToast(
                'Please select atleast one shift to mark as confirmed',
                'info',
            );
            setLoadingOption(null);
        } else {
            CommonService.onConfirm({
                confirmationTitle: `Mark As Confirmed`,
                image: ImageConfig.MarkAsCompleted,
                confirmationDescription:
                    'Are you sure you want to mark this shift as Confirmed ?',
                no: {
                    color: 'default',
                    text: 'No, Go Back',
                    variant: 'outlined',
                },
                yes: {
                    color: 'error',
                    text: 'Yes, Mark as Confirmed',
                    variant: 'contained',
                },
            })
                .then((result: any) => {
                    const payload: any = {};
                    let apiCall: any = {};
                    payload.shift_ids = selectedShifts;
                    apiCall =
                        CommonService._shift_management.markCompletedShiftToConfirmedAPICall(
                            payload,
                        );
                    apiCall
                        .then((response: IAPIResponseType<any>) => {
                            CommonService._alert.showToast(
                                response[Misc.API_RESPONSE_MESSAGE_KEY],
                                'success',
                            );
                            CommonService._localStorage.setItem(
                                'shift-requirement-selected-tab',
                                'completed',
                            );
                            setActiveTab('confirmed');
                            setFilterData({...filterData, status: 'confirmed'});
                            setOpenDrawer(false);
                        })
                        .catch((error: any) => {
                            // Handle error here if needed
                        })
                        .finally(() => {
                            // Any final actions here if needed
                        });
                })
                .catch(() => {
                    setLoadingOption(null);
                });
        }
    }, [filterData, selectedShifts, setActiveTab, setFilterData, setOpenDrawer]);

    const openShiftsListColumn = useOpenShiftsListColumn(filterData);
    const otherShiftsListColumn = useOtherShiftsListColumn(
        activeTab,
        handleSelectShifts,
        isSelected,
        setOpenDrawer,
        setShiftDetails,
    );
    const pendingShiftListColumn = usePendingShiftListColumn(
        setOpenDrawer,
        setShiftDetails,
    );

    const cancelShifts = useCallback(() => {
        setIsShiftCancellationRejectionLoading(true);
        const payload: any = {};
        let apiCall: any = {};
        payload.shift_ids = selectedShifts;
        payload.reason = shiftCancellationReason;
        apiCall =
            CommonService._shift_management.cancelApprovedShiftToCancelledAPICall(
                payload,
            );
        apiCall
            .then((response: IAPIResponseType<any>) => {
                CommonService._alert.showToast(
                    response[Misc.API_RESPONSE_MESSAGE_KEY],
                    'success',
                );
                setIsShiftCancellationRejectionLoading(false);
                CommonService._staticData.openDialogPopup(setOpenDialog, false);
                setActiveTab('cancelled');
                setFilterData({...filterData, status: 'cancelled'});
                setOpenDrawer(false);
            })
            .catch((error: any) => {
                setIsShiftCancellationRejectionLoading(false);
            })
            .finally(() => {
            });
    }, [filterData, selectedShifts, shiftCancellationReason, setFilterData]);

    const handleDownloadShifts = useCallback(() => {
        setIsDownloading(true);
        const payload: any = {};
        const {status} = filterData;
        payload.status = [status];
        let apiCall: any = {};
        if (filterData?.status !== 'open') {
            apiCall =
                CommonService._shift_management.downloadShiftDataCSVAPICall(filterData);
        } else {
            apiCall =
                CommonService._shift_management.downloadOpenShiftDataCSVAPICall(
                    filterData,
                );
        }
        apiCall
            .then((response: IAPIResponseType<any>) => {
                if (response?.data) {
                    CommonService._alert.showToast(
                        response[Misc.API_RESPONSE_MESSAGE_KEY],
                        'success',
                    );
                    const link = document.createElement('a');
                    link?.setAttribute('href', response?.data);
                    document.body.appendChild(link);
                    link.click();
                    setIsDownloading(false);
                    setLoadingOption(null);
                } else {
                    CommonService._alert.showToast(
                        response[Misc.API_RESPONSE_MESSAGE_KEY],
                        'error',
                    );
                    setIsDownloading(false);
                }
            })
            .catch((error: any) => {
                CommonService._alert.showToast(
                    error[Misc.API_ERROR_MESSAGE_KEY],
                    'error',
                );
                setIsDownloading(false);
            })
            .finally(() => {
            });
    }, [filterData]);

    const handleOptionChange = useCallback(
        (value: string | number) => {
            setLoadingOption(value);
            if (value === 'approve-shifts') {
                //    handleMarkingApplicationAsApprove();
            } else if (value === 'reject-shifts') {
                setOpen(false);
                // if (selectedApplication.length === 0) {
                //     CommonService._alert.showToast("Please select atleast one shift to reject", "info");
                //     setLoadingOption(null);
                // } else {
                //  //   setCancellationType("reject-application");
                //     CommonService._staticData.openDialogPopup(setOpenDialog, true);
                // }
            } else if (value === 'download-csv') {
                handleDownloadShifts();
                setOpen(false);
            } else if (value === 'mark-as-cancel') {
                setOpen(false);
                if (selectedShifts.length === 0) {
                    setLoadingOption(null);
                    CommonService._alert.showToast(
                        'Please select atleast one shift to cancel',
                        'info',
                    );
                } else {
                    //  setCancellationType("cancel-shift");
                    CommonService._staticData.openDialogPopup(setOpenDialog, true);
                }
            } else if (value === 'mark-as-completed') {
                handleConfirmingShiftMarkingAsCompleted();
            } else if (value === 'mark-as-confirmed') {
                handleConfirmingShiftMarkingAsConfirmed();
            }
        },
        [
            handleDownloadShifts,
            selectedShifts.length,
            handleConfirmingShiftMarkingAsCompleted,
            handleConfirmingShiftMarkingAsConfirmed,
        ],
    );

    const handleShiftCreationPopup=useCallback((value:string)=>{
        setIsPopupOpen(false)
        setPreApprovedShiftHeader("Create Pre Approved Shift Requirement");
        dispatch(getWingsListLite());
        dispatch(
               getAgencyListLite({
                   facility_id: currentUser?.facility_id,
           }));
        if(value==='add-shift-requirement'){
            CommonService._staticData.openDialogPopup(setOpenDialogForShiftCreation,true)
        }else {
            CommonService._staticData.openDialogPopup(setOpenDialogForPreApprovedShiftCreation,true)
        }
    },[dispatch,currentUser])

    return (
        <>
            <DialogComponent
                cancel={() => {
                    CommonService._staticData.openDialogPopup(
                        setOpenDialogForShiftCreation,
                        false,
                    );
                    setFilterData({...filterData});
                }}
                open={openDialogForShiftCreation}
                maxWidth={'xl'}
            >
                <div>
                    <div className={'calender-open-details-header'}>
                        <div className={'form-header-text'}>{shiftTypeHeader}</div>
                        <div
                            className={'icon-button'}
                            onClick={() => {
                                CommonService._staticData.openDialogPopup(
                                    setOpenDialogForShiftCreation,
                                    false,
                                );
                                setFilterData({...filterData});
                            }}
                        >
                            <ImageConfig.CrossIcon/>
                        </div>
                    </div>
                    <HorizontalLineComponent/>
                    <AddShiftDetailsComponent setShiftTypeHeader={setShiftTypeHeader}/>
                </div>
            </DialogComponent>
            <DialogComponent
                open={openDialogForPreApprovedShiftCreation}
                maxWidth={'xl'}
                cancel={() => {
                    CommonService._staticData.openDialogPopup(
                        setOpenDialogForPreApprovedShiftCreation,
                        false
                    )
                    setFilterData({...filterData});
                }}
            >
                <div>
                    <div className={'calender-open-details-header'}>
                        <div className={'form-header-text'}>{preApprovedShiftHeader}</div>
                        <div
                            className={'icon-button'}
                            onClick={() => {
                                CommonService._staticData.openDialogPopup(
                                    setOpenDialogForPreApprovedShiftCreation,
                                    false,
                                );
                                setFilterData({...filterData});
                            }}
                        >
                            <ImageConfig.CrossIcon/>
                        </div>
                    </div>
                    <HorizontalLineComponent/>
                    <AddPreApprovedShiftDetailsComponent setPreApprovedShiftHeader={setPreApprovedShiftHeader}/>
                </div>
            </DialogComponent>
            <DrawerComponent
                onClose={() => {
                    CommonService._staticData.openDialogPopup(setOpenDrawer, false);
                }}
                isOpen={openDrawer}
            >
                {activeTab === 'approved' && (
                    <ApprovedShiftDetailsComponent
                        filterData={filterData}
                        setFilterData={setFilterData}
                        shiftDetails={shiftDetails}
                        setOpenDrawer={setOpenDrawer}
                    />
                )}
                {activeTab === 'pending' && (
                    <PendingShiftDetailsComponent
                        filterData={filterData}
                        setFilterData={setFilterData}
                        shiftDetails={shiftDetails}
                        setOpenDrawer={setOpenDrawer}
                    />
                )}
                {activeTab === 'completed' && (
                    <CompletedShiftDetailsComponent
                        filterData={filterData}
                        setFilterData={setFilterData}
                        shiftDetails={shiftDetails}
                        setOpenDrawer={setOpenDrawer}
                    />
                )}
                {activeTab === 'confirmed' && (
                    <ConfirmedShiftDetailsComponent
                        filterData={filterData}
                        setFilterData={setFilterData}
                        shiftDetails={shiftDetails}
                        setOpenDrawer={setOpenDrawer}
                    />
                )}
                {activeTab === 'cancelled' && (
                    <CancelledShiftDetailsComponent
                        filterData={filterData}
                        setFilterData={setFilterData}
                        shiftDetails={shiftDetails}
                        setOpenDrawer={setOpenDrawer}
                    />
                )}
            </DrawerComponent>
            <DialogComponent
                cancel={() => {
                    CommonService._staticData.openDialogPopup(setOpenDialog, false);
                }}
                open={openDialog}
            >
                <RejectCancelledCommonComponent
                    setOpenDialog={setOpenDialog}
                    cancellationType={'Cancel Shift'}
                    setShiftCancellationReason={setShiftCancellationReason}
                    onSubmit={cancelShifts}
                    shiftCancellationReason={shiftCancellationReason}
                    setLoadingOption={setLoadingOption}
                    isLoading={shiftCancellationRejectionLoading}
                />
            </DialogComponent>
            <div className={'shift-requirement-screen'}>
                <div
                    className={
                        'facility-list-component header-screen  mrg-left-25 mrg-right-30 mrg-top-20 mrg-bottom-10'
                    }
                >
                    <div className='list-screen-header-wrapper'>
                        <div className='list-screen-header'>
                            {/*<div className={'mrg-bottom-5 home-text'}>Home</div>*/}
                            <div className='list-screen-header-title-and-count d-flex'>
                                <div className={'mrg-right-10'}>
                                    <ImageConfig.ShiftManagementModuleHeaderIcon/>
                                </div>
                                <div className='list-screen-header-title'>Shift Management</div>
                            </div>
                        </div>
                        <div className='list-options'>
                            {(activeTab === 'approved' ||
                                activeTab === 'completed' ||
                                activeTab === 'confirmed') && (
                                <div>
                                    <ReusableDropdown
                                        options={tabActionSelection}
                                        loadingOption={loadingOption}
                                        onChange={handleOptionChange}
                                        open={open}
                                        setOpen={setOpen}
                                    />
                                </div>
                            )}

                            <SelectDropdownComponent className={"select-box-width mrg-top-15"}
                                                     options={shiftHomeType}
                                                     placeholder={"select"}
                                                     isClearable={false}
                                                     label={''}
                                                     value={filterData.requirementType}
                                                     valueExtractor={item => item.code}
                                                     displayWith={item => item.title}
                                                     onUpdate={
                                                         (value) => {
                                                             setFilterData({
                                                                 ...filterData,
                                                                 requirementType: value
                                                             });
                                                             setSelectedShifts([])
                                                             // setSelectedApplication([]);
                                                             // setSelectedApplicationForApproval([]);
                                                         }
                                                     }
                                                     fullWidth={true}
                            />
                            {filterData?.status === 'open' && (
                                <ButtonComponent
                                    color={'download-button'}
                                    variant={'contained'}
                                    isLoading={isDownloading}
                                    onClick={handleDownloadShifts}
                                >
                                    Download CSV
                                </ButtonComponent>
                            )}
                            <ShiftFilterComponent
                                setFilterData={setFilterData}
                                filterData={filterData}
                            />
                            {/*<LinkComponent*/}
                            {/*    onClick={() => {*/}
                            {/*        CommonService._staticData.openDialogPopup(*/}
                            {/*            setOpenDialogForShiftCreation,*/}
                            {/*            true,*/}
                            {/*        );*/}
                            {/*        dispatch(getWingsListLite());*/}
                            {/*        dispatch(*/}
                            {/*            getAgencyListLite({*/}
                            {/*                facility_id: currentUser?.facility_id,*/}
                            {/*            }),*/}
                            {/*        );*/}
                            {/*    }}*/}
                            {/*>*/}
                            {/*    <ButtonComponent*/}
                            {/*        color={'primary'}*/}
                            {/*        prefixIcon={<ImageConfig.AddOutlinedIcon/>}*/}
                            {/*    >*/}
                            {/*        Create Shift*/}
                            {/*    </ButtonComponent>*/}
                            {/*</LinkComponent>*/}
                            <ShiftCreationPopupComponent
                                isPopupOpen={isPopupOpen}
                                setIsPopupOpen={setIsPopupOpen}
                                handleShiftCreationPopup={handleShiftCreationPopup}
                            />
                        </div>
                    </div>
                </div>
                <TabsWrapperComponent>
                    <div className={'d-flex d-flex-space-between mrg-right-30'}>
                        <div>
                            <TabsComponent
                                value={activeTab}
                                allowScrollButtonsMobile={false}
                                onUpdate={(e: any, v: any) => {
                                    setActiveTab(v);
                                    setSelectedShifts([]);
                                    setFilterData({...filterData, status: v, page: 1});
                                }}
                            >
                                <TabComponent label='Open' value={'open'}/>
                                <TabComponent label='Applied' value={'pending'}/>
                                <TabComponent label='Approved' value={'approved'}/>
                                <TabComponent label='Completed' value={'completed'}/>
                                <TabComponent label='Confirmed' value={'confirmed'}/>
                                <TabComponent label='Cancelled' value={'cancelled'}/>
                            </TabsComponent>
                        </div>
                        <SearchComponent
                            size={'medium'}
                            placeholder='Search Shift Title'
                            value={filterData.search}
                            onSearchChange={(value) => {
                                setFilterData({...filterData, search: value, page: 1});
                            }}
                        />
                    </div>
                    <TabContentComponent
                        value={activeTab}
                        selectedTab={filterData?.status}
                    >
                        <div
                            className={
                                'facility-list-component list-screen mrg-top-10 mrg-left-25 mrg-right-30'
                            }
                        >
                            <div className='list-screen-content'>
                                {filterData?.status === 'open' && (
                                    <TableWrapperComponent
                                        url={APIConfig.SHIFT_MANAGEMENT_LIST.URL}
                                        method={APIConfig.SHIFT_MANAGEMENT_LIST.METHOD}
                                        isPaginated={true}
                                        extraPayload={filterData}
                                        noDataText={
                                            filterData.search
                                                ? 'Oops! It seems like there are no shifts available for the name you have searched.'
                                                : `No ${filterData.status} Shifts Found.`
                                        }
                                        columns={openShiftsListColumn}
                                    />
                                )}
                                {filterData?.status !== 'open' &&
                                    filterData?.status !== 'pending' && (
                                        <>
                                            <TableWrapperComponent
                                                url={APIConfig.SHIFT_MANAGEMENT_LIST_AFTER_APPROVAL.URL}
                                                method={
                                                    APIConfig.SHIFT_MANAGEMENT_LIST_AFTER_APPROVAL.METHOD
                                                }
                                                selectedQuantity={selectedShifts?.length}
                                                isPaginated={true}
                                                extraPayload={filterData}
                                                noDataText={
                                                    filterData.search
                                                        ? 'Oops! It seems like there are no shifts available for the name you have searched.'
                                                        : `No ${filterData.status} Shifts Found.`
                                                }
                                                columns={otherShiftsListColumn}
                                            />
                                        </>
                                    )}
                                {filterData?.status === 'pending' && (
                                    <>
                                        <TableWrapperComponent
                                            url={APIConfig.SHIFT_MANAGEMENT_PENDING_LIST.URL}
                                            method={APIConfig.SHIFT_MANAGEMENT_PENDING_LIST.METHOD}
                                            isPaginated={true}
                                            extraPayload={filterData}
                                            noDataText={
                                                filterData.search
                                                    ? 'Oops! It seems like there are no shifts available for the name you have searched.'
                                                    : `No ${filterData.status} Shifts Found.`
                                            }
                                            columns={pendingShiftListColumn}
                                        />
                                    </>
                                )}
                            </div>
                        </div>
                    </TabContentComponent>
                </TabsWrapperComponent>
            </div>
        </>
    );
};

export default ShiftRequirementList;
