import React, {useCallback, useEffect, useState} from "react";
import "./PendingShiftDetails.scss";
import {APIConfig, ImageConfig, Misc} from "../../../../constants";
import HorizontalLineComponent
    from "../../../../shared/components/horizontal-line/horizontal-line/HorizontalLineComponent";
import {CommonService} from "../../../../shared/services";
import {IAPIResponseType} from "../../../../shared/models/api.model";
import CardComponent from "../../../../shared/components/card/CardComponent";
import DialogComponent from "../../../../shared/components/dialog-component/DialogComponent";
import ButtonComponent from "../../../../shared/components/button/ButtonComponent";
import CommonHcpDetailsCard from "../hcp-details-common-card/CommonHcpDetailsCard";
import TabsWrapperComponent, {
    TabComponent,
    TabContentComponent,
    TabsComponent
} from "../../../../shared/components/tabs/TabsComponent";
import LoaderComponent from "../../../../shared/components/loader/LoaderComponent";
import TableWrapperComponent from "../../../../shared/components/table-wrapper/TableWrapperComponent";
import {Tooltip} from "@mui/material";
import RejectCancelledCommonComponent
    from "../../../../shared/components/reject-cancellation/RejectCancelledCommonComponent";
import Lottie from "react-lottie";
import FavoriteJson from "../../../../assets/animations/Favorite.json";
import {useUserListColumn} from "../../../../shared/common-table/CommonTableColumns";
import ApproveApplicationComponent from "../../application-approve-component/ApproveApplicationComponent";
import ContractTravelApproveApplicationComponent
    from "../../contract-travel-approve-component/ContarctTravelApproveComponent";

interface ApprovedShiftDetailsComponentProps {
    setOpenDrawer?: any;
    shiftDetails?: any;
    setFilterData?: any;
    filterData?: any;
    isOnCalender?: any;
    dayMonthView?: any;
}

const PendingShiftDetailsComponent = (props: ApprovedShiftDetailsComponentProps) => {
    const {setOpenDrawer, shiftDetails, dayMonthView, filterData, setFilterData, isOnCalender} = props;
    const shiftTimingsColumn = useUserListColumn();
    const [hcpFacilityShiftFilterData, setHcpFacilityShiftFilterData] = useState({});
    const [hcpDetails, setHcpDetails] = useState<any>(null);
    const [isHcpDetailsLoaded, setIsHcpDetailsLoaded] = useState<boolean>(false);
    const [isHcpDetailsLoading, setIsHcpDetailsLoading] = useState<boolean>(false);
    const [hcpDocumentsList, setHcpDocumentsList] = useState<any>(null);
    const [shiftRequirementDetails, setShiftRequirementDetails] = useState<any>(null);
    const [isShiftDetailsLoaded, setIsShiftDetailsLoaded] = useState<any>(false);
    const [shiftCancellationReason, setShiftCancellationReason] = useState<any>(null);
    const [isRejectButtonLoading, setIsRejectButtonLoading] = useState<boolean>(false);
    const [openDialog, setOpenDialog] = useState<boolean>(false);
    const [isApprovedShiftLoading, setIsApprovedShiftLoading] = useState(false);
    const [activeTab, setActiveTab] = useState<any>("shift-requirement");
    const [isApproveBoxOpen, setIsApproveBoxOpen] = useState<boolean>(false);
    const [isContractApprovalBoxOpen, setIsContractApprovalBoxOpen] = useState<boolean>(false);


    const getShiftRequirementDetails = useCallback(() => {
        const payload = {};
        CommonService._shift_management.getShiftDetailsAPICall(shiftDetails?.requirement_id, payload)
            .then((response: IAPIResponseType<any>) => {
                console.log(response.data);
                setShiftRequirementDetails(response.data);
                setIsShiftDetailsLoaded(true);
            }).catch((error: any) => {
            setIsShiftDetailsLoaded(false);
        }).finally(() => {
        });
    }, [shiftDetails]);

    const approveHcpApplication = useCallback((application_id: any) => {
        const payload = {};
        setIsApprovedShiftLoading(true);
        CommonService._shift_management.approveShiftRequirementApplicationAPICall(application_id, payload)
            .then((response: IAPIResponseType<any>) => {
                setIsApprovedShiftLoading(false);
                setOpenDrawer(false);
                if (isOnCalender) {
                    setFilterData({...filterData});
                } else {
                    setFilterData({...filterData, status: "pending"});
                }
                CommonService._alert.showToast(response[Misc.API_RESPONSE_MESSAGE_KEY], "success");
            }).catch((error: any) => {
            CommonService._alert.showToast(error?.message, "error");
            setIsApprovedShiftLoading(false);
        });
    }, [filterData, isOnCalender, setFilterData, setOpenDrawer]);

    const rejectHcpApplication = useCallback(() => {
        setIsRejectButtonLoading(true);
        const payload: any = {};
        let apiCall: any = {};
        payload.rejected_reason = shiftCancellationReason;
        apiCall = CommonService._shift_management.rejectShiftRequirementApplicationAPICall(shiftDetails?._id, payload);
        apiCall.then((response: IAPIResponseType<any>) => {
            CommonService._alert.showToast(response[Misc.API_RESPONSE_MESSAGE_KEY], "success");
            CommonService._staticData.openDialogPopup(setOpenDialog, false);
            setOpenDrawer(false);
            setIsRejectButtonLoading(false);
            if (isOnCalender) {
                setFilterData({...filterData});
            } else {
                setFilterData({...filterData, status: "pending"});
            }
        })
            .catch((error: any) =>{
                CommonService._alert.showToast('Something went wrong!!',"error")
                setIsRejectButtonLoading(false);
            }).finally(() => {

        });
    }, [shiftCancellationReason, shiftDetails?._id, setOpenDrawer, isOnCalender, setFilterData, filterData]);

    const getHcpDetails = useCallback(() => {
        setIsHcpDetailsLoading(true);
        const payload = {};
        if (dayMonthView) {
            CommonService._hcp.HcpDetailsAPICall(shiftDetails?.hcp_id, payload)
                .then((response: IAPIResponseType<any>) => {
                    setHcpDetails(response.data);
                    setIsHcpDetailsLoaded(true);
                }).catch((error: any) => {
                setIsHcpDetailsLoaded(false);
            }).finally(() => {
                setIsHcpDetailsLoading(false);
            });
        } else {
            CommonService._hcp.HcpDetailsAPICall(shiftDetails?.hcp_details?._id, payload)
                .then((response: IAPIResponseType<any>) => {
                    //  console.log(response.data);
                    setHcpDetails(response.data);
                    setIsHcpDetailsLoaded(true);
                    //  setIsHcpDetailsLoadingFailed(false);
                }).catch((error: any) => {
                setIsHcpDetailsLoaded(false);
                // setIsHcpDetailsLoadingFailed(true);
            }).finally(() => {
                setIsHcpDetailsLoading(false);
            });
        }
    }, [dayMonthView, shiftDetails?.hcp_details?._id, shiftDetails?.hcp_id]);

    const getHcpDocuments = useCallback(() => {
        const payload = {};
        if (dayMonthView) {
            CommonService._hcp.HcpDocumentAPICall(shiftDetails?.hcp_id, payload)
                .then((response: IAPIResponseType<any>) => {
                    //  console.log(response.data);
                    setHcpDocumentsList(response?.data);
                }).catch((error: any) => {
            }).finally(() => {
            });
        } else {
            CommonService._hcp.HcpDocumentAPICall(shiftDetails?.hcp_details?._id, payload)
                .then((response: IAPIResponseType<any>) => {
                    //  console.log(response.data);
                    setHcpDocumentsList(response?.data);
                }).catch((error: any) => {
            }).finally(() => {
            });
        }
    }, [dayMonthView, shiftDetails?.hcp_details?._id, shiftDetails?.hcp_id]);

    const handleMarkingApplicationAsApprove = useCallback((application_id: any) => {
        CommonService.onConfirm(
            {
                confirmationTitle: `Mark As Approve`,
                image: ImageConfig.MarkAsCompleted,
                confirmationDescription: "Are you sure you want to mark this application as approve ?",
                no: {
                    color: "default",
                    text: "No, Go Back",
                    variant: "outlined"
                },
                yes: {
                    color: "error",
                    text: "Yes, Mark as Approve",
                    variant: "contained",
                }
            }
        ).then((result: any) => {
            approveHcpApplication(application_id);
        }).catch(() => {
        });

    }, [approveHcpApplication]);

    useEffect((): void => {
        getHcpDetails();
    }, [getHcpDetails]);

    useEffect(() => {
        getHcpDocuments();
    }, [getHcpDocuments]);

    useEffect(() => {
        console.log(shiftDetails);
        if (isHcpDetailsLoaded) {
            setHcpFacilityShiftFilterData({
                hcp_id: hcpDetails?._id,
                facility_id: shiftDetails?.facility_id
            });
        }
    }, [hcpDetails, shiftDetails, isHcpDetailsLoaded]);

    useEffect(() => {
        getShiftRequirementDetails();
    }, [getShiftRequirementDetails]);

    return (
        <>
            <>
                <DialogComponent cancel={() => {
                    CommonService._staticData.openDialogPopup(setOpenDialog, false);
                }} open={openDialog}>
                    <RejectCancelledCommonComponent setOpenDialog={setOpenDialog} cancellationType={"Reject Shift"}
                                                    setShiftCancellationReason={setShiftCancellationReason}
                                                    onSubmit={rejectHcpApplication}
                                                    shiftCancellationReason={shiftCancellationReason}
                                                    isLoading={isRejectButtonLoading}/>

                </DialogComponent>
            </>
            <DialogComponent cancel={() => {
                setIsApproveBoxOpen(false)
            }} open={isApproveBoxOpen} maxWidth={'xl'}>
                <ApproveApplicationComponent
                    setIsApproveBoxOpen={setIsApproveBoxOpen}
                    selectedApplicationForApproval={[shiftDetails]}
                    pendingFilterData={filterData}
                    setPendingFilterData={setFilterData}
                    status={'pending-shift'}
                    setOpenDrawer={setOpenDrawer}
                    isOnCalender={isOnCalender}
                    shiftRequirementDetails={shiftRequirementDetails}
                />
            </DialogComponent>

            <DialogComponent cancel={() => {
                setIsContractApprovalBoxOpen(false)
            }} open={isContractApprovalBoxOpen} maxWidth={'xl'}>
                <ContractTravelApproveApplicationComponent
                    setIsContractApprovalBoxOpen={setIsContractApprovalBoxOpen}
                    selectedApplicationForApproval={[shiftDetails]}
                    pendingFilterData={filterData}
                    setPendingFilterData={setFilterData}
                    status={'pending-shift'}
                    setOpenDrawer={setOpenDrawer}
                    isOnCalender={isOnCalender}
                    shiftRequirementDetails={shiftRequirementDetails}
                />
            </DialogComponent>
            <div className={"pending-shift-view-details"}>
                <div className={"pending-header d-flex justify-content-space-between pdd-20"} style={{
                    backgroundImage: `url(${ImageConfig.ApprovedGradient}`,
                    backgroundRepeat: "no-repeat"
                }}>
                    <div className={'d-flex ts-align-items-center'}>
                        <div className={'form-header-text'}>Shift Details</div>
                        <div className={'mrg-left-15 pending-wrapper'}>Pending</div>
                    </div>
                    <div className={'icon-button'} onClick={() => {
                        setOpenDrawer(false);
                    }}><ImageConfig.CrossIcon/></div>
                </div>
                <HorizontalLineComponent/>

                {isHcpDetailsLoading && <>
                    <div className="h-v-center">
                        <LoaderComponent type={'spinner'}/>
                    </div>
                </>}
                {isHcpDetailsLoaded && <>
                    <div className={"non-header-container"}>
                        <div className={'hcp-details-wrapper'}>
                            <>
                                <div className={'shift-requirement-day-week-details-wrapper'}>
                                    <div className={'d-flex d-flex-space-between ts-align-items-center'}>
                                        <div className={'d-flex align-items-center'}>
                                            <div className={'hcp-details-header-text-drawer'}>Staff Details</div>
                                            {hcpDetails?.is_favourite &&
                                                <Lottie width={50} height={50} speed={1}
                                                        options={{animationData: FavoriteJson}}/>}
                                        </div>
                                        <div className={'d-flex'}>
                                            <div className={'mrg-right-10'}>
                                                <ButtonComponent isLoading={isApprovedShiftLoading} onClick={() => {
                                                    if (shiftRequirementDetails?.requirementType === "Home Health") {
                                                        setIsApproveBoxOpen(true)
                                                    } else if (shiftRequirementDetails?.requirementType === "Contract Travel") {
                                                        setIsContractApprovalBoxOpen(true)
                                                    } else {
                                                        handleMarkingApplicationAsApprove(shiftDetails?._id)
                                                    }
                                                }} style={{
                                                    backgroundColor: "#F5FDFF",
                                                    color: "#0DB9D8",
                                                    boxShadow: "none",
                                                    border: "1px solid #BEEDF6",
                                                    fontWeight: 500,
                                                    fontSize: "12px"
                                                }}>
                                                    Approve
                                                </ButtonComponent>
                                            </div>
                                            <ButtonComponent onClick={() => {
                                                CommonService._staticData.openDialogPopup(setOpenDialog, true);
                                            }} style={{
                                                backgroundColor: "#FFF4F4",
                                                color: "#DB5656",
                                                boxShadow: "none",
                                                border: "1px solid #F6BEBE",
                                                fontWeight: 500,
                                                fontSize: "12px"
                                            }}>
                                                Reject
                                            </ButtonComponent>
                                        </div>
                                    </div>

                                    <div className={'mrg-top-15'}>
                                        <CommonHcpDetailsCard shiftDetails={shiftDetails} hcpDetails={hcpDetails}
                                                              getHcpDetails={getHcpDetails}
                                                              innerShiftDetails={shiftRequirementDetails}
                                                              shiftType={"pending"}/>
                                    </div>
                                    <div className={'drawer-tab-wrapper mrg-top-20'}>
                                        <TabsWrapperComponent>
                                            <TabsComponent
                                                value={activeTab}
                                                allowScrollButtonsMobile={false}
                                                onUpdate={(e: any, v: any) => {
                                                    setActiveTab(v);
                                                }}
                                            >
                                                <TabComponent label="Shift Requirement details"
                                                              value={"shift-requirement"}/>
                                                <TabComponent label="Documents" value={"document"}/>
                                                <TabComponent label="Shifts in this facility" value={"facility-shift"}/>
                                            </TabsComponent>
                                            <TabContentComponent value={activeTab} selectedTab={"shift-requirement"}>
                                                <CardComponent size={"sm"}
                                                               className={"mrg-top-10 shift-details-card-hcp"}>
                                                    <div className={"pdd-left-15 pdd-right-25 pdd-top-25"}>
                                                        {isShiftDetailsLoaded &&
                                                            <>
                                                                <div
                                                                    className={'shift-requirement-week-day-header mrg-bottom-15 form-header-text '}>Shift
                                                                    Requirement Details
                                                                </div>
                                                                <div
                                                                    className={'shift-requirement-week-day-details-card'}>
                                                                    <div className={'pdd-10'}>
                                                                        <div className={'d-flex mrg-bottom-20'}>
                                                                            <div
                                                                                className={'d-flex flex-2 ts-align-items-center'}>
                                                                                <div
                                                                                    className={"mrg-right-15 d-flex ts-align-items-center"}>
                                                                                    <ImageConfig.FacilityIcon/>
                                                                                </div>
                                                                                <Tooltip
                                                                                    title={shiftRequirementDetails?.facility_details?.name || '-'}
                                                                                    placement={"top"}>
                                                                                    <div
                                                                                        className={'ellipses-for-calender-card-data'}> {shiftRequirementDetails?.facility_details?.name || '-'}
                                                                                    </div>
                                                                                </Tooltip>
                                                                            </div>
                                                                            <div
                                                                                className={'d-flex flex-2 ts-align-items-center'}>
                                                                                <ImageConfig.TimeIcon/>
                                                                                <div
                                                                                    className={'shift-details-field-text mrg-left-15'}>
                                                                                    {CommonService?.convertMinutesToTime(shiftRequirementDetails?.shift_timings?.start_time) + "-" + CommonService?.convertMinutesToTime(shiftRequirementDetails?.shift_timings?.end_time)}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className={'d-flex mrg-bottom-20'}>
                                                                            <div
                                                                                className={'d-flex  flex-2 ts-align-items-center'}>
                                                                                <ImageConfig.DateIcon/>
                                                                                <div
                                                                                    className={'shift-details-field-text mrg-left-15'}>
                                                                                    {CommonService.convertDateFormat2(shiftRequirementDetails?.shift_date, 'MM-DD-YYYY') || '-'}
                                                                                </div>
                                                                            </div>
                                                                            <div
                                                                                className={'d-flex  flex-2 ts-align-items-center'}>
                                                                                <ImageConfig.HCPTypeIcon/>
                                                                                <div
                                                                                    className={'shift-details-field-text mrg-left-15'}>
                                                                                    {shiftRequirementDetails?.hcp_type_details?.title || "-"}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className={'d-flex mrg-bottom-20'}>
                                                                            <div
                                                                                className={'d-flex  flex-2 ts-align-items-center'}>
                                                                                <ImageConfig.HCPCountIcon/>
                                                                                <div
                                                                                    className={'shift-details-field-text'}>
                                                                                    {shiftRequirementDetails?.required_hcp_count || '-'} Staff
                                                                                    Required
                                                                                </div>
                                                                            </div>
                                                                            <div
                                                                                className={'d-flex  flex-2 ts-align-items-center'}>
                                                                                <ImageConfig.RateIcon/>
                                                                                <div
                                                                                    className={'shift-details-field-text mrg-left-15'}>
                                                                                    ${shiftRequirementDetails?.shift_rate || '-'}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        {shiftRequirementDetails?.facility_wing_details?.data?.name &&
                                                                            <div className={'d-flex'}>
                                                                                <div
                                                                                    className={'d-flex  flex-2 ts-align-items-center'}>
                                                                                    <ImageConfig.WingIcon/>
                                                                                    <div
                                                                                        className={'shift-details-field-text mrg-left-15'}>
                                                                                        {shiftRequirementDetails?.facility_wing_details?.data?.name || '-'}
                                                                                    </div>
                                                                                </div>
                                                                            </div>}
                                                                    </div>
                                                                </div>
                                                            </>
                                                        }
                                                    </div>
                                                </CardComponent>
                                            </TabContentComponent>
                                            <TabContentComponent value={activeTab} selectedTab={"document"}>
                                                {(hcpDocumentsList?.length > 0 && isHcpDetailsLoaded) &&
                                                    <CardComponent size={"sm"}
                                                                   className={"mrg-top-10 shift-details-card-hcp"}>
                                                        <div className={'document-box'}>
                                                            <div
                                                                className={'document-text mrg-left-20 mrg-top-20 mrg-bottom-5'}>Document
                                                            </div>
                                                            {
                                                                hcpDocumentsList?.map((document: any, index: any) => {
                                                                    let transformedName = "";
                                                                    if (document?.attachment_type === "tb_test") {
                                                                        transformedName = "TB Test";
                                                                    } else if (document?.attachment_type === "cpr_bls_card") {
                                                                        transformedName = "CPR BLS Card";
                                                                    } else {
                                                                        transformedName = CommonService.transformName(document?.attachment_type);
                                                                    }
                                                                    return (
                                                                        <>
                                                                            <div
                                                                                className={'hcp-document-list-wrapper cursor-pointer'}
                                                                                onClick={() => {
                                                                                    window.open(document?.url, '_blank');
                                                                                }}>
                                                                                <div className={'icon-button'}>
                                                                                    <ImageConfig.FileUploadHcp/>
                                                                                </div>
                                                                                <div
                                                                                    className={'underline-text mrg-left-10'}>
                                                                                    {transformedName}
                                                                                </div>
                                                                            </div>
                                                                        </>
                                                                    );
                                                                })
                                                            }
                                                        </div>
                                                    </CardComponent>}
                                                {(hcpDocumentsList?.length === 0 && isHcpDetailsLoaded) &&
                                                    <CardComponent size={"sm"} className={"mrg-top-10"}>
                                                        <div className={'details-screen'}>
                                                            <div
                                                                className="details-screen-title-with-image-wrapper pdd-10">
                                                                <div
                                                                    className={'details-screen-title-wrapper center-right-element'}>
                                                                    <div className={'details-screen-title'}>Documents
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <HorizontalLineComponent/>
                                                            <div className={'no-content differential-hcp-rate'}>
                                                                No Document Uploaded yet..
                                                            </div>
                                                        </div>
                                                    </CardComponent>}
                                            </TabContentComponent>
                                            <TabContentComponent value={activeTab} selectedTab={"facility-shift"}>
                                                <div className={'table-wrapper-box'}>
                                                    <TableWrapperComponent url={APIConfig.FACILITY_HCP_SHIFTS.URL}
                                                                           method={APIConfig.FACILITY_HCP_SHIFTS.METHOD}
                                                                           isPaginated={true}
                                                                           extraPayload={hcpFacilityShiftFilterData}
                                                                           columns={shiftTimingsColumn}
                                                    />
                                                </div>
                                            </TabContentComponent>

                                        </TabsWrapperComponent>
                                    </div>
                                </div>
                            </>
                        </div>
                    </div>
                </>
                }
            </div>
        </>
    );
};

export default PendingShiftDetailsComponent;
