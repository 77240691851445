import React, {useMemo} from 'react';
import {ITableColumn} from "../../../shared/models/table.model";
import {CommonService} from "../../../shared/services";
import ToolTipComponent from "../../../shared/components/tool-tip/ToolTipComponent";
import {ImageConfig} from '../../../constants';
import LinkComponent from '../../../shared/components/link/LinkComponent';
import CustomToggleComponent
    from "../../../shared/components/custom-toogle-using-button-component/CustomToggleComponent";

export const useHCPListColumn = (onHCPActivateDeactivate: any): ITableColumn[] => {
    return useMemo(() => {
        return [
            {
                title: "Staff Name",
                key: 'hcp_name',
                dataIndex: 'hcp_name',
                fixed: 'left',
                width: 200,
                render: (item: any) => {
                    return <div className={'hcp-details-wrapper'}>
                        <div className={'hcp-name-tower-wrapper'}>
                            {
                                (item?.first_name?.length + item?.last_name?.length) > 20 ?
                                    <ToolTipComponent
                                        tooltip={item?.first_name + " " + item?.last_name}
                                        showArrow={true}
                                        position={"top"}
                                    >
                                        <div className={"ellipses-for-table-data property-name"}>
                                            {item?.first_name + " " + item?.last_name}
                                        </div>
                                    </ToolTipComponent> :
                                    <div className="hcp-name">
                                        {item?.first_name + " " + item?.last_name || '-'}
                                    </div>
                            }
                        </div>
                    </div>;
                }
            },
            {
                title: "Staff ID",
                key: 'hcp_uniqueId',
                dataIndex: 'hcp_uniqueId',
                fixed: 'left',
                width: 200,
                render: (item: any) => {
                    return <div className={'hcp-details-wrapper'}>
                        <div className={'hcp-name-tower-wrapper'}>
                            {
                                (item?.hcp_uniqueId)?.length > 20 ?
                                    <ToolTipComponent
                                        tooltip={item?.hcp_uniqueId}
                                        showArrow={true}
                                        position={"top"}
                                    >
                                        <div className={"ellipses-for-table-data property-name"}>
                                            {item?.hcp_uniqueId}
                                        </div>
                                    </ToolTipComponent> :
                                    <div className="hcp-name">
                                        {item?.hcp_uniqueId || '-'}
                                    </div>
                            }
                        </div>
                    </div>;
                }
            },
            {
                title: "Email ID",
                key: 'email_id',
                dataIndex: 'email_id_name',
                width: 250,
                render: (item: any) => {
                    return <div className={'hcp-details-wrapper'}>
                        <div className={'hcp-name-tower-wrapper'}>
                            {
                                (item?.email)?.length > 20 ?
                                    <ToolTipComponent
                                        tooltip={item?.email}
                                        showArrow={true}
                                        position={"top"}
                                    >
                                        <div className={"ellipses-for-table-data property-name"}>
                                            {item?.email}
                                        </div>
                                    </ToolTipComponent> :
                                    <div className="hcp-name">
                                        {item?.email || '-'}
                                    </div>
                            }
                        </div>
                    </div>;
                }
            },
            {
                title: "Onboarded On",
                key: 'onboarded_on',
                dataIndex: 'onboarded_on',
                width: 137,
                align: 'left',
                render: (item: any) => {
                    if (item?.created_at) {
                        return <>{CommonService.convertDateFormat2(item?.created_at, "MM-DD-YYYY")}</>;
                    } else {
                        return <>N/A</>;
                    }
                }
            },
            {
                title: "Staff Type",
                key: 'hcp-type',
                dataIndex: 'hcp-type',
                render: (item: any) => {
                    return <>
                        {item?.hcp_type_details?.title ? item?.hcp_type_details?.title : "-"}
                    </>;
                }
            },
            {
                title: "Staff Type",
                key: 'staff-type',
                dataIndex: 'staff-type',
                render: (item: any) => {
                    return <>
                        {item?.is_float ? <>
                            <div style={{
                                color: "#37B96B"
                            }}>Floating
                            </div>
                        </> : <>
                            <div style={{
                                color: "#518FC9"
                            }}>Internal
                            </div>
                        </>}
                    </>;
                }
            },
            {
                title: "Phone Number",
                key: 'phone-number',
                dataIndex: 'phone_number',
                width: 140,
                render: (item: any) => {
                    return <>
                        {item?.contact_number}
                    </>;
                }
            },
            {
                title: "Location",
                key: 'location',
                dataIndex: 'location',
                width: 180,
                render: (item: any) => {
                    return <>
                        {item?.address?.city}
                    </>;
                }
            },
            {
                title: 'Activate/Deactivate',
                key: 'activate-deactivate',
                dataIndex: 'activate',
                align: 'center',
                width: 150,
                render: (item: any) => {
                    return <>
                        <CustomToggleComponent defaultOn={item?.is_active} toggleHandler={() => {
                            onHCPActivateDeactivate(item);
                        }}/>
                    </>;
                }
            },
            {
                title: "View Details",
                dataIndex: "actions",
                align: 'center',
                key: "actions",
                width: 100,
                fixed: 'right',
                render: (item: any) => {
                    if (item?._id) {
                        return <div>
                            <LinkComponent route={CommonService._routeConfig.hcpViewDetailsRoute(item?._id)}>
                                <ImageConfig.ArrowRightOutlinedIcon/>
                            </LinkComponent>
                        </div>;
                    }
                }
            }
        ];
    }, [onHCPActivateDeactivate]);
};