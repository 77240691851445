import styled from "styled-components";
import {InputBase} from "@mui/material";
import {ImageConfig} from "./constants";

export const shift_type: any = [
    {
        code: "AM",
        title: "AM",
    },
    {
        code: "PM",
        title: "PM",
    },
    {
        code: "NOC",
        title: "NOC",
    },

    {
        code: "AM-12",
        title: "AM-12",

    },

    {
        code: "PM-12",
        title: "PM-12",
    },
    {
        code: 'AM/PM-Double',
        title: 'AM/PM-Double'
    },
    {
        code: 'PM/NOC-Double',
        title: 'PM/NOC-Double'
    },
    {
        code: 'NOC/AM-Double',
        title: 'NOC/AM-Double'
    },
    {
        code: 'AM-13',
        title: 'AM-13'
    },
    {
        code: 'PM-13',
        title: 'PM-13'
    }
];

// Start of Care
// Resumption of Care
// Evaluation
// Recertification
// Discharge
// Follow Up Visit

export const home_shift_type: any = [
    {
        code: 'Evaluation (RN/OT/PT/ST)',
        title: 'Evaluation (RN/OT/PT/ST)'
    },
    {
        code: 'Start of Care (RN/OT/PT/ST)',
        title: 'Start of Care (RN/OT/PT/ST)'
    },
    {
        code: "Routine visit (RN)",
        title: "Routine visit (RN)"
    },
    {
        code: "Routine visit (LVN)",
        title: "Routine visit (LVN)"
    },
    {
        code: "Routine visit (OT/PT/ST)",
        title: "Routine visit (OT/PT/ST)"
    },
    {
        code: "Routine visit (OTA/PTA/STA)",
        title: "Routine visit (OTA/PTA/STA)"
    },
    {
        code: "Discharge",
        title: "Discharge"
    },
    {
        code: "Recertification",
        title: "Recertification"
    },
    {
        code: "Resumption of Care",
        title: "Resumption of Care"
    },
    {
        code: "Routine visit (CNA)",
        title: "Routine visit (CNA)"
    },
    {
        code: "Routine visit (CareGiver/CHHA)",
        title: "Routine visit (CareGiver/CHHA)"
    }
];

export const shiftTypeCreationOptions=[
    {label:"Add Shift Requirement",value:"add-shift-requirement"},
    {label:"Add Pre Approved Shift",value:"add-pre-approved-shift"},

]


export const shift_type_filter: any = [
    {
        code: "AM",
        title: "AM",
    },
    {
        code: "PM",
        title: "PM",
    },
    {
        code: "NOC",
        title: "NOC",
    },

    {
        code: "AM-12",
        title: "AM-12",
    },

    {
        code: "PM-12",
        title: "PM-12",
    },
    {
        code: 'AM/PM-Double',
        title: 'AM/PM-Double'
    },
    {
        code: 'PM/NOC-Double',
        title: 'PM/NOC-Double'
    },
    {
        code: 'NOC/AM-Double',
        title: 'NOC/AM-Double'
    },
    {
        code: 'AM-13',
        title: 'AM-13'
    },
    {
        code: 'PM-13',
        title: 'PM-13'
    },
    {
        code: 'Evaluation (RN/OT/PT/ST)',
        title: 'Evaluation (RN/OT/PT/ST)'
    },
    {
        code: 'Start of Care (RN/OT/PT/ST)',
        title: 'Start of Care (RN/OT/PT/ST)'
    },
    {
        code: "Routine visit (RN)",
        title: "Routine visit (RN)"
    },
    {
        code: "Routine visit (LVN)",
        title: "Routine visit (LVN)"
    },
    {
        code: "Routine visit (OT/PT/ST)",
        title: "Routine visit (OT/PT/ST)"
    },
    {
        code: "Routine visit (OTA/PTA/STA)",
        title: "Routine visit (OTA/PTA/STA)"
    },
    {
        code: "Routine visit (CNA)",
        title: "Routine visit (CNA)"
    },
    {
        code: "Routine visit (CareGiver/CHHA)",
        title: "Routine visit (CareGiver/CHHA)"
    },
    {
        code: "Discharge",
        title: "Discharge"
    },
    {
        code: "Recertification",
        title: "Recertification"
    },
    {
        code: "Resumption of Care",
        title: "Resumption of Care"
    },
    {
        code: "days",
        title: "days",
    },
    {
        code: "nights",
        title: "nights",
    },
    {
        code: "flexible",
        title: "flexible",
    },
];

export const open_shift_status = [
    {
        code: "open",
        title: "Open",
    },
    {
        code: "partially_open",
        title: "Partially Open"
    },
    {
        code: "unfilled",
        title: "Unfilled"
    },
    {
        code: "filled",
        title: "Filled"
    },
    {
        code: "cancelled",
        title: "Cancelled",
    }
];

export const shift_status = [
    {
        code: "pending",
        title: "Applied"
    },
    {
        code: "approved",
        title: "Approved"
    },
    {
        code: "completed",
        title: "Completed"
    },
    {
        code: "confirmed",
        title: "Confirmed"
    },
    {
        code: "cancelled",
        title: "Cancelled",
    },
];
export const showDropDownBelowField: any = {
    MenuProps: {
        anchorOrigin: {
            vertical: "bottom",
            horizontal: "center",
        },
        getContentAnchorEl: null,
    },
};

export const americanTimeZone = [
    {code: 720, title: "(UTC−12:00) Baker Island and Howland Island "},
    {code: 660, title: "(UTC−11:00) (ST) American Samoa, Jarvis Island, Kingman Reef, Midway Atoll and Palmyra Atoll"},
    {code: 600, title: "(UTC−10:00) (HT) Hawaii, most of the Aleutian Islands, and Johnston Atoll"},
    {code: 540, title: "(UTC−09:00) (AKT) Most of the State of Alaska "},
    {
        code: 480,
        title: "(UTC−08:00) (PT) Pacific Time zone: the Pacific coast states, the Idaho panhandle and most of Nevada and Oregon "
    },
    {
        code: 420,
        title: "(UTC−07:00) (MT) Mountain Time zone: most of Idaho, part of Oregon, and the Mountain states plus western parts of some adjacent states "
    },
    {
        code: 360,
        title: "(UTC-06:00) (CT) Central Time zone: a large area spanning from the Gulf Coast to the Great Lakes "
    },
    {
        code: 300,
        title: "(UTC−05:00) (ET) Eastern Time zone: roughly a triangle covering all the states from the Great Lakes down to Florida and east to the Atlantic coast"
    },
    {code: 240, title: "(UTC−04:00) (AST) Puerto Rico, the U.S. Virgin Islands  "},
    {code: -600, title: "(UTC+10:00) (ChT) Guam and the Northern Mariana Islands "},
    {code: -720, title: "(UTC+12:00) Wake Island "},
];

export const BootstrapInput = styled(InputBase)(({theme}) => ({
    'label + &': {
        marginTop: 20,
    },
    '& .MuiInputBase-input': {
        borderRadius: 4,
        position: 'relative',
        backgroundColor: "#FAFAFA",
        border: '1px solid #0DB9D8',
        fontSize: 16,
        //padding: '10px 26px 10px 12px',
        // Use the system font instead of the default Roboto font.
        '&:focus': {
            borderRadius: 4,
            borderColor: '#0DB9D8',
        },
    },
}));

export const ImageSupportedFile: any = ["jpg", "jpeg", "png"];
export const OtherFilesSupported: any = ["pdf", "jpg", "jpeg", "png"];


export const addFacilitySteps = [
    {
        label: 'Facility Details',
        description: `In this step you will be filling the basic details 
                           of the facility.`,
    },
    {
        label: 'Facility Address Details',
        description:
            'You will add the address details of the facility.',
    },
    {
        label: 'Shift Timings',
        description:
            'In this step you’ll be adding different shift timings present in the facility.',
    },
];

export const addAgencySteps = [
    // {
    //     label: 'Agency Selection',
    //     description:
    //         'In this step, you will select the agency you want to link with the facility, or you can opt to add a new agency.',
    // },
    {
        label: 'New Agency Details',
        description:
            'In this step you’ll be adding a new agency by filling up the basic details.',
    },
    {
        label: 'Agency Address Details',
        description:
            'In this step you’ll be adding a new agency’s address details.',
    },
    {
        label: 'Agency  HCP Rates',
        description:
            'In this step you’ll be adding different rates of HCPs as per the contract.',
    },
    {
        label: 'Additional Rates',
        description:
            'In this step you’ll be adding various additional rates as per the contract.',
    },
    {
        label: 'Preferences',
        description:
            'In this step you’ll be setting up basic preferences with respect to the agency.',
    },
    {
        label: 'Contract',
        description:
            'In this step you’ll be uploading the contract agreed between the facility and the agency.',
    },
];


export const LinkAgencySteps = [
    {
        label: 'Agency Selection',
        description:
            'In this step, you will select the agency you want to link with the facility, or you can opt to add a new agency.',
    },
    {
        label: 'Agency  HCP Rates',
        description:
            'In this step you’ll be adding different rates of HCPs as per the contract.',
    },
    {
        label: 'Additional Rates',
        description:
            'In this step you’ll be adding various additional rates as per the contract.',
    },
    {
        label: 'Preferences',
        description:
            'In this step you’ll be setting up basic preferences with respect to the agency.',
    },
    {
        label: 'Contract',
        description:
            'In this step you’ll be uploading the contract agreed between the facility and the agency.',
    },
];

export const addHcpSteps = [
    {
        label: 'Staff Details',
        description: `In this step you will be filling the basic details 
of the Staff.`,
    },
    {
        label: 'Staff Address Details',
        description:
            'You will add the address details of the Staff.',
    },
    {
        label: 'Educational Details',
        description:
            'In this step you’ll be adding education details of the Staff.',
    },
    {
        label: "Work Experience",
        description: "In this step you’ll be adding work experience of the Staff."
    },
    {
        label: 'Document Upload',
        description:
            'In this step you’ll be uploading relevant document of the Staff.',
    },
    // {
    //     label: 'Contract Upload',
    //     description:
    //         'In this step you’ll be uploading the contract signed by the HCP.',
    // },
];

export const addInternalHcpSteps = [
    {
        label: 'Internal Staff Details',
        description: `In this step you will be filling the basic details 
of the Internal Staff.`,
    },
    {
        label: 'Internal Staff Address Details',
        description:
            'You will add the address details of the Internal Staff.',
    },
    {
        label: 'Document Upload',
        description:
            'In this step you’ll be uploading relevant document of the Internal Staff.',
    },
    // {
    //     label: 'Contract Upload',
    //     description:
    //         'In this step you’ll be uploading the contract signed by the Internal HCP.',
    // },
];

export const addUserSteps = [
    {
        label: 'New User Details',
        description: "In this step you will be filling the basic details \n" +
            "of the new user.",
    },
];

export const warningZone: any = [
    {code: "green", title: "Green"},
    {code: "orange", title: "Orange"},
    {code: "red", title: "Red"},
];

export const isEmail = new RegExp('^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$');


export const pendingTabButtonOptions = [
    //  {label: "Download CSV", value: "download-csv"},
    {label: "Approve Shifts", value: "approve-shifts"},
    {label: "Reject Shifts", value: "reject-shifts"},
];


export const approvedTabButtonOptions = [
    //  {label: "Download CSV", value: "download-csv"},
    {label: "Download CSV", value: "download-csv"},
    {label: "Mark as Completed", value: "mark-as-completed"},
    {label: "Cancel Shift", value: "mark-as-cancel"},
];

export const completedTabButtonOptions = [
    //  {label: "Download CSV", value: "download-csv"},
    {label: "Download CSV", value: "download-csv"},
    {label: "Mark as Confirmed", value: "mark-as-confirmed"},
    {label: "Cancel Shift", value: "mark-as-cancel"},
];


export const confirmedTabButtonOptions = [
    //  {label: "Download CSV", value: "download-csv"},
    {label: "Download CSV", value: "download-csv"},
    {label: "Cancel Shift", value: "mark-as-cancel"},
];


export const expiry_date_docs = ["TB Exam", "Physical Exam", "BLS Cert", "CPR Cert", "Nursing License"];

export const genderList = [{
    title: "Male",
    code: "male"
}, {
    title: "Female",
    code: "female"
}, {
    title: "Neutral",
    code: "neutral"
}];


export const ITEM_HEIGHT = 48;
export const ITEM_PADDING_TOP = 8;
export const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

export const HcpDocuments = [{
    document_name: "Resume",
    document_type: "Resume",
    document_key: "Resume",
    expiry_date: null,
    is_expiry_date: false,
},
    {
        document_name: "Nursing License",
        document_type: "Nursing License",
        document_key: "Nursing License",
        expiry_date: null,
        is_expiry_date: true,
    },
    {
        document_name: "State ID / Drivers License",
        document_type: "State ID / Drivers License",
        document_key: "State ID / Drivers License",
        expiry_date: null,
        is_expiry_date: false,
    },
    {
        document_name: "CPR Cert",
        document_type: "CPR Cert",
        document_key: "CPR Cert",
        expiry_date: null,
        is_expiry_date: true,
    },
    {
        document_name: "BLS Cert",
        document_type: "BLS Cert",
        document_key: "BLS Cert",
        expiry_date: null,
        is_expiry_date: true,
    },
    {
        document_name: "Vaccination Record",
        document_type: "Vaccination Record",
        document_key: "Vaccination Record",
        is_active: false,
        expiry_date: null,
        is_expiry_date: false,
    },
    {
        document_name: "Covid Vaccination Record",
        document_type: "Covid Vaccination Record",
        document_key: "Covid Vaccination Record",
        expiry_date: null,
        is_expiry_date: false,
    },
    {
        document_name: "Covid Booster",
        document_type: "Covid Booster",
        document_key: "Covid Booster",
        expiry_date: null,
        is_expiry_date: false,
    },
    {
        document_name: "Physical Exam",
        document_type: "Physical Exam",
        document_key: "Physical Exam",
        expiry_date: null,
        is_expiry_date: true,
    },
    {
        document_name: "TB Exam",
        document_type: "TB Exam",
        document_key: "TB Exam",
        expiry_date: null,
        is_expiry_date: true,
    },
    {
        document_name: "Background Check",
        document_type: "Background Check",
        document_key: "Background Check",
        expiry_date: null,
        is_expiry_date: false,
    },
    {
        document_name: "DOJ Livescan",
        document_type: "DOJ Livescan",
        document_key: "DOJ Livescan",
        expiry_date: null,
        is_expiry_date: false,
    },
    {
        document_name: "Profile Picture",
        document_type: "Profile Picture",
        document_key: "Profile Picture",
        expiry_date: null,
        is_expiry_date: false,
    },]

export const OTpayOption = [
    {
        code: "yes",
        title: "Yes",
    }, {
        code: "no",
        title: "No",
    }
]


export const visitPatientBy = [
    {
        code: "Date",
        title: "date",
    },
    {
        code: "Time",
        title: "time",
    },
]

// Languages
// English
// Spanish
// Hindi
// Mandarin
// Russian
// Other   [ Please specify ]


export const languagesList = [
    {
        code: "English",
        title: "English",
    },
    {
        code: "Spanish",
        title: "Spanish",
    },
    {
        code: "Hindi",
        title: "Hindi",
    },
    {
        code: "Mandarin",
        title: "Mandarin",
    },
    {
        code: "Russian",
        title: "Russian",
    },
    {
        code: "Other",
        title: "Other",
    },

]


export const contactTypeList = [
    {
        code: "days",
        title: "Days",
    }, {
        code: "nights",
        title: "Nights",
    }, {
        code: "flexible",
        title: "Flexible",
    }
]

export const shiftDurationList = [
    {
        code: "8-hour",
        title: "8 Hours",
    },
    {
        code: "10-hour",
        title: "10 Hours",
    }, {
        code: "12-hour",
        title: "12 Hours",
    },
]

export const contractShiftType = [
    {
        code: "travel",
        title: "Travel",
    }, {
        code: "contract",
        title: "Contract",
    },
    {
        code: "permanent",
        title: "Permanent",
    },
]


export const weekDaysList = [
    {
        code: "1",
        title: "1",
    }, {
        code: "2",
        title: "2",
    },
    {
        code: "3",
        title: "3",
    },
    {
        code: "4",
        title: "4",
    },
    {
        code: "5",
        title: "5",
    },
    {
        code: "6",
        title: "6",
    },
    {
        code: "7",
        title: "7",
    },
]


export const TimezoneMenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 1000,
        },
    },
};


export const schedulerCalenderRepresentation = [
    {
        code: "AM",
        title: "AM",
        color: "scheduler-am-type",
        bc_color: "scheduler-am-bc-color",
    },
    {
        code: "PM",
        title: "PM",
        color: "scheduler-pm-type",
        bc_color: "scheduler-pm-bc-color",
    },
    {
        code: "NOC",
        title: "NOC",
        color: "scheduler-noc-type",
        bc_color: "scheduler-noc-bc-color",
    },

    {
        code: "AM-12",
        title: "AM-12",
        color: "scheduler-am-type",
        bc_color: "scheduler-am-bc-color",
    },

    {
        code: "PM-12",
        title: "PM-12",
        color: "scheduler-pm-type",
        bc_color: "scheduler-pm-bc-color",
    },
    {
        code: 'AM/PM-Double',
        title: 'AM/PM-Double',
        color: "scheduler-am-type",
        bc_color: "scheduler-am-bc-color",
    },
    {
        code: 'PM/NOC-Double',
        title: 'PM/NOC-Double',
        color: "scheduler-pm-type",
        bc_color: "scheduler-pm-bc-color",
    },
    {
        code: 'NOC/AM-Double',
        title: 'NOC/AM-Double',
        color: "scheduler-noc-type",
        bc_color: "scheduler-noc-bc-color",
    },
    {
        code: 'AM-13',
        title: 'AM-13',
        color: "scheduler-am-type",
        bc_color: "scheduler-am-bc-color",
    },
    {
        code: 'PM-13',
        title: 'PM-13',
        color: "scheduler-pm-type",
        bc_color: "scheduler-pm-bc-color",
    },

    {
        code: 'START OF CARE',
        title: 'Start of Care',
        color: "scheduler-pm-type",
        bc_color: "scheduler-pm-bc-color",
    },
    {
        code: 'RESUMPTION OF CARE',
        title: 'Resumption of Care',
        color: "scheduler-pm-type",
        bc_color: "scheduler-pm-bc-color",
    },
    {
        code: "EVALUATION",
        title: "Evaluation",
        color: "scheduler-pm-type",
        bc_color: "scheduler-pm-bc-color",
    },
    {
        code: "RECERTIFICATION",
        title: "Recertification",
        color: "scheduler-pm-type",
        bc_color: "scheduler-pm-bc-color",
    },
    {
        code: "DISCHARGE",
        title: "Discharge",
        color: "scheduler-pm-type",
        bc_color: "scheduler-pm-bc-color",
    },
    {
        code: "FOLLOW UP VISIT",
        title: "Follow Up Visit",
        color: "scheduler-pm-type",
        bc_color: "scheduler-pm-bc-color",
    },


    {
        code: 'Evaluation (RN/OT/PT/ST)',
        title: 'Evaluation (RN/OT/PT/ST)',
        color: "scheduler-pm-type",
        bc_color: "scheduler-pm-bc-color",
    },
    {
        code: 'Start of Care (RN/OT/PT/ST)',
        title: 'Start of Care (RN/OT/PT/ST)',
        color: "scheduler-pm-type",
        bc_color: "scheduler-pm-bc-color",
    },
    {
        code: "Routine visit (RN)",
        title: "Routine visit (RN)",
        color: "scheduler-pm-type",
        bc_color: "scheduler-pm-bc-color",
    },
    {
        code: "Routine visit (LVN)",
        title: "Routine visit (LVN)",
        color: "scheduler-pm-type",
        bc_color: "scheduler-pm-bc-color",
    },
    {
        code: "Routine visit (OT/PT/ST)",
        title: "Routine visit (OT/PT/ST)",
        color: "scheduler-pm-type",
        bc_color: "scheduler-pm-bc-color",
    },
    {
        code: "Routine visit (OTA/PTA/STA)",
        title: "Routine visit (OTA/PTA/STA)",
        color: "scheduler-pm-type",
        bc_color: "scheduler-pm-bc-color",
    },
    {
        code: "Discharge",
        title: "Discharge",
        color: "scheduler-pm-type",
        bc_color: "scheduler-pm-bc-color",
    },
    {
        code: "Recertification",
        title: "Recertification",
        color: "scheduler-pm-type",
        bc_color: "scheduler-pm-bc-color",
    },
    {
        code: "Resumption of Care",
        title: "Resumption of Care",
        color: "scheduler-pm-type",
        bc_color: "scheduler-pm-bc-color",
    },
    {
        code: "Routine visit (CNA)",
        title: "Routine visit (CNA)",
        color: "scheduler-pm-type",
        bc_color: "scheduler-pm-bc-color",
    },
    {
        code: "Routine visit (CareGiver/CHHA)",
        title: "Routine visit (CareGiver/CHHA)",
        color: "scheduler-pm-type",
        bc_color: "scheduler-pm-bc-color",
    },


    {
        code: "days",
        title: "Days",
        color: "scheduler-pm-type",
        bc_color: "scheduler-pm-bc-color",
    },
    {
        code: "nights",
        title: "Nights",
        color: "scheduler-pm-type",
        bc_color: "scheduler-pm-bc-color",
    },
    {
        code: "flexible",
        title: "Flexible",
        color: "scheduler-pm-type",
        bc_color: "scheduler-pm-bc-color",
    },
];
export const shiftTypeLayoutRepresentation = [
    {
        code: "AM",
        title: "AM",
        icon: <ImageConfig.AmShiftIcon/>,
        classname: "am-holder",
        differentiatorClassname: "am-differentiator-holder",
        color: "am-type"
    },
    {
        code: "PM",
        title: "PM",
        icon: <ImageConfig.PmShiftIcon/>,
        classname: "pm-holder",
        differentiatorClassname: "pm-differentiator-holder",
        color: "pm-type"
    },
    {
        code: "NOC",
        title: "NOC",
        icon: <ImageConfig.NocShiftIcon/>,
        classname: "noc-holder",
        differentiatorClassname: "noc-differentiator-holder",
        color: "noc-type"
    },

    {
        code: "AM-12",
        title: "AM-12",
        icon: <ImageConfig.AmShiftIcon/>,
        classname: "am-12-holder",
        differentiatorClassname: "am-differentiator-holder",
        color: "am-type"
    },

    {
        code: "PM-12",
        title: "PM-12",
        icon: <ImageConfig.NocShiftIcon/>,
        classname: "pm-12-holder",
        differentiatorClassname: "pm-differentiator-holder",
        color: "pm-type"
    },
    {
        code: 'AM/PM-Double',
        title: 'AM/PM-Double',
        icon: <ImageConfig.AmShiftIcon/>,
        classname: "am-pm-double-holder",
        differentiatorClassname: "am-differentiator-holder",
        color: "am-type"
    },
    {
        code: 'PM/NOC-Double',
        title: 'PM/NOC-Double',
        icon: <ImageConfig.PmShiftIcon/>,
        classname: "pm-noc-double-holder",
        differentiatorClassname: "pm-differentiator-holder",
        color: "pm-type"
    },
    {
        code: 'NOC/AM-Double',
        title: 'NOC/AM-Double',
        icon: <ImageConfig.NocShiftIcon/>,
        classname: "noc-am-double-holder",
        differentiatorClassname: "noc-differentiator-holder",
        color: "noc-type"
    },
    {
        code: 'AM-13',
        title: 'AM-13',
        icon: <ImageConfig.AmShiftIcon/>,
        classname: "am-13-holder",
        differentiatorClassname: "am-differentiator-holder",
        color: "am-type"
    },
    {
        code: 'PM-13',
        title: 'PM-13',
        icon: <ImageConfig.PmShiftIcon/>,
        classname: "pm-13-holder",
        differentiatorClassname: "pm-differentiator-holder",
        color: "pm-type"
    },
    {
        code: 'START OF CARE',
        title: 'Start of Care',
        color: "scheduler-pm-type",
        icon: <ImageConfig.HomeHealthShiftIcon/>,
        bc_color: "scheduler-pm-bc-color",
    },
    {
        code: 'RESUMPTION OF CARE',
        title: 'Resumption of Care',
        icon: <ImageConfig.HomeHealthShiftIcon/>,
        color: "scheduler-pm-type",
        bc_color: "scheduler-pm-bc-color",
    },
    {
        code: "EVALUATION",
        title: "Evaluation",
        icon: <ImageConfig.HomeHealthShiftIcon/>,
        color: "scheduler-pm-type",
        bc_color: "scheduler-pm-bc-color",
    },
    {
        code: "RECERTIFICATION",
        title: "Recertification",
        icon: <ImageConfig.HomeHealthShiftIcon/>,
        color: "scheduler-pm-type",
        bc_color: "scheduler-pm-bc-color",
    },
    {
        code: "DISCHARGE",
        title: "Discharge",
        icon: <ImageConfig.HomeHealthShiftIcon/>,
        color: "scheduler-pm-type",
        bc_color: "scheduler-pm-bc-color",
    },
    {
        code: "FOLLOW UP VISIT",
        title: "Follow Up Visit",
        icon: <ImageConfig.HomeHealthShiftIcon/>,
        color: "scheduler-pm-type",
        bc_color: "scheduler-pm-bc-color",
    },


    {
        code: 'Evaluation (RN/OT/PT/ST)',
        title: 'Evaluation (RN/OT/PT/ST)',
        icon: <ImageConfig.HomeHealthShiftIcon/>,
        color: "scheduler-pm-type",
        bc_color: "scheduler-pm-bc-color",
    },
    {
        code: 'Start of Care (RN/OT/PT/ST)',
        title: 'Start of Care (RN/OT/PT/ST)',
        icon: <ImageConfig.HomeHealthShiftIcon/>,
        color: "scheduler-pm-type",
        bc_color: "scheduler-pm-bc-color",
    },
    {
        code: "Routine visit (RN)",
        title: "Routine visit (RN)",
        icon: <ImageConfig.HomeHealthShiftIcon/>,
        color: "scheduler-pm-type",
        bc_color: "scheduler-pm-bc-color",
    },
    {
        code: "Routine visit (LVN)",
        title: "Routine visit (LVN)",
        icon: <ImageConfig.HomeHealthShiftIcon/>,
        color: "scheduler-pm-type",
        bc_color: "scheduler-pm-bc-color",
    },
    {
        code: "Routine visit (OT/PT/ST)",
        title: "Routine visit (OT/PT/ST)",
        icon: <ImageConfig.HomeHealthShiftIcon/>,
        color: "scheduler-pm-type",
        bc_color: "scheduler-pm-bc-color",
    },
    {
        code: "Routine visit (OTA/PTA/STA)",
        title: "Routine visit (OTA/PTA/STA)",
        icon: <ImageConfig.HomeHealthShiftIcon/>,
        color: "scheduler-pm-type",
        bc_color: "scheduler-pm-bc-color",
    },
    {
        code: "Discharge",
        title: "Discharge",
        icon: <ImageConfig.HomeHealthShiftIcon/>,
        color: "scheduler-pm-type",
        bc_color: "scheduler-pm-bc-color",
    },
    {
        code: "Recertification",
        title: "Recertification",
        icon: <ImageConfig.HomeHealthShiftIcon/>,
        color: "scheduler-pm-type",
        bc_color: "scheduler-pm-bc-color",
    },
    {
        code: "Resumption of Care",
        title: "Resumption of Care",
        icon: <ImageConfig.HomeHealthShiftIcon/>,
        color: "scheduler-pm-type",
        bc_color: "scheduler-pm-bc-color",
    },
    {
        code: "Routine visit (CNA)",
        title: "Routine visit (CNA)",
        icon: <ImageConfig.HomeHealthShiftIcon/>,
        color: "scheduler-pm-type",
        bc_color: "scheduler-pm-bc-color",
    },
    {
        code: "Routine visit (CareGiver/CHHA)",
        title: "Routine visit (CareGiver/CHHA)",
        icon: <ImageConfig.HomeHealthShiftIcon/>,
        color: "scheduler-pm-type",
        bc_color: "scheduler-pm-bc-color",
    },


    {
        code: "days",
        title: "Day",
        icon: <ImageConfig.TravelContractShiftIcon/>,
        color: "scheduler-pm-type",
        bc_color: "scheduler-pm-bc-color",
    },
    {
        code: "nights",
        title: "Nights",
        icon: <ImageConfig.TravelContractShiftIcon/>,
        color: "scheduler-pm-type",
        bc_color: "scheduler-pm-bc-color",
    },
    {
        code: "flexible",
        title: "Flexible",
        icon: <ImageConfig.TravelContractShiftIcon/>,
        color: "scheduler-pm-type",
        bc_color: "scheduler-pm-bc-color",
    },
];

export const shiftHomeType: any = [
    {
        code: "Open Shift",
        title: "Per Diem",
    },
    {
        code: "Home Health",
        title: "Per Job/Visit",
    },
    {
        code: "Contract Travel",
        title: "Contract/Travel",
    },

];
