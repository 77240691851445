import "./EditAdminDetailsComponent.scss";
import {useCallback, useEffect, useState} from "react";
import {Field, FieldProps, Form, Formik, FormikHelpers} from "formik";
import * as Yup from "yup";
import {CommonService} from "../../../../../shared/services";
import {IAPIResponseType} from "../../../../../shared/models/api.model";
import {ImageConfig, Misc} from "../../../../../constants";
import FormikInputComponent from "../../../../../shared/components/form-controls/formik-input/FormikInputComponent";
import ButtonComponent from "../../../../../shared/components/button/ButtonComponent";
import LabelComponent from "../../../../../shared/components/label/LabelComponent";
import PhoneInputComponent from "../../../../../shared/components/phoneInput/PhoneInputComponent";
import FormikCheckBoxComponent
    from "../../../../../shared/components/form-controls/formik-check-box/FormikCheckBoxComponent";

// import FormDebuggerComponent from "../../../shared/components/form-debugger/FormDebuggerComponent";

interface EditAdminComponentProps {
    facilityDetails: any;
    closeEditDrawer: any;
    getAgencyDetails: any;
}

const EditFacilityAdminValidationSchema = Yup.object().shape({
        first_name: Yup.string().required('Admin first name is required'),
        last_name: Yup.string().required('Admin last name is required'),
        contact_number: Yup.string().min(12, "min 10 digits").required("Required"),
        email: Yup.string().email('Invalid admin email address').required('Admin email is required'),
    }
);

interface EditAdminFormInitialValuesFormSchema {
    first_name: string;
    last_name: string;
    contact_number: string;
    email: string;
    notification_preferences: {
        isSmsRequired: any;
        isEmailRequired: any;
        isPush_notificationRequired: any;
    };
}

const EditAdminFormInitialValues: EditAdminFormInitialValuesFormSchema = {
    first_name: "",
    last_name: "",
    contact_number: "",
    email: "",
    notification_preferences: {
        isSmsRequired: true,
        isEmailRequired: true,
        isPush_notificationRequired: true,
    }
};


const EditAgencyAdminDetailsComponent = (props: EditAdminComponentProps) => {
    const {facilityDetails, closeEditDrawer, getAgencyDetails} = props;
    const [initialValues, setInitialValues] = useState<EditAdminFormInitialValuesFormSchema>(EditAdminFormInitialValues);

    useEffect(() => {
        const basicDetails = {
            first_name: facilityDetails?.admin_details?.first_name,
            last_name: facilityDetails?.admin_details?.last_name,
            contact_number: facilityDetails?.admin_details?.contact_number,
            email: facilityDetails?.admin_details?.email,
            notification_preferences: {
                isSmsRequired: facilityDetails?.admin_details?.notification_preferences?.isSmsRequired,
                isEmailRequired: facilityDetails?.admin_details?.notification_preferences?.isEmailRequired,
                isPush_notificationRequired: facilityDetails?.admin_details?.notification_preferences?.isPush_notificationRequired,
            }
        };
        setInitialValues(basicDetails);
    }, [facilityDetails]);

    const onSubmitAdminDetails = useCallback((values: any, {setErrors, setSubmitting}: FormikHelpers<any>) => {
        setSubmitting(true);
        const payload = {
            ...values,
        };
        let apiCall: any = {};
        apiCall = CommonService._agency.EditAgencyAdminAPICall(facilityDetails?.admin_details._id, payload);
        apiCall.then((response: IAPIResponseType<any>) => {
            CommonService._alert.showToast(response[Misc.API_RESPONSE_MESSAGE_KEY], "success");
            closeEditDrawer();
            getAgencyDetails();
        })
            .catch((error: any) => {
                CommonService.handleErrors(setErrors, error);
            }).finally(() => {
            setSubmitting(false);
        });
    }, [closeEditDrawer, getAgencyDetails, facilityDetails]);

    return (
        <>
            <div className={'edit-agency-admin-component'}>
                <div className={'edit-admin-header'}>
                    <div className={'form-header-text'}>Edit Admin Details</div>
                    <div className={'icon-button'} onClick={closeEditDrawer}><ImageConfig.CrossIcon/></div>
                </div>
                <div className={'edit-admin-form-wrapper'}>
                    <Formik
                        validationSchema={EditFacilityAdminValidationSchema}
                        initialValues={initialValues}
                        validateOnChange={false}
                        validateOnBlur={true}
                        enableReinitialize={true}
                        validateOnMount={true}
                        onSubmit={onSubmitAdminDetails}
                    >
                        {({values, errors, validateForm, isValid, setFieldValue, isSubmitting}) => {
                            // eslint-disable-next-line react-hooks/rules-of-hooks
                            useEffect(() => {
                                validateForm();
                            }, [validateForm, values]);
                            return (
                                <Form className="t-form scroll-content form-holder" noValidate={true}>
                                    {/*<FormDebuggerComponent values={values} errors={errors} showDebugger={true}/>*/}
                                    <div className={'add-form-holder'}>
                                        <div className={'add-form-input-container'}>
                                            <div className="edit-component-content">
                                                <div className={'d-flex'}>
                                                    <div className={'mrg-right-25 half-width'} onClick={() => {
                                                        console.log(initialValues);
                                                    }}>
                                                        <Field name={'first_name'}>
                                                            {
                                                                (field: FieldProps) => (
                                                                    <FormikInputComponent
                                                                        label={'First Name'}
                                                                        placeholder={'Enter First Name'}
                                                                        type={"text"}
                                                                        required={true}
                                                                        formikField={field}
                                                                        fullWidth={true}
                                                                    />
                                                                )
                                                            }
                                                        </Field>
                                                    </div>
                                                    <div className={'half-width'}>
                                                        <Field name={'last_name'}>
                                                            {
                                                                (field: FieldProps) => (
                                                                    <FormikInputComponent
                                                                        label={'Last Name'}
                                                                        placeholder={'Enter Last Name'}
                                                                        type={"text"}
                                                                        required={true}
                                                                        formikField={field}
                                                                        fullWidth={true}
                                                                    />
                                                                )
                                                            }
                                                        </Field>
                                                    </div>
                                                </div>
                                                <div className={'d-flex'}>
                                                    <div className={'mrg-right-25 half-width'}>
                                                        {/*<Field name={'contact_number'}>*/}
                                                        {/*    {*/}
                                                        {/*        (field: FieldProps) => (*/}
                                                        {/*            <FormikInputComponent*/}
                                                        {/*                label={'Phone Number'}*/}
                                                        {/*                placeholder={'000-000-0000'}*/}
                                                        {/*                type={"text"}*/}
                                                        {/*                required={true}*/}
                                                        {/*                formikField={field}*/}
                                                        {/*                fullWidth={true}*/}
                                                        {/*            />*/}
                                                        {/*        )*/}
                                                        {/*    }*/}
                                                        {/*</Field>*/}
                                                        <LabelComponent title={'Phone Number'} required={true}/>
                                                        <Field name={'contact_number'}>
                                                            {(field: FieldProps) => {
                                                                return <PhoneInputComponent field={field}
                                                                                            placeholder={"Enter Phone number"}/>;
                                                            }}
                                                        </Field>
                                                    </div>
                                                    <div className={'half-width'}>
                                                        <Field name={'email'}>
                                                            {
                                                                (field: FieldProps) => (
                                                                    <FormikInputComponent
                                                                        label={'Email Address'}
                                                                        placeholder={'Enter Email Address'}
                                                                        type={"text"}
                                                                        required={true}
                                                                        formikField={field}
                                                                        fullWidth={true}
                                                                    />
                                                                )
                                                            }
                                                        </Field>
                                                    </div>
                                                </div>
                                                <div className={'form-top-header-text'}>Notification Preferences</div>
                                                <div className="ts-row">
                                                    <div className="ts-col-4">
                                                        <Field name={'notification_preferences.isSmsRequired'}>
                                                            {
                                                                (field: FieldProps) => (
                                                                    <FormikCheckBoxComponent
                                                                        formikField={field}
                                                                        label={"SMS"}
                                                                    />
                                                                )
                                                            }
                                                        </Field>
                                                    </div>
                                                    <div className="ts-col-4">
                                                        <Field name={'notification_preferences.isEmailRequired'}>
                                                            {
                                                                (field: FieldProps) => (
                                                                    <FormikCheckBoxComponent
                                                                        formikField={field}
                                                                        label={"Email"}
                                                                    />
                                                                )
                                                            }
                                                        </Field>
                                                    </div>
                                                    <div className="ts-col-4">
                                                        <Field
                                                            name={'notification_preferences.isPush_notificationRequired'}>
                                                            {
                                                                (field: FieldProps) => (
                                                                    <FormikCheckBoxComponent
                                                                        formikField={field}
                                                                        label={"In App"}
                                                                    />
                                                                )
                                                            }
                                                        </Field>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="add-form-action-button">
                                        <ButtonComponent
                                            disabled={isSubmitting || !isValid}
                                            isLoading={isSubmitting}
                                            color={'secondary'}
                                            type={'submit'}
                                        >
                                            Save Details
                                        </ButtonComponent>
                                    </div>
                                </Form>
                            );
                        }}
                    </Formik>
                </div>
            </div>
        </>
    );
};
export default EditAgencyAdminDetailsComponent;

