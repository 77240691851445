import React, {useMemo} from 'react';
import {ITableColumn} from "../../../shared/models/table.model";
import {CommonService} from "../../../shared/services";
import ToolTipComponent from "../../../shared/components/tool-tip/ToolTipComponent";
import {ImageConfig} from '../../../constants';
import LinkComponent from '../../../shared/components/link/LinkComponent';
import CustomToggleComponent
    from "../../../shared/components/custom-toogle-using-button-component/CustomToggleComponent";
import TagComponent from "../../../shared/components/tag/TagComponent";

export const useAgencyListColumn = (onAgencyActivateDeactivate: any): ITableColumn[] => {
    return useMemo(() => {
        return [
            {
                title:"Agency Name",
                key: 'agency_name',
                dataIndex: 'name',
                fixed: 'left',
                width: 250,
                render: (item: any) => {
                    return <div className={'agency-details-wrapper'}>
                        <div className={'facility-name-tower-wrapper'}>
                            {
                                (item?.name)?.length > 20 ?
                                    <ToolTipComponent
                                        tooltip={item?.name}
                                        showArrow={true}
                                        position={"top"}
                                    >
                                        <div className={"ellipses-for-table-data property-name"}>
                                            {item?.name}
                                        </div>
                                    </ToolTipComponent> :
                                    <div className="agency-name">
                                        {item?.name || '-'}
                                    </div>
                            }
                        </div>
                    </div>;
                }
            },
            {
                title:"Location",
                key: 'location',
                dataIndex: 'location',
                width: 150,
                render: (item: any) => {
                    return (
                        <>
                            <div className={'mrg-left-5'}>
                                {item?.address?.city || '-'}
                            </div>
                        </>
                    );
                }
            },
            {
                title:"Onboarded On",
                key: 'onboarded_on',
                dataIndex: 'onboarded_on',
                width: 137,
                align: 'left',
                render: (item: any) => {
                    if (item?.created_at) {
                        return <>
                            <div className={'mrg-left-5'}>
                                {CommonService.convertDateFormat2(item?.created_at, "MM-DD-YYYY")}
                            </div>
                        </>;
                    } else {
                        return <>
                            <div className={'mrg-left-5'}>
                                N/A
                            </div>
                        </>;
                    }
                }
            },
            {
                title:"Admin Name",
                key: 'admin',
                dataIndex: 'admin',
                render: (item: any) => {
                    return <div className={'agency-details-wrapper mrg-left-5'}>
                        <div className={'facility-name-tower-wrapper'}>
                            {
                                (item?.admin_details?.first_name + " " + item?.admin_details?.last_name)?.length > 20 ?
                                    <ToolTipComponent
                                        tooltip={item?.admin_details?.first_name + " " + item?.admin_details?.last_name}
                                        showArrow={true}
                                        position={"top"}
                                    >
                                        <div className={"ellipses-for-table-data property-name"}>
                                            {item?.admin_details?.first_name + " " + item?.admin_details?.last_name}
                                        </div>
                                    </ToolTipComponent> :
                                    <div className="agency-name">
                                        {item?.admin_details?.first_name + " " + item?.admin_details?.last_name || '-'}
                                    </div>
                            }
                        </div>
                    </div>;
                }
            },
            {
                title:"Admin Contact No",
                key: 'admin_contact_no',
                dataIndex: 'admin_contact_no',
                width: 150,
                render: (item: any) => {
                    return <>
                        <div className={'mrg-left-5'}>
                            {item?.admin_details?.contact_number}
                        </div>
                    </>;
                }
            },
            {
                title:"Status",
                key: 'status',
                dataIndex: 'status',
                align: 'center',
                width: 120,
                //   sortable: true,
                render: (item: any) => {
                    return <>
                        <TagComponent
                            color={item?.is_active ? CommonService.getStatusTagColor('Checked In') : CommonService.getStatusTagColor('Checked Out')}
                            // showIndicator={true}
                            label={item?.is_active ? 'Active' : 'Inactive'}/>
                    </>;
                }
            },
            {
                title: 'Activate/Deactivate',
                key: 'activate-deactivate',
                dataIndex: 'activate',
                align: 'center',
                width: 150,
                render: (item: any) => {
                    return <>
                        <CustomToggleComponent defaultOn={item?.is_active} toggleHandler={() => {
                            onAgencyActivateDeactivate(item);
                        }}/>
                    </>;
                }
            },
            {
                title: "Action",
                dataIndex: "actions",
                align: 'center',
                key: "actions",
                width: 100,
                fixed: 'right',
                render: (item: any) => {
                    if (item?._id) {
                        return <LinkComponent
                            route={CommonService._routeConfig.AgencyDetailsRoute(item?.admin_details?.agency_id)}>
                            <ImageConfig.ArrowRightOutlinedIcon style={{color: "#183258"}}/>
                        </LinkComponent>;
                    }
                }
            }
        ];
    }, [onAgencyActivateDeactivate]);
};