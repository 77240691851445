import "./EditHcpBasicDetails.scss";
import * as Yup from "yup";
import React, {useCallback, useEffect, useState} from "react";
import {Field, FieldProps, Form, Formik, FormikHelpers} from "formik";
import {CommonService} from "../../../../shared/services";
import {IAPIResponseType} from "../../../../shared/models/api.model";
import {ImageConfig, Misc} from "../../../../constants";
import FormikInputComponent from "../../../../shared/components/form-controls/formik-input/FormikInputComponent";
import ButtonComponent from "../../../../shared/components/button/ButtonComponent";
import LabelComponent from "../../../../shared/components/label/LabelComponent";
import FormControl from "@mui/material/FormControl";
import {americanTimeZone, MenuProps, TimezoneMenuProps} from "../../../../data";
import {ListItemText, MenuItem, Select} from "@mui/material";
import PhoneInputComponent from "../../../../shared/components/phoneInput/PhoneInputComponent";
import {useSelector} from "react-redux";
import {IRootReducerState} from "../../../../store/reducers";
import FormikTextAreaComponent
    from "../../../../shared/components/form-controls/formik-text-area/FormikTextAreaComponent";

// import FormDebuggerComponent from "../../../../shared/components/form-debugger/FormDebuggerComponent";

interface EditHcpDetailsComponentProps {
    facilityDetails: any;
    closeEditDrawer: any;
    getFacilityDetails: any;
}

const EditHcpDetailsValidationSchema = Yup.object().shape({
        contact_number: Yup.string().min(12, "min 10 digits").required("Phone number is required"),
        email: Yup.string().email('Invalid Email address').required('Email is required'),
        about: Yup.string().max(1000, "About should be maximum 1000 characters"),
        // gender: Yup.string().required("Gender is required"),
        hcp_type_id: Yup.string().required("Internal Staff Type is required"),
        first_name: Yup.string().required('First Name is required').min(2, "First Name should be minimum 2 characters").max(50, "First Name should be maximum 50 characters"),
        last_name: Yup.string().required('Last Name is required').min(2, "Last Name should be minimum 2 characters").max(50, "Last Name should be maximum 50 characters"),
        timezone: Yup.string().required("Timezone is required"),
        hcp_uniqueId:Yup.string().required('Staff ID is required').min(2,"Minimum 2 characters are required"),
        address: Yup.object().shape({
            street: Yup.string().required('Address Line is required').min(2, 'Address Line should be at least 2 characters').max(100, 'Address Line should be maximum 100 characters'),
            city: Yup.string().required('City is required').min(2, 'City should be at least 2 characters').max(30, 'City should be maximum 30 characters'),
            state: Yup.string().required('State is required').min(2, 'State should be at least 2 characters').max(30, 'State should be maximum 30 characters'),
            region: Yup.string().required('Region is required').min(2, 'Region should be at least 2 characters'),
            country: Yup.string().required('Country is required').min(2, 'Country should be at least 2 characters').max(30, 'Country should be maximum 30 characters'),
            zip_code: Yup.string().required('ZIP Code is required').min(5, 'ZIP Code should be at least 5 characters').max(6, 'ZIP Code should be maximum 6 characters')
        }),
    }
);

interface EditHcpDetailsFormInitialValuesFormSchema {
    first_name: string;
    last_name: string;
    contact_number: string;
    email: string;
    hcp_type_id: string,
    about: string;
    admin_first_name: string;
    admin_last_name: string;
    admin_contact_number: string;
    admin_email: string;
    location_url: string,
    timezone: string,
    hcp_uniqueId:string,
    address: {
        region: any,
        zip_code: any,
        street: any,
        state: string,
        city: string,
        country: string,

    };
}

const EditFacilityFormInitialValues: EditHcpDetailsFormInitialValuesFormSchema = {
    first_name: "",
    last_name: "",
    contact_number: "",
    email: "",
    hcp_type_id: "",
    about: "",
    admin_first_name: "",
    admin_last_name: "",
    admin_contact_number: "",
    admin_email: "",
    location_url: "",
    timezone: "",
    hcp_uniqueId:"",
    address: {
        region: "",
        zip_code: "",
        street: "",
        state: "",
        city: "",
        country: "",
    }
};


const EditHcpBasicDetailsComponent = (props: EditHcpDetailsComponentProps) => {

    const {facilityDetails, closeEditDrawer, getFacilityDetails} = props;
    const [selectedHcpType, setSelectedHcpType] = useState<any>(null);
    const [initialValues, setInitialValues] = useState<EditHcpDetailsFormInitialValuesFormSchema>(EditFacilityFormInitialValues);
    const [selectedTimeZone, setSelectedTimeZone] = useState<any>(null);
    const {regionList} = useSelector((state: IRootReducerState) => state.staticData);
    const [selectedRegion, setSelectedRegion] = useState<any>(null);
    const {
        HcpTypesList,
    } = useSelector((state: IRootReducerState) => state.meta);
    useEffect(() => {
        const basicDetails = {
            first_name: facilityDetails?.first_name,
            last_name: facilityDetails?.last_name,
            contact_number: facilityDetails?.contact_number,
            email: facilityDetails?.email,
            hcp_type_id: facilityDetails?.hcp_type_id,
            about: facilityDetails?.about,
            admin_first_name: facilityDetails?.admin_first_name,
            admin_last_name: facilityDetails?.admin_last_name,
            admin_contact_number: facilityDetails?.admin_contact_number,
            admin_email: facilityDetails?.admin_email,
            location_url: facilityDetails?.location_url,
            timezone: facilityDetails?.timezone,
            hcp_uniqueId:facilityDetails?.hcp_uniqueId,
            address: {
                region: facilityDetails?.address?.region,
                zip_code: facilityDetails?.address?.zip_code,
                street: facilityDetails?.address?.street,
                state: facilityDetails?.address?.state,
                city: facilityDetails?.address?.city,
                country: facilityDetails?.address?.country,
            }
        };
        setInitialValues(basicDetails);
        setSelectedHcpType(facilityDetails?.hcp_type_id);
        setSelectedTimeZone(facilityDetails?.timezone);
        setSelectedRegion(facilityDetails?.address?.region);
    }, [facilityDetails]);

    const onHCPDetailsSubmit = useCallback((values: any, {setErrors, setSubmitting}: FormikHelpers<any>) => {
        setSubmitting(true);
        const payload = {
            ...values,
        };
        let apiCall: any = {};
        apiCall = CommonService._hcp.EditHCPDetailsAPICall(facilityDetails._id, payload);
        apiCall.then((response: IAPIResponseType<any>) => {
            CommonService._alert.showToast(response[Misc.API_RESPONSE_MESSAGE_KEY], "success");
            closeEditDrawer();
            getFacilityDetails();
        })
            .catch((error: any) => {
                CommonService.handleErrors(setErrors, error);
            }).finally(() => {
            setSubmitting(false);
        });
    }, [closeEditDrawer, getFacilityDetails, facilityDetails]);

    return (
        <>
            <div className={'edit-facility-component'}>
                <div className={'edit-facility-header'}>
                    <div className={'form-header-text'}>Edit Internal Staff Details</div>
                    <div className={'icon-button'} onClick={closeEditDrawer}><ImageConfig.CrossIcon/></div>
                </div>
                <div className={'edit-facility-form-wrapper'}>
                    <Formik
                        validationSchema={EditHcpDetailsValidationSchema}
                        initialValues={initialValues}
                        validateOnChange={false}
                        validateOnBlur={true}
                        enableReinitialize={true}
                        validateOnMount={true}
                        onSubmit={onHCPDetailsSubmit}
                    >
                        {({values, errors, validateForm, isValid, setFieldValue, isSubmitting}) => {
                            // eslint-disable-next-line react-hooks/rules-of-hooks
                            useEffect(() => {
                                validateForm();
                            }, [validateForm, values]);
                            return (
                                <Form className="t-form scroll-content form-holder" noValidate={true}>
                                    {/*<FormDebuggerComponent values={values} errors={errors} showDebugger={true}/>*/}
                                    <div className={'ts-justify-content-between'}>
                                        <div className={'add-form-holder'}>
                                            <div className={'add-form-input-container'}>
                                                <div className="edit-component-content">
                                                    <div className={'d-flex'}>
                                                        <div className={'mrg-right-25 half-width'}>
                                                            <Field name={'first_name'}>
                                                                {
                                                                    (field: FieldProps) => (
                                                                        <FormikInputComponent
                                                                            label={'First Name'}
                                                                            placeholder={'Enter First Name'}
                                                                            type={"text"}
                                                                            required={true}
                                                                            formikField={field}
                                                                            fullWidth={true}
                                                                        />
                                                                    )
                                                                }
                                                            </Field>
                                                        </div>
                                                        <div className={'half-width'}>
                                                            <Field name={'last_name'}>
                                                                {
                                                                    (field: FieldProps) => (
                                                                        <FormikInputComponent
                                                                            label={'Last Name'}
                                                                            placeholder={'Enter Last Name'}
                                                                            type={"text"}
                                                                            required={true}
                                                                            formikField={field}
                                                                            fullWidth={true}
                                                                        />
                                                                    )
                                                                }
                                                            </Field>
                                                        </div>
                                                    </div>
                                                    <div className={'d-flex'}>
                                                        <div className={'mrg-right-25 half-width'}>
                                                            <LabelComponent title={'Phone Number'} required={true}/>
                                                            <Field name={'contact_number'}>
                                                                {(field: FieldProps) => {
                                                                    return <PhoneInputComponent field={field}
                                                                                                placeholder={"Enter Phone number"}/>;
                                                                }}
                                                            </Field>
                                                        </div>
                                                        <div className={'half-width'}>
                                                            <Field name={'email'}>
                                                                {
                                                                    (field: FieldProps) => (
                                                                        <FormikInputComponent
                                                                            label={'Email Address'}
                                                                            placeholder={'example@xyz.com'}
                                                                            type={"text"}
                                                                            required={true}
                                                                            formikField={field}
                                                                            fullWidth={true}
                                                                        />
                                                                    )
                                                                }
                                                            </Field>
                                                        </div>
                                                    </div>
                                                    <div className={"mrg-bottom-15"}>
                                                        <LabelComponent title={'Staff Type'}/>
                                                        <Field name={'hcp_type_id'}>
                                                            {
                                                                (field: FieldProps) => (
                                                                    <FormControl
                                                                        sx={{marginBottom: "10px", width: '100%'}}>
                                                                        <Select
                                                                            labelId="demo-simple-select-label"
                                                                            id="demo-simple-select"
                                                                            inputProps={{
                                                                                name: 'hcp_type_id',
                                                                                id: 'uncontrolled-native',
                                                                            }}
                                                                            // input={<BootstrapInput/>}
                                                                            value={selectedHcpType}
                                                                            onChange={(event: any) => {
                                                                                setSelectedHcpType(event.target.value);
                                                                                setFieldValue('hcp_type_id', event.target.value);
                                                                            }}
                                                                            MenuProps={MenuProps}
                                                                            displayEmpty={true}
                                                                            // label="Select Facility"
                                                                        >
                                                                            {HcpTypesList.map((item: any) => (
                                                                                <MenuItem key={item.code}
                                                                                          value={item?.code}>
                                                                                    <ListItemText
                                                                                        primary={item.title}/>
                                                                                </MenuItem>
                                                                            ))}
                                                                        </Select>
                                                                    </FormControl>
                                                                )
                                                            }
                                                        </Field>
                                                    </div>
                                                    <div className={'full-width'}>
                                                        <Field name={'hcp_uniqueId'}>
                                                            {
                                                                (field: FieldProps) => (
                                                                    <FormikInputComponent
                                                                        label={'Staff ID'}
                                                                        placeholder={'ex: 1234'}
                                                                        type={"text"}
                                                                        required={true}
                                                                        formikField={field}
                                                                        fullWidth={true}
                                                                    />
                                                                )
                                                            }
                                                        </Field>
                                                    </div>
                                                    <Field name={'about'}>
                                                        {
                                                            (field: FieldProps) => (
                                                                <FormikTextAreaComponent
                                                                    label={'About the Staff'}
                                                                    //  placeholder={'Enter Facility Name'}
                                                                    rows={5}
                                                                    formikField={field}
                                                                    fullWidth={true}
                                                                />
                                                            )
                                                        }
                                                    </Field>
                                                </div>
                                                <div className="edit-component-content">
                                                    <Field name={'address.street'}>
                                                        {
                                                            (field: FieldProps) => (
                                                                <FormikInputComponent
                                                                    label={'Address Line'}
                                                                    placeholder={'Enter Address Line here'}
                                                                    type={"text"}
                                                                    required={true}
                                                                    formikField={field}
                                                                    fullWidth={true}
                                                                />
                                                            )
                                                        }
                                                    </Field>
                                                    <div className={'d-flex'}>
                                                    <div className={'mrg-right-25 half-width'}>
                                                            <Field name={'address.country'}>
                                                                {
                                                                    (field: FieldProps) => (
                                                                        <FormikInputComponent
                                                                            label={'Country'}
                                                                            placeholder={'Enter Country Name'}
                                                                            type={"text"}
                                                                            required={true}
                                                                            formikField={field}
                                                                            fullWidth={true}
                                                                        />
                                                                    )
                                                                }
                                                            </Field>
                                                        </div>
                                                        <div className={'half-width'}>
                                                            <Field name={'address.state'}>
                                                                {
                                                                    (field: FieldProps) => (
                                                                        <FormikInputComponent
                                                                            label={'State'}
                                                                            placeholder={'Enter State Name'}
                                                                            type={"text"}
                                                                            required={true}
                                                                            formikField={field}
                                                                            fullWidth={true}
                                                                        />
                                                                    )
                                                                }
                                                            </Field>
                                                        </div>
                                                    </div>
                                                    <div className={'d-flex'}>
                                                        <div className={'mrg-right-25 half-width'}>
                                                            <Field name={'address.zip_code'}>
                                                                {
                                                                    (field: FieldProps) => (
                                                                        <FormikInputComponent
                                                                            label={'ZIP Code'}
                                                                            placeholder={'Enter ZIP Code'}
                                                                            type={"text"}
                                                                            required={true}
                                                                            formikField={field}
                                                                            fullWidth={true}
                                                                        />
                                                                    )
                                                                }
                                                            </Field>
                                                        </div>
                                                        <div className={'half-width'}>
                                                            <Field name={'address.city'}>
                                                                {
                                                                    (field: FieldProps) => (
                                                                        <FormikInputComponent
                                                                            label={'City'}
                                                                            placeholder={'Enter City Name'}
                                                                            type={"text"}
                                                                            required={true}
                                                                            formikField={field}
                                                                            fullWidth={true}
                                                                        />
                                                                    )
                                                                }
                                                            </Field>
                                                        </div>
                                                    </div>
                                                    <div className={'d-flex'}>
                                                        <div className={'mrg-right-25 half-width'}>
                                                            <LabelComponent title={'Timezone'}/>
                                                            <Field name={'timezone'}>
                                                                {
                                                                    (field: FieldProps) => (
                                                                        <FormControl sx={{m: 1, width: 236.5}}>
                                                                            {/*<InputLabel id="demo-simple-select-label">Select Facility</InputLabel>*/}
                                                                            <Select
                                                                                labelId="demo-simple-select-label"
                                                                                id="demo-simple-select"
                                                                                inputProps={{
                                                                                    name: 'timezone',
                                                                                    id: 'uncontrolled-native',
                                                                                }}
                                                                                //    input={<BootstrapInput/>}
                                                                                value={selectedTimeZone}
                                                                                onChange={(event: any) => {
                                                                                    setSelectedTimeZone(event.target.value);
                                                                                    setFieldValue('timezone', event.target.value);
                                                                                }}
                                                                                MenuProps={TimezoneMenuProps}
                                                                                displayEmpty={true}
                                                                                // label="Select Facility"
                                                                            >
                                                                                {americanTimeZone.map((item: any) => (
                                                                                    <MenuItem key={item.code}
                                                                                              value={item?.code}>
                                                                                        <ListItemText
                                                                                            primary={item.title}/>
                                                                                    </MenuItem>
                                                                                ))}
                                                                            </Select>
                                                                        </FormControl>
                                                                    )
                                                                }
                                                            </Field>
                                                        </div>
                                                        <div className={'half-width'}>
                                                            <LabelComponent title={'Region'}/>
                                                            <Field name={'address.region'}>
                                                                {
                                                                    (field: FieldProps) => (
                                                                        <FormControl sx={{m: 1, width: 236.5}}>
                                                                            {/*<InputLabel id="demo-simple-select-label">Select Facility</InputLabel>*/}
                                                                            <Select
                                                                                labelId="demo-simple-select-label"
                                                                                id="demo-simple-select"
                                                                                inputProps={{
                                                                                    name: 'address.region',
                                                                                    id: 'uncontrolled-native',
                                                                                }}
                                                                                // input={<BootstrapInput/>}
                                                                                value={selectedRegion}
                                                                                onChange={(event: any) => {
                                                                                    setSelectedRegion(event.target.value);
                                                                                    setFieldValue('address.region', event.target.value);
                                                                                }}
                                                                                MenuProps={MenuProps}
                                                                                displayEmpty={true}
                                                                                // label="Select Facility"
                                                                            >
                                                                                {regionList.map((item: any) => (
                                                                                    <MenuItem key={item.code}
                                                                                              value={item?.code}>
                                                                                        <ListItemText
                                                                                            primary={item.title}/>
                                                                                    </MenuItem>
                                                                                ))}
                                                                            </Select>
                                                                        </FormControl>
                                                                    )
                                                                }
                                                            </Field>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className={'ts-justify-content-center d-flex mrg-top-10'}>
                                            <ButtonComponent
                                                disabled={isSubmitting || !isValid}
                                                isLoading={isSubmitting}
                                                color={'secondary'}
                                                type={'submit'}
                                            >
                                                Save Details
                                            </ButtonComponent>
                                        </div>
                                    </div>
                                </Form>
                            );
                        }}
                    </Formik>
                </div>
            </div>
        </>
    );
};
export default EditHcpBasicDetailsComponent;

