import "./FacvoriteHcpDetailsComponent.scss";
import React, {useCallback, useEffect, useState} from "react";
import {CommonService} from "../../../shared/services";
import {IAPIResponseType} from "../../../shared/models/api.model";
import CardComponent from "../../../shared/components/card/CardComponent";
import LoaderComponent from "../../../shared/components/loader/LoaderComponent";
import StatusCardComponent from "../../../shared/components/status-card/StatusCardComponent";
import {ImageConfig, Misc} from "../../../constants";
import HorizontalLineComponent
    from "../../../shared/components/horizontal-line/horizontal-line/HorizontalLineComponent";
import LinkComponent from "../../../shared/components/link/LinkComponent";
import Lottie from "react-lottie";
import FavoriteJson from "../../../assets/animations/Favorite.json";
import CommonHcpDetailsCard from "../../shift-requirement/shift-details/hcp-details-common-card/CommonHcpDetailsCard";
import ButtonComponent from "../../../shared/components/button/ButtonComponent";

interface FavoriteHcpDetailsComponentProps {
    setOpenDrawer?: any;
    hcpId?: any;
    filterData?: any;
    setFilterData?: any;
}

const FavoriteHcpDetailsComponent = (props: FavoriteHcpDetailsComponentProps) => {
    const {hcpId, setOpenDrawer, filterData, setFilterData} = props;
    const [hcpDetails, setHcpDetails] = useState<any>(null);
    const [isHcpDetailsLoaded, setIsHcpDetailsLoaded] = useState<boolean>(false);
    const [isHcpDetailsLoading, setIsHcpDetailsLoading] = useState<boolean>(false);
    const [isHcpDetailsLoadingFailed, setIsHcpDetailsLoadingFailed] = useState<boolean>(false);
    const [hcpDocumentsList, setHcpDocumentsList] = useState<any>(null);
    const [isDeMarkingHcpAsFavorite, setIsDeMarkingHcpAsFavorite] = useState<boolean>(false);

    const getHcpDetails = useCallback(() => {
        setIsHcpDetailsLoading(true);
        const payload = {};
        if (hcpId) {
            CommonService._hcp.HcpDetailsAPICall(hcpId, payload)
                .then((response: IAPIResponseType<any>) => {
                    //  console.log(response.data);
                    setHcpDetails(response.data);
                    setIsHcpDetailsLoaded(true);
                    setIsHcpDetailsLoadingFailed(false);
                }).catch((error: any) => {
                setIsHcpDetailsLoaded(false);
                setIsHcpDetailsLoadingFailed(true);
            }).finally(() => {
                setIsHcpDetailsLoading(false);
            });
        }
    }, [hcpId]);
    const getHcpDocuments = useCallback(() => {
        const payload = {};
        if (hcpId) {
            CommonService._hcp.HcpDocumentAPICall(hcpId, payload)
                .then((response: IAPIResponseType<any>) => {
                    //  console.log(response.data);
                    setHcpDocumentsList(response?.data);
                }).catch((error: any) => {
            }).finally(() => {
            });
        }
    }, [hcpId]);

    const deMarkHcpAsFavorite = useCallback(() => {
        setIsDeMarkingHcpAsFavorite(true);
        CommonService._hcp.RemoveAsFavorite(hcpDetails?._id)
            .then((response: IAPIResponseType<any>) => {
                setIsDeMarkingHcpAsFavorite(false);
                getHcpDetails();
                setFilterData({...filterData, search: '', sort: {},});
                setOpenDrawer(false);
                CommonService._alert.showToast(response[Misc.API_RESPONSE_MESSAGE_KEY], "success");
            }).catch((error: any) => {
            setIsDeMarkingHcpAsFavorite(false);
            CommonService._alert.showToast(error[Misc.API_ERROR_MESSAGE_KEY], "error");
        }).finally(() => {
            setIsDeMarkingHcpAsFavorite(false);
        });
    }, [hcpDetails, getHcpDetails, filterData, setFilterData, setOpenDrawer]);

    useEffect((): void => {
        getHcpDetails();
    }, [getHcpDetails]);

    useEffect(() => {
        getHcpDocuments();
    }, [getHcpDocuments]);

    return (
        <>
            <div className={'favorite-hcp-component'}>
                <div className={"approved-header d-flex justify-content-space-between pdd-20"} style={{
                    backgroundImage: `url(${ImageConfig.ApprovedGradient}`,
                    backgroundRepeat: "no-repeat"
                }}>
                    <div className={'d-flex ts-align-items-center'}>
                        <div className={'form-header-text'}>Staff Details
                        </div>
                    </div>
                    <div className={'icon-button'} onClick={() => {
                        setOpenDrawer(false);
                    }}><ImageConfig.CrossIcon/></div>
                </div>
                <HorizontalLineComponent/>
                <div className={'hcp-details-wrapper mrg-20'}>
                        {
                            isHcpDetailsLoading && <div className="h-v-center">
                                <LoaderComponent type={'spinner'}/>
                            </div>
                        }
                        {
                            isHcpDetailsLoadingFailed &&
                            <div className="h-v-center">
                                <StatusCardComponent title={'Failed to Load'}/>
                            </div>
                        }
                        {
                            isHcpDetailsLoaded && <>
                                <div>
                                    <div className={'d-flex justify-content-space-between'}>
                                        <div className={'d-flex align-items-center height-hcp-details-wrapper'}>
                                            <div className={'hcp-details-header-text-drawer'}>Staff Details</div>
                                            <div>
                                                {hcpDetails?.is_favourite &&
                                                    <Lottie width={50} height={50} speed={1}
                                                            options={{animationData: FavoriteJson}}/>}
                                        </div>
                                        </div>
                                        <div>
                                            <ButtonComponent color={'remove-as-favorite'}
                                                             onClick={deMarkHcpAsFavorite}
                                                             isLoading={isDeMarkingHcpAsFavorite}
                                            >
                                                Remove Favorite
                                            </ButtonComponent>
                                        </div>
                                    </div>
                                    <CommonHcpDetailsCard isFavoriteHcpDetails={true} hcpDetails={hcpDetails} favoriteModule={true}
                                        // innerShiftDetails={shiftRequirementDetails}
                                                          getHcpDetails={getHcpDetails}
                                    />
                                </div>
                                {/*<div className={'details-screen'}>*/}
                                {/*    <div className={"pdd-left-25 pdd-right-25 pdd-top-25"}>*/}
                                {/*        <div className={'d-flex mrg-bottom-25'}*/}
                                {/*             style={{justifyContent: "space-between", alignItems: "center"}}>*/}
                                {/*            <div>*/}
                                {/*                <div*/}
                                {/*                    className={'hcp-name-header'}> {hcpDetails?.first_name + " " + hcpDetails?.last_name || '-'}{hcpDetails?.is_favourite && <ImageConfig.MarkedFavoriteStarMark/>}</div>*/}
                                {/*                <div className={'hcp-header-sub-text mrg-top-5'}>{hcpDetails?.hcp_type_details?.title} | {hcpDetails?.agency_details?.name}*/}
                                {/*                </div>*/}
                                {/*            </div>*/}
                                {/*            <div>*/}
                                {/*                <ButtonComponent style={{*/}
                                {/*                    backgroundColor: "#FFF4F4",*/}
                                {/*                    color: "#DB5656",*/}
                                {/*                    border: "0.5px solid #DB5656"*/}
                                {/*                }}*/}
                                {/*                                 onClick={deMarkHcpAsFavorite}*/}
                                {/*                                 isLoading={isDeMarkingHcpAsFavorite}>*/}
                                {/*                    Remove as Favorite*/}
                                {/*                </ButtonComponent>*/}
                                {/*            </div>*/}
                                {/*        </div>*/}
                                {/*        <div className={'ts-row'}>*/}
                                {/*            <div className={'ts-col-lg-4'}>*/}
                                {/*                <DataLabelValueComponent label={'Phone Number'}>*/}
                                {/*                    {hcpDetails?.contact_number || '-'}*/}
                                {/*                </DataLabelValueComponent>*/}
                                {/*            </div>*/}
                                {/*            <div className={'ts-col-lg-4'}>*/}
                                {/*                <DataLabelValueComponent label={'Email Id'}>*/}
                                {/*                    {hcpDetails?.email || '-'}*/}
                                {/*                </DataLabelValueComponent>*/}
                                {/*            </div>*/}
                                {/*            <div className={'ts-col-lg-4'}>*/}
                                {/*                <DataLabelValueComponent label={'Address Line'}>*/}
                                {/*                    {`${hcpDetails?.address?.street}, ${hcpDetails?.address?.city}, ${hcpDetails?.address?.state}, ${hcpDetails?.address?.zip_code}, ${hcpDetails?.address?.region}, ${hcpDetails?.address?.country}` || '-'}*/}
                                {/*                </DataLabelValueComponent>*/}
                                {/*            </div>*/}
                                {/*        </div>*/}
                                {/*        <div className={'ts-row'}>*/}
                                {/*            <div className={'ts-col-lg-4'}>*/}
                                {/*                <DataLabelValueComponent label={'Timezone'}>*/}
                                {/*                    {hcpDetails?.timezone || '-'}*/}
                                {/*                </DataLabelValueComponent>*/}
                                {/*            </div>*/}
                                {/*            <div className={'ts-col-lg-4'}>*/}
                                {/*                <DataLabelValueComponent label={'Region'}>*/}
                                {/*                    {*/}
                                {/*                        hcpDetails?.address?.region*/}
                                {/*                    }*/}
                                {/*                </DataLabelValueComponent>*/}
                                {/*            </div>*/}
                                {/*        </div>*/}
                                {/*        <div className={"ts-row"}>*/}
                                {/*            <div className={'ts-col-lg-6'}>*/}
                                {/*                <DataLabelValueComponent label={'About the HCP'}>*/}
                                {/*                    {hcpDetails?.about || '-'}*/}
                                {/*                </DataLabelValueComponent>*/}
                                {/*            </div>*/}
                                {/*        </div>*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                            </>}
                    {(hcpDocumentsList?.length > 0 && isHcpDetailsLoaded) &&
                        <CardComponent size={"sm"} className={"mrg-top-10"}>
                            <div className={'details-screen'}>
                                <div className="details-screen-title-with-image-wrapper pdd-10">
                                    <div className={'details-screen-title-wrapper center-right-element'}>
                                        <div className={'details-screen-title'}>Documents
                                        </div>
                                        {/*<div onClick={openEditDrawer} className={'icon-button'}>*/}
                                        {/*<span*/}
                                        {/*    className={'center-element button-font-text'}><ImageConfig.EditIcon*/}
                                        {/*    className={'mrg-right-10'}/>Edit HCP Details</span>*/}
                                        {/*</div>*/}
                                    </div>
                                </div>
                                <HorizontalLineComponent/>
                                {
                                    hcpDocumentsList?.map((document: any, index: any) => {
                                        let transformedName = "";
                                        //  CommonService.transformName(item?.attachment_type);
                                        if (document?.attachment_type === "tb_test") {
                                            transformedName = "TB Test";
                                            //  CPR BLS Card
                                        } else if (document?.attachment_type === "cpr_bls_card") {
                                            transformedName = "CPR BLS Card";
                                        } else {
                                            transformedName = CommonService.transformName(document?.attachment_type);
                                        }
                                        return (
                                            <>
                                                <div className={'document-list-wrapper'}>
                                                    <div>
                                                        {transformedName}
                                                    </div>
                                                    <div>
                                                        <LinkComponent onClick={() => {
                                                            window.open(document?.url, '_blank');
                                                        }}>
                                                            <ImageConfig.ArrowRightOutlinedIcon/>
                                                        </LinkComponent>
                                                    </div>
                                                </div>
                                            </>
                                        );
                                    })
                                }
                            </div>
                        </CardComponent>}
                </div>
            </div>
        </>
    );
};
export default FavoriteHcpDetailsComponent;