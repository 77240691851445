import "./EditFacilityComponent.scss";
import * as Yup from "yup";
import React, {useCallback, useEffect, useState} from "react";
import {ImageConfig, Misc} from "../../../constants";
import {Field, FieldProps, Form, Formik, FormikHelpers} from "formik";
import FormikInputComponent from "../../../shared/components/form-controls/formik-input/FormikInputComponent";
import ButtonComponent from "../../../shared/components/button/ButtonComponent";
import {CommonService} from "../../../shared/services";
import {IAPIResponseType} from "../../../shared/models/api.model";
import LabelComponent from "../../../shared/components/label/LabelComponent";
import PhoneInputComponent from "../../../shared/components/phoneInput/PhoneInputComponent";
import {americanTimeZone, TimezoneMenuProps} from "../../../data";
import {ListItemText, MenuItem, Select} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import {useSelector} from "react-redux";
import {IRootReducerState} from "../../../store/reducers";
import FormikTextAreaComponent from "../../../shared/components/form-controls/formik-text-area/FormikTextAreaComponent";

// import FormDebuggerComponent from "../../../shared/components/form-debugger/FormDebuggerComponent";
interface EditFacilityComponentProps {
    facilityDetails: any;
    closeEditDrawer: any;
    getFacilityDetails: any;
}

const EditFacilityValidationSchema = Yup.object().shape({
        name: Yup.string()
            .min(3, 'Name should be at least 3 characters')
            .max(50, 'Name should not exceed 50 characters')
            .required('Name is required'),
        contact_number: Yup.string().min(12, "min 10 digits").required("Required"),
        email: Yup.string().email('Invalid email address').required('Email is required'),
        about: Yup.string(),
        lat:Yup.string().required('Latitude is required').min(-90,'Latitude cannot be less than -90').max(90,'Latitude cannot be more than 90'),
        long:Yup.string().required('Longitude is required').min(-180,'Longitude cannot be less than -180').max(90,'Latitude cannot be more than 180'),
    domain: Yup.string()
        .required('Domain is required')
        .matches(/^[a-zA-Z0-9]+$/, 'Invalid domain format (alphanumeric only)'),
        address: Yup.object({
            street: Yup.string()
                .min(1, 'Address Line should be at least 1 character')
                .required('Address Line  is required'),
            zip_code: Yup.string().required('ZIP Code is required').min(5, 'ZIP Code should be at least 5 characters').max(6, 'ZIP Code should be maximum 6 characters'),
            state: Yup.string()
                .min(1, 'State should be at least 1 character')
                .max(30, 'State should not exceed 30 characters')
                .required('State is required'),
            city: Yup.string()
                .min(1, 'City should be at least 1 character')
                .max(30, 'City should not exceed 30 characters')
                .required('City is required'),
            country: Yup.string()
                .min(1, 'Country should be at least 1 character')
                .max(30, 'Country should not exceed 30 characters')
                .required('Country is required'),
            region: Yup.string()
                .min(1, 'Country should be at least 1 character')
                .max(30, 'Country should not exceed 30 characters')
                .required('Country is required'),
        }),

    },
);

interface EditFacilityFormInitialValuesFormSchema {
    name: string;
    contact_number: string;
    email: string;
    about: string;
    admin_first_name: string;
    admin_last_name: string;
    admin_contact_number: string;
    admin_email: string;
    domain: string,
    timezone: string,
    lat:any;
    long:any;
    address: {
        region: any,
        zip_code: any,
        street: any,
        state: string,
        city: string,
        country: string,

    };
}

const EditFacilityFormInitialValues: EditFacilityFormInitialValuesFormSchema = {
    name: "",
    contact_number: "",
    email: "",
    about: "",
    admin_first_name: "",
    domain: "",
    admin_last_name: "",
    admin_contact_number: "",
    admin_email: "",
   // location_url: "",
    timezone: "",
    lat:"",
    long:"",
    address: {
        region: "",
        zip_code: "",
        street: "",
        state: "",
        city: "",
        country: "",
    }
};


const EditFacilityComponent = (props: EditFacilityComponentProps) => {

    const {facilityDetails, closeEditDrawer, getFacilityDetails} = props;
    const [initialValues, setInitialValues] = useState<EditFacilityFormInitialValuesFormSchema>(EditFacilityFormInitialValues);
    const [selectedTimeZone, setSelectedTimeZone] = useState<any>(null);
    const {regionList} = useSelector((state: IRootReducerState) => state.staticData);
    const [selectedRegion, setSelectedRegion] = useState<any>(null);

    useEffect(() => {
        const basicDetails = {
            name: facilityDetails?.name,
            contact_number: facilityDetails?.contact_number,
            email: facilityDetails?.email,
            about: facilityDetails?.about,
            admin_first_name: facilityDetails?.admin_first_name,
            admin_last_name: facilityDetails?.admin_last_name,
            admin_contact_number: facilityDetails?.admin_contact_number,
            admin_email: facilityDetails?.admin_email,
            domain: facilityDetails?.domain?.split('.')[0],
            timezone: facilityDetails?.timezone,
            lat:facilityDetails?.location?.coordinates[1],
            long:facilityDetails?.location?.coordinates[0],
            address: {
                region: facilityDetails?.address?.region,
                zip_code: facilityDetails?.address?.zip_code,
                street: facilityDetails?.address?.street,
                state: facilityDetails?.address?.state,
                city: facilityDetails?.address?.city,
                country: facilityDetails?.address?.country,
            }
        };
        setInitialValues(basicDetails);
        setSelectedTimeZone(facilityDetails?.timezone);
        setSelectedRegion(facilityDetails?.address?.region);
    }, [facilityDetails]);

    const onSubmit = useCallback((values: any, {setErrors, setSubmitting}: FormikHelpers<any>) => {
        setSubmitting(true);
        const payload = {
            ...values,
            coordinates:[
                parseFloat(values?.long),
                parseFloat(values?.lat)
            ]
        };
        let apiCall: any = {};
        apiCall = CommonService._facility.EditFacilityAPICall(payload);
        apiCall.then((response: IAPIResponseType<any>) => {
            CommonService._alert.showToast(response[Misc.API_RESPONSE_MESSAGE_KEY], "success");
            closeEditDrawer();
            getFacilityDetails();
        })
            .catch((error: any) => {
                CommonService.handleErrors(setErrors, error);
            }).finally(() => {
            setSubmitting(false);
        });
    }, [closeEditDrawer, getFacilityDetails]);

    return (
        <>
            <div className={'edit-facility-component'}>
                <div className={'edit-facility-header'}>
                    <div className={'form-header-text'}>Edit Facility Details</div>
                    <div className={'icon-button'} onClick={closeEditDrawer}><ImageConfig.CrossIcon/></div>
                </div>
                <div className={'edit-facility-form-wrapper'}>
                    <Formik
                        validationSchema={EditFacilityValidationSchema}
                        initialValues={initialValues}
                        validateOnChange={false}
                        validateOnBlur={true}
                        enableReinitialize={true}
                        validateOnMount={true}
                        onSubmit={onSubmit}
                    >
                        {({values, errors, validateForm, isValid, setFieldValue, isSubmitting}) => {
                            // eslint-disable-next-line react-hooks/rules-of-hooks
                            useEffect(() => {
                                validateForm();
                            }, [validateForm, values]);
                            return (
                                <Form className="t-form scroll-content form-holder" noValidate={true}>
                                        <div className={'add-form-holder'}>
                                            <div className={'add-form-input-container'}>
                                                <div className="edit-component-content">
                                                    <Field name={'name'}>
                                                        {
                                                            (field: FieldProps) => (
                                                                <FormikInputComponent
                                                                    label={'Facility Name'}
                                                                    placeholder={'Enter Facility Name'}
                                                                    type={"text"}
                                                                    required={true}
                                                                    formikField={field}
                                                                    fullWidth={true}
                                                                />
                                                            )
                                                        }
                                                    </Field>
                                                    <div className={'d-flex'}>
                                                        <div className={'mrg-right-25 half-width'}>
                                                            <LabelComponent title={'Phone Number'} required={true}/>
                                                            <Field name={'contact_number'}>
                                                                {(field: FieldProps) => {
                                                                    return <PhoneInputComponent field={field}
                                                                                                placeholder={"Enter Phone number"}/>;
                                                                }}
                                                            </Field>
                                                        </div>
                                                        <div className={'half-width'}>
                                                            <Field name={'email'}>
                                                                {
                                                                    (field: FieldProps) => (
                                                                        <FormikInputComponent
                                                                            label={'Email Address'}
                                                                            placeholder={'example@xyz.com'}
                                                                            type={"text"}
                                                                            required={true}
                                                                            formikField={field}
                                                                            fullWidth={true}
                                                                        />
                                                                    )
                                                                }
                                                            </Field>
                                                        </div>
                                                    </div>
                                                    <div className="ts-row align-items-center mrg-left-5">
                                                        <div className="ts-col-0.5 disabled-state-text-font mrg-top-10">
                                                            https://
                                                        </div>
                                                        <div className="ts-col-7">
                                                            <Field name={'domain'}>
                                                                {
                                                                    (field: FieldProps) => (
                                                                        <FormikInputComponent
                                                                            label={'Domain Name'}
                                                                            placeholder={'Enter Domain Name'}
                                                                            type={"text"}
                                                                            required={true}
                                                                            formikField={field}
                                                                            fullWidth={true}
                                                                        />
                                                                    )
                                                                }
                                                            </Field>
                                                        </div>
                                                        <div className="ts-col-0.5 disabled-state-text-font mrg-top-10">
                                                            .varshealth.com
                                                        </div>
                                                    </div>
                                                    <Field name={'about'}>
                                                        {
                                                            (field: FieldProps) => (
                                                                <FormikTextAreaComponent
                                                                    label={'About the Facility'}
                                                                    //  placeholder={'Enter Facility Name'}
                                                                    rows={5}
                                                                    formikField={field}
                                                                    fullWidth={true}
                                                                />
                                                            )
                                                        }
                                                    </Field>
                                                </div>
                                                <div className="edit-component-content">
                                                    <Field name={'address.street'}>
                                                        {
                                                            (field: FieldProps) => (
                                                                <FormikInputComponent
                                                                    label={'Address Line'}
                                                                    placeholder={'Enter Address Line here'}
                                                                    type={"text"}
                                                                    required={true}
                                                                    formikField={field}
                                                                    fullWidth={true}
                                                                />
                                                            )
                                                        }
                                                    </Field>
                                                    <div className={'d-flex'}>
                                                        <div className={'mrg-right-25 half-width'}>
                                                            <Field name={'address.country'}>
                                                                {
                                                                    (field: FieldProps) => (
                                                                        <FormikInputComponent
                                                                            label={'Country'}
                                                                            placeholder={'Enter Country Name'}
                                                                            type={"text"}
                                                                            required={true}
                                                                            formikField={field}
                                                                            fullWidth={true}
                                                                        />
                                                                    )
                                                                }
                                                            </Field>
                                                        </div>
                                                        <div className={'half-width'}>
                                                            <Field name={'address.state'}>
                                                                {
                                                                    (field: FieldProps) => (
                                                                        <FormikInputComponent
                                                                            label={'State'}
                                                                            placeholder={'Enter State Name'}
                                                                            type={"text"}
                                                                            required={true}
                                                                            formikField={field}
                                                                            fullWidth={true}
                                                                        />
                                                                    )
                                                                }
                                                            </Field>
                                                        </div>
                                                    </div>
                                                    <div className={'d-flex'}>
                                                        <div className={'mrg-right-25 half-width'}>
                                                            <Field name={'address.zip_code'}>
                                                                {
                                                                    (field: FieldProps) => (
                                                                        <FormikInputComponent
                                                                            label={'ZIP Code'}
                                                                            placeholder={'Enter ZIP Code'}
                                                                            type={"text"}
                                                                            required={true}
                                                                            formikField={field}
                                                                            fullWidth={true}
                                                                        />
                                                                    )
                                                                }
                                                            </Field>
                                                        </div>
                                                        <div className={'half-width'}>
                                                            <Field name={'address.city'}>
                                                                {
                                                                    (field: FieldProps) => (
                                                                        <FormikInputComponent
                                                                            label={'City'}
                                                                            placeholder={'Enter City Name'}
                                                                            type={"text"}
                                                                            required={true}
                                                                            formikField={field}
                                                                            fullWidth={true}
                                                                        />
                                                                    )
                                                                }
                                                            </Field>
                                                        </div>
                                                    </div>
                                                    <div className={'d-flex'}>
                                                        <div className={'mrg-right-25 half-width'}>
                                                            <LabelComponent title={'Timezone*'}/>
                                                            <Field name={'timezone'}>
                                                                {
                                                                    (field: FieldProps) => (
                                                                        <FormControl sx={{m: 1, width: 236.5}}>
                                                                            {/*<InputLabel id="demo-simple-select-label">Select Facility</InputLabel>*/}
                                                                            <Select
                                                                                labelId="demo-simple-select-label"
                                                                                id="demo-simple-select"
                                                                                inputProps={{
                                                                                    name: 'timezone',
                                                                                    id: 'uncontrolled-native',
                                                                                }}
                                                                                // input={<BootstrapInput/>}
                                                                                value={selectedTimeZone}
                                                                                onChange={(event: any) => {
                                                                                    setSelectedTimeZone(event.target.value);
                                                                                    setFieldValue('timezone', event.target.value);
                                                                                }}
                                                                                MenuProps={TimezoneMenuProps}
                                                                                // label="Select Facility"
                                                                            >
                                                                                {americanTimeZone.map((item: any) => (
                                                                                    <MenuItem key={item.code}
                                                                                              value={item?.code}>
                                                                                        <ListItemText
                                                                                            primary={item.title}/>
                                                                                    </MenuItem>
                                                                                ))}
                                                                            </Select>
                                                                        </FormControl>
                                                                    )
                                                                }
                                                            </Field>
                                                        </div>
                                                        <div className={'half-width'}>
                                                            <LabelComponent title={'Region*'}/>
                                                            <Field name={'address.region'}>
                                                                {
                                                                    (field: FieldProps) => (
                                                                        <FormControl sx={{m: 1, width: 236.5}}>
                                                                            {/*<InputLabel id="demo-simple-select-label">Select Facility</InputLabel>*/}
                                                                            <Select
                                                                                labelId="demo-simple-select-label"
                                                                                id="demo-simple-select"
                                                                                inputProps={{
                                                                                    name: 'address.region',
                                                                                    id: 'uncontrolled-native',
                                                                                }}
                                                                                // input={<BootstrapInput/>}
                                                                                value={selectedRegion}
                                                                                onChange={(event: any) => {
                                                                                    setSelectedRegion(event.target.value);
                                                                                    setFieldValue('address.region', event.target.value);
                                                                                }}
                                                                                // label="Select Facility"
                                                                            >
                                                                                {regionList.map((item: any) => (
                                                                                    <MenuItem key={item.code}
                                                                                              value={item?.code}>
                                                                                        <ListItemText
                                                                                            primary={item.title}/>
                                                                                    </MenuItem>
                                                                                ))}
                                                                            </Select>
                                                                        </FormControl>
                                                                    )
                                                                }
                                                            </Field>
                                                        </div>
                                                    </div>
                                                    <div className={'d-flex mrg-top-20 '}>
                                                        <div className={'half-width mrg-right-25'}>
                                                            <Field name={`lat`}>
                                                                {
                                                                    (field: FieldProps) => (
                                                                        <FormikInputComponent
                                                                            label={'Latitude'}
                                                                            placeholder={'ex: 37.7749'}
                                                                            type={"number"}
                                                                            required={true}
                                                                            formikField={field}
                                                                            fullWidth={true}
                                                                            validationPattern={new RegExp(/^(-?([1-8]?[0-9](\.\d+)?|90(\.0+)?))$/)}
                                                                        />
                                                                    )
                                                                }
                                                            </Field>

                                                        </div>
                                                        <div className={'half-width'}>
                                                            <Field name={`long`}>
                                                                {
                                                                    (field: FieldProps) => (
                                                                        <FormikInputComponent
                                                                            label={'Longitude'}
                                                                            placeholder={'ex: -122.4194'}
                                                                            type={"number"}
                                                                            required={true}
                                                                            formikField={field}
                                                                            fullWidth={true}
                                                                            validationPattern={new RegExp(/^(-?((1[0-7][0-9]|[1-9]?[0-9])(\.\d+)?|180(\.0+)?))$/)}

                                                                        />
                                                                    )
                                                                }
                                                            </Field>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    <div className={'add-form-action-button'}>
                                        <ButtonComponent
                                            disabled={isSubmitting || !isValid}
                                            isLoading={isSubmitting}
                                            color={'secondary'}
                                            type={'submit'}
                                        >
                                            Save Details
                                        </ButtonComponent>
                                    </div>
                                </Form>
                            );
                        }}
                    </Formik>
                </div>
            </div>
        </>
    );
};
export default EditFacilityComponent;

