import "./UserManagementComponent.scss";
import {useCallback, useState} from "react";
import {CommonService} from "../../../shared/services";
import LinkComponent from "../../../shared/components/link/LinkComponent";
import {APIConfig, ImageConfig, Misc} from "../../../constants";
import SearchComponent from "../../../shared/components/search/SearchComponent";
import ButtonComponent from "../../../shared/components/button/ButtonComponent";
import TableWrapperComponent from "../../../shared/components/table-wrapper/TableWrapperComponent";
import DrawerComponent from "../../../shared/components/drawer/DrawerComponent";
import EditUserComponent from "../edit-user/EditUserComponent";
import {IAPIResponseType} from "../../../shared/models/api.model";
import {useUserListColumn} from "./TableColumnns";
const UserManagementListComponent = () => {
    const [filterData, setFilterData] = useState<any>({
        search: '',
    });
    const [isEditUserDetailsDrawerOpen, setIsEditUserDetailsDrawerOpen] = useState<any>(false);
    const [userDetails, setUserDetails] = useState<any>(null);
    const [isDownloading, setIsDownloading] = useState<boolean>(false);

    const handleDownloadUsers = useCallback(() => {
        setIsDownloading(true);
        let apiCall: any = {};
        apiCall = CommonService._user_management.downloadUserDataCSVAPICall(filterData);

        apiCall.then((response: IAPIResponseType<any>) => {
            if (response?.data) {
                CommonService._alert.showToast(response[Misc.API_RESPONSE_MESSAGE_KEY], "success");
                const link = document.createElement("a");
                link?.setAttribute("href", response?.data);
                document.body.appendChild(link);
                link.click();
                setIsDownloading(false);
            } else {
                CommonService._alert.showToast(response[Misc.API_RESPONSE_MESSAGE_KEY], "error");
                setIsDownloading(false);
            }

        })
            .catch((error: any) => {
                setIsDownloading(false);
            }).finally(() => {

        });
    }, [filterData]);

    const onUserActivateDeactivate = useCallback((item: any) => {
        CommonService.onConfirm(
            {
                confirmationTitle: `${item?.is_active ? 'Deactivate' : 'Activate'}  User ?`,
                image: item?.is_active ? ImageConfig.UserDeactivateIcon : ImageConfig.UserActivateIcon,
                confirmationDescription: `Are you sure you want to ${item?.is_active ? 'Deactivate' : 'Activate'}  the selected
    User?`,
                no: {
                    color: "default",
                    text: "No, Go Back",
                    variant: "outlined"
                },
                yes: {
                    color: "error",
                    text: `Yes, ${item?.is_active ? 'Deactivate' : 'Activate'}`,
                    variant: "contained",
                }
            }
        ).then((result: any) => {
            const payload: any = {};
            payload.is_active = !item?.is_active;
            CommonService._facility.ActivateDeactivateFacilityAdmin(item?._id, payload)
                .then((response: IAPIResponseType<any>) => {
                    CommonService._alert.showToast(response[Misc.API_RESPONSE_MESSAGE_KEY], "success");
                    setFilterData({});
                }).catch((error: any) => {
                CommonService._alert.showToast(error?.error || "Error", "error");
            });
        });
    }, []);

    const UserManagementListColumn = useUserListColumn(onUserActivateDeactivate, setIsEditUserDetailsDrawerOpen, setUserDetails);

    const closeEditDrawer = useCallback(() => {
        setIsEditUserDetailsDrawerOpen(false);
    }, []);

    return (
        <>
            <DrawerComponent isOpen={isEditUserDetailsDrawerOpen} direction={"right"} onClose={closeEditDrawer}>
                <EditUserComponent filterData={filterData} setFilterData={setFilterData} userDetails={userDetails}
                                   closeEditDrawer={closeEditDrawer}/>
            </DrawerComponent>
            <div className={'user-management-list-component header-screen mrg-top-25 mrg-left-25 mrg-right-30'}>
                <div className="list-screen-header-wrapper">
                    <div className="list-screen-header">
                        <div className="list-screen-header-title-and-count d-flex">
                            <div className={'mrg-right-10'}>
                                <ImageConfig.UserModuleHeaderIcon/>
                            </div>
                            <div className="list-screen-header-title">User</div>
                        </div>
                    </div>
                    <div className="list-options">
                        <div className={"mrg-top-20"}>
                            <SearchComponent
                                size={'medium'}
                                placeholder="Search"
                                value={filterData.search}
                                onSearchChange={(value) => {
                                    setFilterData({...filterData, search: value, page: 1});
                                }}
                            />
                        </div>
                        <ButtonComponent color={'download-button'} variant={"contained"}
                                         isLoading={isDownloading}
                                         onClick={handleDownloadUsers}>
                            Download CSV
                        </ButtonComponent>
                        <LinkComponent route={CommonService._routeConfig.AddUserManagementRoute()}>
                            <ButtonComponent color={'primary'} prefixIcon={<ImageConfig.AddOutlinedIcon/>}>
                                Add New User
                            </ButtonComponent>
                        </LinkComponent>
                    </div>
                </div>
            </div>
            <div className={'facility-list-component list-screen mrg-top-10 mrg-left-25 mrg-right-30'}>
                <div className="list-screen-content">
                    <TableWrapperComponent url={APIConfig.USER_MANAGEMENT_LIST.URL}
                                           method={APIConfig.USER_MANAGEMENT_LIST.METHOD}
                                           isPaginated={true}
                                           extraPayload={filterData}
                                           noDataText={filterData.search ? 'Oops! It seems like there are no user available for the name you have searched.' : 'No Data'}
                                           columns={UserManagementListColumn}
                    />
                </div>
            </div>
        </>
    );
};
export default UserManagementListComponent;
